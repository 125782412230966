// @flow
import { authGet } from '../../api';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import * as schema from './schema';

const Orders = {
  async getIndex(page: number, perPage: number) {
    const response = await authGet(
      `/v2/orders/mine?page=${page}&per_page=${perPage}&q[state_in][]=complete&q[state_in][]=canceled`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.pastOrders);
    return normalized;
  },
  async getPastOrder(orderNumber: string) {
    const response = await authGet(`/api/v1/orders/${orderNumber}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.order);
    return normalized;
  },
  async getTracker(orderNumber: string) {
    const response = await authGet(`/v2/orders/${orderNumber}/tracker`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.tracker);
    return normalized;
  },
};

export default Orders;
