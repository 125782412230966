// @flow
import React, { Component } from 'react';

import './BuyBox.css';
import QuestionMarkIcon from '../../images/question-mark.png';
import PriceInformationNotice from '../../common/components/PriceInformationNotice';
import AddToCartButton from '../containers/AddToCartButton';
import BuyPrice from '../containers/BuyPrice';

export type Props = {
  product: any,
};

class BuyBox extends Component {
  props: Props;
  render() {
    const product = this.props.product;
    // Boolean that is used to determine if product is in promotion
    const productOnPromotion = product.listPrice > product.price;

    return (
      <div className="buybox-main">
        <div
          className={`buybox-promo-tag ${
            productOnPromotion ? 'buybox-show-promo' : ''
          }`}
        >
          PROMO
        </div>
        <h3 className="buy-box-manufacturer">{product.manufacturer}</h3>
        <h4>Preço Estimado</h4>

        <BuyPrice
          productId={product.id}
          listPrice={product.listPrice}
          price={product.price}
          unit={product.unitForQuantity}
          showPromo={productOnPromotion}
        />

        <div className="buybox-sub-container">
          <img
            src={QuestionMarkIcon}
            alt=""
            className="buy-box-question-mark"
          />
          <div className="buybox-understand-the-price"> Entenda o preço</div>

          <div className="buybox-price-content">
            <PriceInformationNotice />
          </div>
        </div>

        <AddToCartButton
          showNotifyMeButton={true}
          productId={product.id}
          slug={product.slug}
          unitForQuantity={product.unitForQuantity}
          defaultQuantity={product.defaultQuantity}
          incrementQuantity={product.incrementQuantity}
          inStock={product.inStock}
          shouldNotifyUser={product.shouldNotifyUser}
          analyticsEventType={'visit'} // USE ANALYTICS
        />

        {product.unitForQuantity !== 'un' ? (
          <div className="buybox-min-quantity">
            Quantidade mínima: &nbsp;
            {product.unitForQuantity === 'g'
              ? (product.minimumQuantity / 1000).toLocaleString('pt-br', {
                  currency: 'BRL',
                }) + ' Kg'
              : product.minimumQuantity}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default BuyBox;
