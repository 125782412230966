import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
// Actions
import {
  fetchProduct,
  fetchProductRequest,
  fetchProducts,
  fetchProductsRequest,
  fetchRecentlyViewedProducts,
  fetchRecentlyViewedProductsRequest,
  fetchRelatedProducts,
  fetchRelatedProductsRequest,
  fetchPromotionProducts,
  fetchPromotionProductsRequest,
  clearPromotionProducts,
  fetchRecommendedForUserProducts,
  fetchRecommendedForUserProductsRequest,
  clearStaffSelectedProducts,
  fetchMostSoldProducts,
  fetchMostSoldProductsRequest,
  clearMostSoldProducts,
  fetchFrequentlyPurchasedTogetherProducts,
  fetchFrequentlyPurchasedTogetherProductsRequest,
  putNotifyMeWhenProductStockIsRenewed,
  putUnnotifyMeWhenProductStockIsRenewed,
  putNotifyMeWhenProductStockIsRenewedRequest,
  putUnnotifyMeWhenProductStockIsRenewedRequest,
  findFrequentlyPurchasedTogetherProducts,
} from '../actions';
import type { Reducer } from 'redux';
import type { Action } from '../../types';
import type { State } from '../types';

// Current product
const current: Reducer<number | string, Action> = handleActions(
  {
    [fetchProductRequest]: {
      next: (state, action) => action.payload,
    },
    [fetchProduct]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  0,
);

// Scroller: Recently viewed products
const recentlyViewed: Reducer<string, Action> = handleActions(
  {
    [fetchRecentlyViewedProducts]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

// Scroller: Related products
const related: Reducer<string, Action> = handleActions(
  {
    [fetchRelatedProducts]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

// Scroller: Products with offers
const promotion: Reducer<string, Action> = handleActions(
  {
    [fetchPromotionProducts]: {
      next: (state, action) => _.uniq([...state, ...action.payload.result]),
      throw: (state, action) => state,
    },
    [clearPromotionProducts]: (state, action) => [],
  },
  [],
);

// Scroller: Products recommended for the user
// TODO: We should have a proper name for this reducer. E.g.: staffSelectedProducts
const recommendedForUser: Reducer<string, Action> = handleActions(
  {
    [fetchRecommendedForUserProducts]: {
      next: (state, action) => _.uniq([...state, ...action.payload.result]),
      throw: (state, action) => state,
    },
    [clearStaffSelectedProducts]: (state, action) => [],
  },
  [],
);

// Scroller: Products most sold products
const mostSold: Reducer<string, Action> = handleActions(
  {
    [fetchMostSoldProducts]: {
      next: (state, action) => _.uniq([...state, ...action.payload.result]),
      throw: (state, action) => state,
    },
    [clearMostSoldProducts]: (state, action) => [],
  },
  [],
);

// Scroller: Buy Together Products
const frequentlyPurchasedTogether: Reducer<string, Action> = handleActions(
  {
    [fetchFrequentlyPurchasedTogetherProducts]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  [],
);

// All products saved
const products: Reducer<{ [string]: any }, Action> = handleActions(
  {
    [combineActions(
      fetchProduct,
      fetchProducts,
      fetchRecentlyViewedProducts,
      fetchRelatedProducts,
      fetchPromotionProducts,
      fetchRecommendedForUserProducts,
      fetchMostSoldProducts,
      fetchFrequentlyPurchasedTogetherProducts,
      putNotifyMeWhenProductStockIsRenewed,
      putUnnotifyMeWhenProductStockIsRenewed,
    )]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.product,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// lastPage

const initialLastPageState = {
  promotion: false,
  mostSold: false,
  recommended: false,
  frequentlyPurchasedTogether: false,
};

const lastPage: Reducer<string, Action> = handleActions(
  {
    [fetchPromotionProducts]: (state, action) => ({
      ...state,
      promotion: !action.payload.result,
    }),
    [fetchMostSoldProducts]: (state, action) => ({
      ...state,
      mostSold: !action.payload.result,
    }),
    [fetchRecommendedForUserProducts]: (state, action) => ({
      ...state,
      recommended: !action.payload.result,
    }),
    [fetchFrequentlyPurchasedTogetherProducts]: (state, action) => ({
      ...state,
      frequentlyPurchasedTogether: !action.payload.result,
    }),
  },
  initialLastPageState,
);

// Loading
const initialLoadingState = {
  currentProduct: false,
  shouldNotifyUser: false,
  recentlyViewed: false,
  related: false,
  promotion: false,
  recommendedForUser: false,
  multipleProducts: false,
  mostSold: false,
  frequentlyPurchasedTogether: false,
};
const shouldNotifyUserLoading = handleActions(
  {
    [combineActions(
      putNotifyMeWhenProductStockIsRenewed,
      putUnnotifyMeWhenProductStockIsRenewed,
    )]: {
      next: (state, action) => {
        return _.filter(state, o => {
          return o === action.payload;
        });
      },
      throw: (state, action) => [],
    },
    [combineActions(
      putNotifyMeWhenProductStockIsRenewedRequest,
      putUnnotifyMeWhenProductStockIsRenewedRequest,
    )]: (state, action) => {
      if (state.indexOf(action.payload) === -1) {
        return [...state, action.payload];
      }
      return state;
    },
  },
  [],
);

const loading: Reducer<string, Action> = handleActions(
  {
    [fetchProduct]: (state, action) => ({ ...state, currentProduct: false }),
    [fetchProductRequest]: (state, action) => ({
      ...state,
      currentProduct: true,
    }),
    [fetchRecentlyViewedProducts]: (state, action) => ({
      ...state,
      recentlyViewed: false,
    }),
    [fetchRecentlyViewedProductsRequest]: (state, action) => ({
      ...state,
      recentlyViewed: true,
    }),
    [fetchRelatedProducts]: (state, action) => ({ ...state, related: false }),
    [fetchRelatedProductsRequest]: (state, action) => ({
      ...state,
      related: true,
    }),
    [fetchPromotionProducts]: (state, action) => ({
      ...state,
      promotion: false,
    }),
    [fetchPromotionProductsRequest]: (state, action) => ({
      ...state,
      promotion: true,
    }),
    [fetchRecommendedForUserProducts]: (state, action) => ({
      ...state,
      recommendedForUser: false,
    }),
    [fetchRecommendedForUserProductsRequest]: (state, action) => ({
      ...state,
      recommendedForUser: true,
    }),
    [fetchMostSoldProducts]: (state, action) => ({
      ...state,
      mostSold: false,
    }),
    [fetchMostSoldProductsRequest]: (state, action) => ({
      ...state,
      mostSold: true,
    }),
    [fetchFrequentlyPurchasedTogetherProducts]: (state, action) => ({
      ...state,
      frequentlyPurchasedTogether: false,
    }),
    [fetchFrequentlyPurchasedTogetherProductsRequest]: (state, action) => ({
      ...state,
      frequentlyPurchasedTogether: true,
    }),

    [fetchProducts]: (state, action) => ({ ...state, multipleProducts: false }),
    [fetchProductsRequest]: (state, action) => ({
      ...state,
      multipleProducts: true,
    }),
  },
  initialLoadingState,
);

const reducer: Reducer<State, Action> = combineReducers({
  products, // Keeps all products
  // References to the products
  current,
  recentlyViewed,
  related,
  promotion,
  recommendedForUser,
  mostSold,
  frequentlyPurchasedTogether,
  // Loading
  loading,
  shouldNotifyUserLoading,
  // lastPage
  lastPage,
});

export default reducer;
