// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import ImageCarousel from '../components/ImageCarousel';
import {
  ProductPromotionScroller,
  ProductMostSoldScroller,
  ProductRecommendedForUserScroller,
} from '../../products';
import { Helmet } from 'react-helmet';
import LastOrderInfo from '../components/LastOrderInfo';
// Selectors
// Types
import type { Connector } from 'react-redux';
// Images
import BannerFeijoada from '../images/banner_feijoada.jpg';
import BannerChurrasco from '../images/banner_churrasco.jpg';
import BannerBlend from '../images/banner_blend.jpg';

type Props = {};

class HomePage extends Component {
  props: Props;

  render() {
    return (
      <div>
        <Helmet>
          <title>Bigdelivery, Seu açougue virtual</title>
          <meta
            property="og:title"
            content="Bigdelivery - Seu açougue virtual"
          />
          <meta
            name="description"
            content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
          />
          <meta
            name="og:description"
            content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
          />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/image_logo.png`}
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="900" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>

        <ImageCarousel
          images={[
            {
              src: BannerFeijoada,
              link:
                '/busca?query=feijoada&page=1&configure%5Bquery%5D=feijoada',
              alt: 'Banner sobre feijoada.',
            },
            {
              src: BannerChurrasco,
              link:
                '/busca?query=churrasco&page=1&configure%5Bquery%5D=churrasco',
              alt: 'Banner sobre churrasco.',
            },
            {
              src: BannerBlend,
              link: '/blend',
              alt: 'Banner sobre plataforma de blends.',
            },
          ]}
        />
        <LastOrderInfo />
        <ProductRecommendedForUserScroller />
        <ProductPromotionScroller />
        <ProductMostSoldScroller />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default connector(HomePage);
