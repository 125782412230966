// @flow
import './CreditCardAddNewButton.css';
import React, { Component } from 'react';

type Props = {
  isEditing: boolean,
  onClick: () => void,
};

class CreditCardAddNewButton extends Component {
  props: Props;
  render() {
    return (
      <div
        className="credit-card-add-new-button-main"
        onClick={this.props.onClick}
      >
        <div className="credit-card-add-new-button-content">
          <div className="credit-card-add-new-text">
            Clique aqui para adicionar novo cartão online.
          </div>
          <div
            className={
              this.props.isEditing
                ? 'credit-card-add-new-arrow-up'
                : 'credit-card-add-new-arrow-down'
            }
          />
        </div>
      </div>
    );
  }
}

export default CreditCardAddNewButton;
