/** @flow
 * PAYMENT METHOD SELECTOR
 * This components allows the user to select a payment method from a list and
 * calls an onSelect function with that payment method.
 */
import React, { Component } from 'react';
import _ from 'lodash';

import { userAllowedPaymentMethodsConnector } from '../../../user';

import type { PaymentMethod } from '../../types/PaymentMethod';

import './PaymentMethodSelector.css';

type Props = {
  offlineGatewayMethod: PaymentMethod[],
  selected: ?PaymentMethod,
  selectOfflinePaymentMethod: PaymentMethod => void,
  // From connector
  allowedPaymentMethods: PaymentMethod[],
};

class PaymentMethodSelector extends Component {
  props: Props;

  render() {
    const paymentMethods = _.intersectionBy(
      this.props.offlineGatewayMethod,
      this.props.allowedPaymentMethods,
      'name',
    );

    if (!Array.isArray(paymentMethods) || paymentMethods.length === 0) {
      return null;
    }

    return (
      <div className="other-methods-pane-container">
        <h3 className="other-methods-pane-title">Outros métodos</h3>
        <div className="other-methods-picker-main">
          {paymentMethods.map((item, ind) => (
            <div
              key={ind}
              onClick={() => this.props.selectOfflinePaymentMethod(item)}
              className={`other-methods-option-main ${
                this.props.selected === item ? 'other-methods-selected' : ''
              }`}
            >
              {item.name}
            </div>
          ))}
          <div className="payment-on-delivery-info">
            {this.props.selected && this.props.selected.description ? (
              <span>{this.props.selected.description}</span>
            ) : (
              '⠀'
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default userAllowedPaymentMethodsConnector(PaymentMethodSelector);
