// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
import PaneTitle from '../PaneTitle';
import DeliveryPicker from './DeliveryPicker';
import CheckoutContinueButton from '../CheckoutContinueButton';
import DeliverySummary from './DeliverySummary';
import CheckoutErrors from '../CheckoutErrors';
// Analytics
import * as Analytics from '../../../common/analytics';
// Types
import type { ShippingRate } from '../../types';
// Styles
import './DeliveryPane.css';

type Props = {
  errors: string[],
  loading: boolean,
  active: boolean,
  selected: ?ShippingRate,
  shippingRates: ShippingRate[],
  onSubmit: ShippingRate => void,
  dismissErrors: () => void,
};

type State = {
  selectedShippingRate: ?ShippingRate,
};

class DeliveryPane extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);
    // Spree sends a preselected value automatically, we have to find it
    this.state = {
      selectedShippingRate:
        props.shippingRates.length > 0 ? props.shippingRates[0] : null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If the selected shipping rate is not in the list of shippingRates we should reset it
    if (
      nextProps.shippingRates.length > 0 &&
      this.state.selectedShippingRate &&
      !_.find(nextProps.shippingRates, {
        id: this.state.selectedShippingRate.id,
      })
    ) {
      this.setState({ selectedShippingRate: null });
    }
  }

  submitShippingRate(e: Event) {
    e.preventDefault();
    const selectedShippingRate = this.state.selectedShippingRate;
    if (selectedShippingRate) {
      this.props.onSubmit(selectedShippingRate);
    }
  }

  selectShippingRate(sr: ShippingRate) {
    this.setState((state: State, props: Props) => ({
      ...state,
      selectedShippingRate: sr,
    }));
  }

  render() {
    return (
      <div>
        <PaneTitle
          active={this.props.active}
          number={2}
          title={'Agende sua entrega'}
        >
          {!this.props.active && this.props.selected ? (
            <DeliverySummary delivery={this.props.selected} />
          ) : null}
        </PaneTitle>

        {this.props.active ? (
          <div>
            <CheckoutErrors
              errors={this.props.errors}
              dismiss={this.props.dismissErrors}
            />
            <p className="delivery-pane-intstructions">
              Selecione o horário de entrega e clique "confirmar data de
              entrega" para continuar.
            </p>

            <DeliveryPicker
              options={this.props.shippingRates}
              selected={this.state.selectedShippingRate}
              onSelect={this.selectShippingRate.bind(this)}
            />

            <div className="delivery-pane-checkout-continue-button">
              <CheckoutContinueButton
                text="Confirmar data de entrega"
                disabled={!this.state.selectedShippingRate}
                onClick={(e: Event) => {
                  this.submitShippingRate(e);
                  Analytics.logConfirmedDelivery();
                }}
                loading={this.props.loading}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DeliveryPane;
