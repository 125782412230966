// @flow
import React, { Component } from 'react';
// Components
import InputFieldErrorMessage from './InputFieldErrorMessage';
// Types
import type {} from '../../types';
import './InputWithValidation.css';

type Props = {
  value: ?string,
  validationError: string,
  validator: (value: string) => string,
};

type State = {
  value: string,
};

class InputWithValidation extends Component {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value || '' });
    }
  }

  render() {
    const { value, onChange, validationError, ...inputParams } = this.props;

    return (
      <div className="input-with-validation-main">
        <InputFieldErrorMessage message={validationError} />
        <input
          {...inputParams}
          value={this.state.value}
          onChange={e => {
            this.setState({ value: e.target.value });
            onChange(e);
          }}
        />
      </div>
    );
  }
}

export default InputWithValidation;
