// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  itemsSelector,
  itemsWithBlendsSelector,
  itemCountSelector,
  itemTotalSumSelector,
  lineItemTotalByUnitSelector,
  gettingOrderLoadingSelector,
  removingItemLoadingSelector,
} from '../selectors';
// Actions
import { findOrCreateOrder, initiateCheckout, deleteItem } from '../actions';
// Components
import Cart from '../components/Cart';
import { ColorRow } from '../../common';
import { Helmet } from 'react-helmet';
// Types
import type { Dispatch } from '../../types';
import type { LineItem } from '../types';

type Props = {
  items: LineItem[],
  itemsWithBlendId: LineItem[],
  totalPrice: number,
  totalKg: number,
  totalUnit: number,
  loadingOrder: boolean,
  handleChangeQuantity: (lineItemId: number, quantity: number) => void,
  handleRemoveProduct: (lineItemId: number) => void,
  handleInitiateCheckout: () => void,
};

class CartPage extends Component {
  constructor(props: Props) {
    super(props);

    // Try to load current order
    this.props.findOrCreateOrder();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Meu Carrinho - Bigdelivery - Seu açougue virtual</title>
          <meta
            property="og:title"
            content="Meu Carrinho - Bigdelivery - Seu açougue virtual"
          />
          <meta
            name="og:description"
            content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
          />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/image_logo.png`}
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="900" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>

        <ColorRow color="grey" text="MEU CARRINHO" />
        <Cart
          items={this.props.items}
          blends={this.props.itemsWithBlendId}
          totalPrice={this.props.totalPrice}
          numberOfItems={this.props.itemCount} // TODO: Won't this throw a length of undefined error? When there are no items.
          totalKg={this.props.totalKg}
          totalUnit={this.props.totalUnit}
          handleInitiateCheckout={this.props.initiateCheckout}
          loading={this.props.loadingOrder}
          handleRemoveItem={this.props.handleRemoveItem}
          removingItemLoading={this.props.removingItemLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: itemsSelector(state),
    itemsWithBlendId: itemsWithBlendsSelector(state),
    itemCount: itemCountSelector(state),
    totalPrice: itemTotalSumSelector(state),
    totalKg: lineItemTotalByUnitSelector(state, 'g'),
    totalUnit: lineItemTotalByUnitSelector(state, 'un'),
    loadingOrder: gettingOrderLoadingSelector(state),
    removingItemLoading: removingItemLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findOrCreateOrder: () => {
      dispatch(findOrCreateOrder());
    },
    initiateCheckout: () => {
      dispatch(initiateCheckout());
    },
    handleRemoveItem: (lineItemId: number) => {
      dispatch(deleteItem(lineItemId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CartPage);
