// @flow
import React, { Component } from 'react';
// Composers
import { currentOrderNumberConnector } from '../../current-order';
//Others
import { phone } from '../../common';

import './WhatsappButton.css';

class WhatsappButton extends Component {
  render() {
    const whatsAppmessage = `Olá. Gostaria de ter mais informações sobre ${
      this.props.currentOrderNumber
        ? `o meu pedido ${this.props.currentOrderNumber}`
        : 'a BigDelivery'
    }`;
    return (
      <div className={'whatsapp-button'}>
        <a
          href={`https://wa.me/${phone.whatsAppNumber}?text=${whatsAppmessage}`}
          className={'whatsapp-button-a'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={'whatsapp-button-content'}>
            <i className="fa fa-whatsapp" />
            <span> Entre em contato pelo Whatsapp</span>
          </div>
        </a>
      </div>
    );
  }
}

export default currentOrderNumberConnector(WhatsappButton);
