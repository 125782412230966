// @flow
import React, { Component } from 'react';
// Styles
import './BottomRow.css';
// Components
import { DepartmentsNavList } from '../../departments';
// Images
import TelephoneIcon from '../images/telephone.png';
// Other
import { phone } from '../../common';

class BottomRow extends Component {
  render() {
    return (
      <div className="bottomrow-main">
        <DepartmentsNavList />

        <div className="bottomrow-telphone-container">
          <img
            className="bottomrow-telephone-image"
            alt=""
            src={TelephoneIcon}
          />
          <div className="bottomrow-telephone-text">
            <p className="bottomrow-telephone-mini-text">
              {' '}
              Alô! Alô! Faça seu pedido!{' '}
            </p>
            <a
              className="bottomrow-telephone-standard-text"
              href={`tel:${phone.e164}`}
            >
              {' '}
              <span>{phone.ddd}</span> {phone.asStringNumber}{' '}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default BottomRow;
