// @flow
import React from 'react';
// Components
import {
  Button,
  Checkbox,
  validationHelpers,
  InputWithValidation,
} from '../../common';
// Styles
import './LoginBox.css';

import { Link } from 'react-router-dom';

type State = {
  badUsername: string,
  badPassword: string,
};

type Props = {
  isLoading: boolean,
  errors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  onSubmitLogin: () => void,
};

class LoginBox extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      badUsername: '',
      password: '',
      badPassword: '',
      rememberMe: false,
    };
  }

  validateUserInput() {
    if (this.state.username) {
      this.setState({
        badUsername: validationHelpers.user.email(this.state.username),
      });
    }
    if (this.state.password) {
      this.setState({
        badPassword: validationHelpers.user.password(this.state.password),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we have a server error show them instead of the local errors
    if (!nextProps.isLoading) {
      if (nextProps.errors.badUsername) {
        this.setState({ badUsername: 'Email ou senha incorretos' });
      }

      if (nextProps.errors.badPassword) {
        this.setState({ badPassword: 'Email ou senha incorretos' });
      }
    }
  }

  render() {
    return (
      <div className="login-box-main">
        <h3>Já Sou Cadastrado</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.props.onSubmitLogin(
              this.state.username,
              this.state.password,
              this.state,
            );
          }}
        >
          <InputWithValidation
            className={`login-box-input  ${
              (this.state.passwordError && 'login-box-input-error') || ''
            }`}
            type="text"
            placeholder="Email"
            value={this.state.username}
            onChange={e => {
              this.setState({ username: e.target.value });
            }}
            validationError={this.state.badUsername}
            onFocus={e => this.setState({ username: e.target.value })}
            onBlur={() => this.validateUserInput()}
          />
          <InputWithValidation
            className={`login-box-input  ${
              (this.state.badPassword && 'login-box-input-error') || ''
            }`}
            type="password"
            placeholder="Senha"
            value={this.state.password}
            onChange={e => {
              this.setState({ password: e.target.value });
            }}
            validationError={this.state.badPassword}
            onFocus={e => this.setState({ password: e.target.value })}
            onBlur={() => this.validateUserInput()}
          />

          <Checkbox
            className="login-box-remember-me"
            value={this.state.rememberMe}
            tooltip="Reduz o número de vezes que você terá de fazer login neste
                     dispositivo.&#10;Por questões de segurança, utilize apenas em dispositivos pessoais."
            onChange={e => this.setState({ rememberMe: e.target.checked })}
            label={<span>Mantenha-me conectado</span>}
          />

          <Button
            text="ENTRAR"
            icon=""
            buttonType="button-primary"
            loading={this.props.isLoading}
            onClick={() => {}}
            disabled={!this.state.username || !this.state.password}
          />

          <div className="login-box-forgot-password">
            <Link to="/esqueci-senha/">Perdi minha senha</Link>
          </div>
        </form>
      </div>
    );
  }
}

export default LoginBox;
