// @flow
import React, { Component } from 'react';
// Components
import { AddBeforeExistsButton, AddAfterExistsButton } from '../../common';
// Composers
import { connect } from 'react-redux';
// Selectors
import {
  addingBlendLoadingSelector,
  changingBlendLoadingSelector,
} from '../selectors';
// Types
import type { Connector } from 'react-redux';
import type { Blend } from '../types';
// Actions
import * as actions from '../actions';
//Styles
import './AddBlendToCartButton.css';

type OwnProps = {
  productId: number,
  unitForQuantity: 'g' | 'un',
  incrementQuantity: number,
  defaultQuantity: number,
};

type Props = {
  /* :: ...OwnProps, */
  addingLoading: boolean,
  changingLoading: boolean,
  blend?: Blend,
  clickable: boolean,
  handleAddItem: () => void,
  handleIncreaseQuantity: () => void,
  handleDecreaseQuantity: () => void,
};
class AddBlendToCartButton extends Component {
  props: Props;

  render() {
    // If we don't have a blend we should show the option to add the blend. Else, the option to change its quantity.
    if (!this.props.blend) {
      return (
        <AddBeforeExistsButton
          addItem={this.props.handleAddItem}
          loading={this.props.addingLoading}
          clickable={this.props.clickable}
        />
      );
    } else {
      return (
        <AddAfterExistsButton
          unitForQuantity={this.props.unitForQuantity}
          currentQuantity={this.props.blend.quantity}
          increaseQuantity={this.props.handleIncreaseQuantity}
          decreaseQuantity={this.props.handleDecreaseQuantity}
          loading={this.props.changingLoading}
        />
      );
    }
  }
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    addingLoading: addingBlendLoadingSelector(state, ownProps.blendTemplateId),
    changingLoading: changingBlendLoadingSelector(
      state,
      ownProps.blend ? ownProps.blend.id : null,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleAddItem: () => {
      dispatch(
        // TODO: This should be better managed for when this component is in the cart versus in the index page for blend templates
        actions.createBlend(ownProps.blendTemplateId, ownProps.defaultQuantity),
      );
    },
    handleIncreaseQuantity: () => {
      dispatch(
        actions.updateBlendQuantity(
          ownProps.blend.id,
          ownProps.blend.incrementQuantity,
        ),
      );
    },
    handleDecreaseQuantity: () => {
      dispatch(
        actions.updateBlendQuantity(
          ownProps.blend.id,
          -ownProps.blend.incrementQuantity,
        ),
      );
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(AddBlendToCartButton);
