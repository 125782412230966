// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { CNPJ } from 'cpf_cnpj';
// Helper functions
import {
  getTimeWindow,
  getTimeFormat2L,
  getTimeFormat3l,
  Button,
} from '../../common';
// Composers
import { withRouter } from 'react-router-dom';
// Selectors
import {
  currentOrderSelector,
  currentOrderLineItemsSelector,
  gettingOrderLoadingSelector,
  currentOrderSelectedShipmentSelector,
} from '../selectors';
import { copyingOrderLoadingSelector } from '../../current-order/selectors';
//Conectors
// TODO: FIX THIS IMPORT!! For some reason cannot import from index.js
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
//Styles
import './PastOrderPage.css';
// Actions
import { getPastOrder } from '../actions';
import { copyingOrder } from '../../current-order/actions';
// Components
import { PageLoader } from '../../common';
import NoOrderFound from '../components/NoOrderFound';
import LineItemList from '../components/LineItemList';
import OrderTotal from '../components/OrderTotal';
import OrderTracker from '../../common/components/OrderTracker';
// Types
import type { Dispatch } from '../../types';
import type { Order } from '../types';

type Props = {
  order: Order[],
  loading: boolean,
  lineItems: LineItem[],
  shippingRate: any,
  getPastOrder: (orderNumber: string) => void,
  copyingOrderLoading: boolean,
};

class PastOrderPage extends Component {
  constructor(props: Props) {
    super(props);

    // Get past orders
    this.props.getPastOrder(this.props.match.params.orderNumber);
  }

  handleClick = (orderNumber, orderNumberFrom) => {
    const conf = window.confirm(
      `Itens do pedido ${orderNumberFrom} serão adicionados ao seu carrinho (pedido ${orderNumber}). Deseja continuar?`,
    );

    if (conf) {
      this.props.copyingOrder(orderNumber, orderNumberFrom);
    }
  };

  render() {
    const order = this.props.order;

    if (this.props.loading) {
      return <PageLoader />;
    }

    // If the order does not exist we should return an error page
    if (!order) {
      return <NoOrderFound />;
    }

    const shippingRate = this.props.shippingRate;
    const payment = _.last(order.payments); // Last payment used

    return (
      <div className="past-order-main">
        <h3 className="past-order-title">Detalhes do Pedido</h3>
        <OrderTracker order={order} loading={this.props.loading} />
        <h3 className="past-order-details">
          Efetuado dia {getTimeFormat3l(order.completedAt)}
        </h3>

        <div className="past-order-information">
          <div className="past-order-information-container">
            <div className="past-order-information-sub-container">
              <h3>Endereço de entrega</h3>
              {order.shipAddress.company ? (
                <p>{order.shipAddress.company}</p>
              ) : null}
              <p>
                {order.shipAddress.address1}, {order.shipAddress.address2}
              </p>
              <p>
                {order.shipAddress.city}, {order.shipAddress.stateText}{' '}
                {order.shipAddress.zipcode}
              </p>
              {order.shipAddress.cnpj ? (
                <p>CNPJ: {CNPJ.format(order.shipAddress.cnpj)}</p>
              ) : null}
            </div>

            <div>
              {payment ? (
                <div className="past-order-information-sub-container">
                  <h3>Método de pagamento</h3>
                  {/* Display only if paid with credit card */}
                  {payment.paymentMethod.id === 1 ? (
                    <div className="past-order-information-payment-method">
                      <p>{payment.paymentMethod.name} </p>
                      <p>XXX XXX XXX {payment.source.lastDigits} </p>
                      <p>{payment.source.name} </p>
                    </div>
                  ) : (
                    <div className="past-order-information-payment-method">
                      <p>{payment.paymentMethod.name} </p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="past-order-information-container-rigth">
            {shippingRate ? (
              <div className="past-order-information-sub-container">
                <h3>Forma da entrega</h3>
                <p>
                  {shippingRate.name} das{' '}
                  {getTimeWindow(
                    shippingRate.deliveryWindowStart,
                    shippingRate.deliveryWindowEnd,
                  )}
                </p>
                <p>{getTimeFormat2L(shippingRate.deliveryWindowStart)}</p>
              </div>
            ) : null}
            {this.props.copyingOrderLoading ? (
              <button className="past-order-copy-button">
                <i className="fa fa-spinner fa-pulse" />
              </button>
            ) : (
              <button
                className="past-order-copy-button"
                title="Copiar pedido."
                onClick={() =>
                  this.handleClick(
                    this.props.currentOrderNumber,
                    this.props.order.number,
                  )
                }
              >
                COPIAR PEDIDO
              </button>
            )}
            {this.props.order.saleReceiptUrl &&
              ((
                <a
                  className="see-nfe-button"
                  href={`${this.props.order.saleReceiptUrl}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i class="fa fa-file-text-o" aria-hidden="true"></i> VER NF-e
                </a>
              ) ||
                null)}
          </div>
        </div>

        <LineItemList
          orderNumber={this.props.order.number}
          items={this.props.lineItems}
        />

        <OrderTotal
          itemTotal={order.itemTotal}
          lineItems={this.props.lineItems}
          shipTotal={order.shipTotal}
          adjustmentTotal={order.adjustmentTotal}
          producedAmount={order.producedAmount}
          fulfillmentState={order.fulfillmentState}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    order: currentOrderSelector(state),
    lineItems: currentOrderLineItemsSelector(state),
    shippingRate: currentOrderSelectedShipmentSelector(state),
    loading: gettingOrderLoadingSelector(state),
    copyingOrderLoading: copyingOrderLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getPastOrder: (orderNumber: string) => {
      dispatch(getPastOrder(orderNumber));
    },
    copyingOrder: (orderNumber: string, orderNumberFrom: string) => {
      dispatch(copyingOrder(orderNumber, orderNumberFrom));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(
  currentOrderNumberConnector(connector(PastOrderPage)),
);
