// @flow
import React, { useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import './ProductSquare.css';
import { ProgressiveImageLoader, ProductPrice } from '../../common';
import type { ProductImage } from '../../types/ProductImage';

import * as Analytics from '../../common/analytics';

import { Link } from 'react-router-dom';
import { AddToCartButton } from '../../current-order';

type Props = {
  productImage: ?ProductImage,
  productName: string,
  productManufacturer: string,
  productListPrice: number,
  productPrice: number,
  productUnit: string,
  productSlug: string,
  productId: number,
  defaultQuantity: number,
  incrementQuantity: number,
  inStock: boolean,
};

const ProductSquare = ({
  productSlug,
  productImage,
  productName,
  productPrice,
  productListPrice,
  productManufacturer,
  productUnit,
  productId,
  parentProductId,
  productTotalOnHand,
  defaultQuantity,
  inStock,
  incrementQuantity,
  analyticsViewSource,
}) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (visibility) {
      Analytics.logProductImpression(
        {
          variantId: productId,
          productId: parentProductId,
          name: productName,
          price: productPrice,
          manufacturer: productManufacturer,
          unitForQuantity: productUnit,
          totalOnHand: productTotalOnHand,
          listPrice: productListPrice,
          inStock: inStock,
          images: productImage ? [productImage] : [],
          incrementQuantity: incrementQuantity,
          defaultQuantity: defaultQuantity,
        },
        window.location.pathname,
        analyticsViewSource,
      );
    }
  }, [visibility]);

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setVisibility(isVisible);
      }}
    >
      <div className="product-sqaure-main">
        <div className="product-square-image">
          <Link
            className="product-square-link"
            to={`/produtos/${productSlug}/`}
          >
            <ProgressiveImageLoader
              selectedImage={productImage}
              imageToShow={'product'}
            />
          </Link>
        </div>

        <Link className="product-square-link" to={`/produtos/${productSlug}/`}>
          <h3 className="product-square-title">{productName}</h3>
        </Link>

        <h4>{productManufacturer}</h4>

        <ProductPrice
          productListPrice={productListPrice}
          productPrice={productPrice}
          productUnit={productUnit}
        />

        <div className="product-square-button">
          <AddToCartButton
            productId={productId}
            unitForQuantity={productUnit}
            defaultQuantity={defaultQuantity}
            incrementQuantity={incrementQuantity}
            inStock={inStock}
            // USE ANALYTICS
            analyticsEventType={'impression'}
            analyticsViewSource={analyticsViewSource}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default ProductSquare;
