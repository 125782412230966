// @flow
import React, { Component } from 'react';

import './CartIndicatorButton.css';
import CartIcon from '../../common/images/cart-icon.png';

type Props = {
  quantity: number,
  loading: boolean,
};

class CartIndicatorButton extends Component {
  props: Props;
  render() {
    return (
      <div className="cart-indicator-button-container">
        <img className="cart-indicator-button-icon" src={CartIcon} alt="" />
        {!this.props.loading ? (
          this.props.quantity
        ) : (
          <i className="fa fa-spinner fa-pulse fa-1x fa-fw cart-indicator-spinner" />
        )}
      </div>
    );
  }
}

export default CartIndicatorButton;
