import React, { useState } from 'react';
// Redux related
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Components and Containers
import { Helmet } from 'react-helmet';
import QuantitySelector from '../components/QuantitySelector';
import OptionValueList from './OptionValueList';
import ComplementaryCategoryPickerList from './ComplementaryCategoryPickerList';
import Slider from 'rc-slider';
import { Button } from '../../common';
// Analytics
import * as Analytics from '../../common/analytics';
// Actions and Selectors
import * as actions from '../actions';
import * as selectors from '../selectors';
// Styles
import './BarbecueForm.css';
// types
import type { Product } from '../../products';
import type { BarbecueOptionValuesType } from '../types';

type Props = {
  barbecueCreated: boolean,
  setBarbecueCreated: () => void,
  //LocalProps
  barbecueOptionValues: BarbecueOptionValuesType,
  fetchBarbecueCategories: () => void,
  fetchOptionValues: () => void,
  createBarbecueLoading: boolean,
  selectedVariants: Array<Product>,
  putNewBarbecue: (
    amountOfAdults: number,
    amountOfChildren: number,
    preferredOptionValueId: Array<number>,
    desiredCategories: Array<number>,
  ) => void,
};
const BarbecueForm = ({
  fetchBarbecueCategories,
  fetchOptionValues,
  putNewBarbecue,
  barbecueOptionValues,
  createBarbecueLoading,
  selectedVariants,
  barbecueCreated,
  setBarbecueCreated,
}: Props) => {
  // const sliderMin = 0;
  // const sliderMax = 100;
  // const sliderNumberOfDots = 4;

  const [fatPercentage, setFatPercentage] = useState(50);

  const [amountOfChildren, setAmountOfChildren] = useState(0);
  const [amountOfAdults, setAmountOfAdults] = useState(4);

  const [selectedOptionValue, setSelectedOptionValue] = useState(null);

  const [selectedComplementaryCategories, setSelectedComplementaryCategories] =
    useState([]);

  const handleAmountOfAdultChange = (n: number) => {
    if (n < 0 && amountOfAdults <= 4) return;
    setAmountOfAdults(amountOfAdults + n);
  };
  const handleAmountOfChildrenChange = (n: number) => {
    setAmountOfChildren(amountOfChildren + n);
  };

  const handleSelectedOptionValueToggle = (id: number) => {
    setSelectedOptionValue(id);
  };

  const handleSelectedComplementaryCategoriesToggle = (id: number) => {
    let temporaryArray = [...selectedComplementaryCategories];

    const indexLocation = temporaryArray.indexOf(id);
    // If the id is contained in the complementaryCategories array, this function removes it, otherwise adds it.
    indexLocation === -1
      ? temporaryArray.push(id)
      : temporaryArray.splice(indexLocation, 1);

    setSelectedComplementaryCategories(temporaryArray);
  };

  const handleScroll = () => {
    let element = document.getElementById('barbecue-calculator-form-content');

    var curtop = 0;
    if (element.offsetParent) {
      do {
        curtop += element.offsetTop;
      } while ((element = element.offsetParent));
    }

    window.scroll(0, [curtop]);
  };

  return (
    <div
      className={`barbecue-calculator-form-container created-${barbecueCreated}`}
    >
      <Helmet>
        <title>Meu Churrasco - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="Meu Churrasco - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="description"
          content="Nos diga as suas preferências e nós escolheremos as melhores carnes para você!"
        />
        <meta
          name="og:description"
          content="Nos diga as suas preferências e nós escolheremos as melhores carnes para você!"
        />
      </Helmet>
      <div id="barbecue-calculator-form-content">
        <div className="barbecue-calculator-people-quantity-selector">
          <h2 className="quantity-selector-title title">
            1 - Quantas pessoas vão?
          </h2>
          <h4 className="quantity-selector-subtitle subtitle">
            Nos informe a quantidade de pessoas que vão para o churrasco.
          </h4>
          <div className="quantity-selector-container">
            <QuantitySelector
              handleAmountOfPeopleChange={handleAmountOfAdultChange}
              quantity={amountOfAdults}
              title="Adultos"
            />
            <QuantitySelector
              handleAmountOfPeopleChange={handleAmountOfChildrenChange}
              quantity={amountOfChildren}
              title="Crianças"
            />
          </div>
        </div>
        {/*
        <div className="barbecue-calculator-fat-selector">
          <h2 className="fat-selector-title title">
            2 - Qual a quantidade de gordura que você gosta?
          </h2>
          <h4 className="fat-selector-subtitle subtitle">
            Deslize a barra abaixo para selecionar o nível de gordura presente
            nas carnes.
          </h4>
          <div className="fat-selector-slider-container">
            <Slider
              value={fatPercentage}
              onChange={newFatPercentage => {
                setFatPercentage(newFatPercentage);
              }}
              trackStyle={{ backgroundColor: '#eb5b1e' }}
              dots={true}
              dotStyle={{ borderColor: '#eb5b1e' }}
              handleStyle={{
                borderColor: '#eb5b1e',
                boxShadow: '0 0 5px tint(#eb5b1e, 20%)',
              }}
              min={sliderMin}
              max={sliderMax}
              step={(sliderMax - sliderMin) / sliderNumberOfDots}
            />
            <div className="fat-selector-slider-texts">
              <div>POUCA GORDURA</div>
              <div>MUITA GORDURA</div>
            </div>
          </div>
        </div>
            */}
        <div className="barbecue-calculator-cut-type">
          <h2 className="cut-type-title title">
            2 - Qual tipo de corte você prefere?
          </h2>
          <h4 className="cut-type-subtitle subtitle">
            Precisamos definir o melhor corte para o seu churrasco.
          </h4>
          <OptionValueList
            selectedOptionValue={selectedOptionValue}
            setSelectedOptionValue={setSelectedOptionValue}
            handleOptionValueClick={handleSelectedOptionValueToggle}
          />
        </div>

        <div className="barbecue-calculator-accompaniments">
          <h2 className="accompaniments-title title">3 - E os complementos?</h2>
          <h4 className="accompaniments-subtitle subtitle">
            Um churrasco não são somente carnes, escolha os complementos.
          </h4>
          <ComplementaryCategoryPickerList
            handleClick={handleSelectedComplementaryCategoriesToggle}
            selectedComplementaryCategories={selectedComplementaryCategories}
          />
        </div>
        <div className="submit-barbecue-form-button-container">
          <Button
            text="CRIAR CHURRASCO!"
            icon=""
            buttonType="button-secondary"
            loading={createBarbecueLoading}
            onClick={() => {
              handleScroll();

              Analytics.logCreatedBarbecue(
                amountOfAdults,
                amountOfChildren,
                selectedOptionValue,
              );

              setBarbecueCreated(true);
              putNewBarbecue(
                amountOfAdults,
                amountOfChildren,
                selectedOptionValue,
                selectedComplementaryCategories,
                fatPercentage,
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    barbecueOptionValues: selectors.barbecueOptionValuesSelector(state),
    createBarbecueLoading: selectors.newBarbecueLoadingSelector(state),
    selectedVariants: selectors.barbecueSelectedVariantsSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchOptionValues: () => {
      dispatch(actions.fetchOptionValues());
    },
    putNewBarbecue: (
      amountOfAdults: number,
      amountOfChildren: number,
      preferredOptionValueId: number,
      desiredCategories: Array<number>,
      fatPercentage: number,
    ) => {
      const quantityPerAdult = 400;
      const quantityPerChild = 200;
      const totalDesiredQuantity =
        amountOfAdults * quantityPerAdult + amountOfChildren * quantityPerChild;

      dispatch(
        actions.createBarbecue(
          totalDesiredQuantity,
          preferredOptionValueId,
          desiredCategories,
          fatPercentage,
        ),
      );
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BarbecueForm);
