import React, { useEffect } from 'react';
// Redux related
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
// Selectors and Actions
import * as selectors from '../selectors';
// Connectors
import { addItemToCartConnector } from '../../current-order';
import { findMultipleProductsConnector } from '../../products';
// Containers and components
import { Button, quantityConverter } from '../../common';
import BarbecueCartList from './BarbecueCartList';
// Analytics
import * as Analytics from '../../common/analytics';
// Images
import PreCartImage from '../images/pre-cart-image.png';
// types
import { Product } from '../../products';
// Styles
import './BarbecueCart.css';

type Props = {
  barbecueCreated: boolean,
  setBarbecueCreated: () => void,
  //Local Props
  newBarbecueLoading: boolean,
  totalUnit: number,
  totalKg: number,
  totalPrice: number,
  findMultipleProducts: (ids: Array<number>) => void,
  fetchingProductsLoading: boolean,
  selectedVariantQuantity: (id: number) => number,
  selectedVariants: Array<Product>,
  addItemToCart: (productId: number, quantity: number) => void,
};

const BarbecueCart = ({
  newBarbecueLoading,
  totalUnit,
  totalKg,
  totalPrice,
  findMultipleProducts,
  fetchingProductsLoading,
  selectedVariants,
  selectedVariantQuantity,
  addItemToCart,
  barbecueCreated,
  setBarbecueCreated,
}: Props) => {
  const selectedVariantsIds = selectedVariants.map(variant => variant.id);

  useEffect(() => {
    findMultipleProducts(selectedVariantsIds);
  }, [selectedVariants]);

  if (newBarbecueLoading || fetchingProductsLoading) {
    // If the barbecue cart is loading, return this.
    return (
      <div
        className={`barbecue-cart-container loading-container created-${barbecueCreated}`}
      >
        <div className="loading">
          <i
            className="fa fa-spinner fa-pulse fa-4x loading-icon"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
  if (barbecueCreated) {
    // If the user has already clicked the create barbecue button, return this
    return (
      <div className={`barbecue-cart-container created-${barbecueCreated}`}>
        <div className="barbecue-cart-content barbecue-created">
          <div className="barbecue">
            <div className="barbecue-title">MEU CHURRASCO</div>
            <div className="barbecue-cart-list-container">
              <BarbecueCartList selectedVariantsIds={selectedVariantsIds} />
              <div className="barbecue-cart-info">
                <div className="line"></div>
                <div className="total-quantity">
                  {`Total: ${quantityConverter(totalKg, 'g')}`}
                  {totalUnit > 0 ? ` e ${totalUnit} un` : ''}
                </div>
                <div className="total-price">
                  <div className="text">Subtotal estimado:</div>
                  <div className="price">
                    {parseFloat(totalPrice).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                </div>
              </div>
              <div className="barbecue-add-all-to-cart-button">
                <Button
                  buttonType="button-secondary"
                  icon=""
                  text="ADICIONAR AO CARRINHO"
                  onClick={() => {
                    Analytics.logAddedBarbecueToCart();

                    selectedVariants.forEach(variant => {
                      addItemToCart(
                        variant.id,
                        selectedVariantQuantity(variant.id),
                      );
                    });
                  }}
                />
              </div>
            </div>

            <div className="barbecue-back-button">
              <Button
                buttonType="button-secondary"
                icon=""
                text="EDITAR CHURRASCO"
                onClick={() => {
                  setBarbecueCreated(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    // If the user has not clicked on create barbecue button, return this
    <div className={`barbecue-cart-container created-${barbecueCreated}`}>
      <img
        src={PreCartImage}
        className="barbecue-cart-image"
        alt="churrasco"
      ></img>
      <div className="barbecue-cart-content">
        <div className="barbecue-cart-container-text title">
          Deixe seu churrasco por nossa preparação!
        </div>
        <div className="barbecue-cart-line"></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    newBarbecueLoading: selectors.newBarbecueLoadingSelector(state),
    selectedVariants: selectors.barbecueSelectedVariantsSelector(state),
    totalKg: selectors.barbecueTotalQuantitySelector(state, 'g'),
    totalUnit: selectors.barbecueTotalQuantitySelector(state, 'un'),
    totalPrice: selectors.barbecueTotalPriceSelector(state),
    selectedVariantQuantity: (id: number) =>
      selectors.barbecueSelectedVariantQuantity(state, id),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(
  findMultipleProductsConnector(addItemToCartConnector(BarbecueCart)),
);
