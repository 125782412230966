// @flow
import './AddAfterExistsButton.css';
import React, { Component } from 'react';

type Props = {
  currentQuantity: number,
  unitForQuantity: 'g' | 'un',
  loading: boolean,
  disableDecreaseQuantity?: boolean,
  disableIncrementQuantity?: boolean,
  decreaseQuantity: () => void,
  increaseQuantity: () => void,
};

class AddAfterExistsButton extends Component {
  props: Props;
  render() {
    return (
      <div className="add-after-exists-button-main">
        <button
          onClick={this.props.decreaseQuantity}
          disabled={this.props.disableDecreaseQuantity}
        >
          -
        </button>
        <div className="add-after-exists-button-content">
          {this.props.unitForQuantity === 'g'
            ? (this.props.currentQuantity / 1000).toLocaleString('pt-br', {
                currency: 'BRL',
              }) + ' Kg'
            : this.props.currentQuantity}
        </div>
        <button
          onClick={this.props.increaseQuantity}
          disabled={this.props.disableIncrementQuantity}
        >
          +
        </button>

        {this.props.loading ? (
          <div className="add-after-exists-loader">
            <i className="fa fa-spinner fa-pulse"></i>
          </div>
        ) : null}
      </div>
    );
  }
}

AddAfterExistsButton.defaultProps = {
  disableDecreaseQuantity: false,
  disableIncrementQuantity: false,
};

export default AddAfterExistsButton;
