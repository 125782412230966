// @flow
import React from 'react';
// Styles
import './ConfirmationPage.css';
//Components
import { Link } from 'react-router-dom';
// Images
import confirmation from '../images/confirmation.png';
// Other
import { phone } from '../../common';

type Props = {
  orderNumber: number,
  userEmail: string,
};

const ConfirmationPage = props => {
  return (
    <div className="confirmation-page-main">
      <div className="confirmation-page-content">
        <div className="confirmation-page-text">
          <i className="fa fa-check" aria-hidden="true" />
          <h3>
            Seu pedido #{props.orderNumber} foi recebido. Agora é só relaxar!
          </h3>
        </div>

        <img
          className="confirmation-page-image"
          alt="Pedido efetuado com sucesso"
          src={confirmation}
        />

        <div className="confirmation-page-button-container">
          <Link to="/">
            <h3>Fazer Novo Pedido</h3>
          </Link>
        </div>
      </div>
      <div className="disclaimer-message-confirmation">
        <p>
          Em breve, você receberá um email no {props.userEmail} com os dados
          desse pedido. Para garantir a sua segurança e a de nossos outros
          clientes esse pedido irá passar por uma avaliação de crédito. O nome
          que aparecerá na sua fatura do cartão de crédito será "Costa Wense".
          Caso tenha dúvidas, estamos disponíveis pelo chat ou telefone{' '}
          {phone.asStringFull}.
        </p>
      </div>
    </div>
  );
};

export default ConfirmationPage;
