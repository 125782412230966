// @flow
import { schema } from 'normalizr';

export const address = new schema.Entity('address');
export const addresses = new schema.Array(address);

export const creditCard = new schema.Entity('creditCards');
export const creditCards = new schema.Array(creditCard);

export const allowedPaymentMethod = new schema.Entity('allowedPaymentMethods');
export const allowedPaymentMethods = new schema.Array(allowedPaymentMethod);

export const user = new schema.Entity(
  'user',
  {
    addresses: [address],
    creditCards: [creditCard],
    allowedPaymentMethods: [allowedPaymentMethod],
  },
  { idAttribute: 'email' },
);
