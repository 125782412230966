// @flow
import React from 'react';
// Components
import SimpleLogo from '../components/SimpleLogo';
// Styles
import './SimpleHeader.css';

type Props = {
  color: string,
  text: string,
};

const SimpleHeader = ({ color, text }: Props) => {
  return (
    <span className="simple-header-main">
      <SimpleLogo />
    </span>
  );
};

export default SimpleHeader;
