// @flow
import { createAction } from 'redux-actions';
// API
import Recipe from '../api';
// Types
import type { Dispatch } from '../../types';
// Logger
import { logException } from '../../logHelper';

/***********************
 *** FETCH RECIPES BY PRODUCT ID***
 ***********************/

export const fetchRecipesByProduct = createAction('FETCH_RECIPES_BY_PRODUCT');
export const fetchRecipesByProductRequest = createAction(
  'FETCH_RECIPES_BY_PRODUCT_REQUEST',
);

export function findRecipesByProduct(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchRecipesByProductRequest());
    try {
      const recipeResponse = await Recipe.getRecipesByProduct(id);
      dispatch(fetchRecipesByProduct(recipeResponse));
    } catch (err) {
      dispatch(fetchRecipesByProduct(err));
      logException(err);
    }
  };
}
