// @flow
import React, { Component } from 'react';
import './ImageGallery.css';
import { ProgressiveImageLoader } from '../../common';
import type { ProductImage } from '../types/ProductImage';

type Props = {
  images: ProductImage[],
  isLoading: boolean,
};

type State = {
  current: ?number,
};

class ImageGallery extends Component {
  props: Props;
  state: State;
  selectImage: Function;
  constructor(props: Props) {
    super(props);
    this.state = {
      // If the array is empty, index is null (so we don't access an empty array)
      current: props.images.length !== 0 ? 0 : null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.images.length !== 0) {
      this.setState({
        current: 0,
      });
    }
  }

  selectImage = (selectedIndex: number) => {
    if (this.props.images[selectedIndex]) {
      this.setState({
        current: selectedIndex,
      });
    } else {
      this.setState({
        current: null,
      });
    }
  };

  render() {
    let selected = null;

    if (
      this.props.images != null &&
      (this.state.current || this.state.current === 0)
    ) {
      selected = this.props.images[this.state.current];
    }

    if (this.props.isLoading) {
      return (
        <div className="loading-spinner text-center">
          <i className="fa fa-spinner fa-pulse fa-4x" aria-hidden="true" />
        </div>
      );
    }

    return (
      <div className="image-gallery">
        <div className="image-gallery-main">
          <ProgressiveImageLoader
            selectedImage={selected}
            imageToShow={'large'}
          />
        </div>

        {/* THUMBNAILS */}
        <div className="image-gallery-thumbnails">
          {this.props.images.map((img, i) => (
            <a
              key={i}
              onClick={() => this.selectImage(i)}
              className={`p-2 image-gallery-thumb ${i === this.state.current ? 'image-gallery-thumb-active' : ''}`}
            >
              <img src={img.smallUrl} alt={img.alt} />
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default ImageGallery;
