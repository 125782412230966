// @flow
import { CNPJ, CPF } from 'cpf_cnpj';
import _ from 'lodash';
import { ie } from 'br-validations';

const address = {
  name: (value: string) => {
    if (value === '') {
      return 'Nome é obrigatório';
    } else if (value.length > 100) {
      return 'Nome muito longo. Max 100 letras';
    } else {
      return '';
    }
  },

  addressLine1: (value: string) => {
    if (value === '') {
      return 'Endereço é obrigatório';
    } else if (value.length > 100) {
      return 'Nome muito longo. Max 100 letras';
    } else if (value.length < 5) {
      return 'Nome muito curto.';
    } else {
      return '';
    }
  },

  addressLine2: (value: string) => {
    if (value.length > 100) {
      return 'Complemento muito longo. Max 100 letras';
    } else {
      return '';
    }
  },

  city: (value: string) => {
    if (value === '') {
      return 'Cidade é obrigatório';
    } else if (value.length > 30) {
      return 'Nome muito longo. Max 40 letras';
    } else {
      return '';
    }
  },

  zipcode: (value: string) => {
    if (value === '') {
      return 'CEP é obrigatório';
    } else if (value.replace(/-|\.|\s/g, '').length !== 8) {
      return 'CEP são 8 números';
    } else if (value[0] !== '4') {
      return 'Fora da area de cobertura';
    } else {
      return '';
    }
  },

  neighborhood: (value: string) => {
    if (value === '') {
      return 'Bairro é obrigatório';
    } else if (value.length > 30) {
      return 'Nome muito longo. Max 40 letras';
    } else {
      return '';
    }
  },

  telephone: (value: string) => {
    if (value === '') {
      return 'Telefone é obrigatório';
    } else if (
      value.replace(/-|\.|\s/g, '').length < 9 ||
      value.replace(/-|\.|\s/g, '').length > 13
    ) {
      return 'Tel deve ter 9 a 13 números com DDD';
    } else {
      return '';
    }
  },

  pointOfReference: (value: string) => {
    if (value === '') {
      return 'Ponto de referencia é obrigatório';
    } else if (value.length > 100) {
      return 'Muito longo. Max 100 letras';
    } else {
      return '';
    }
  },

  cnpj: (value: string) => {
    if (value.length === 0) {
      // CNPJ is missing or invalid?
      return 'Campo é obrigatório';
    } else if (value.replace(/-|\.|\s|\//g, '').length !== 14) {
      // period, space and dashes do not count
      return 'CNPJ são 14 números';
    } else if (!CNPJ.isValid(value)) {
      return 'CNPJ inválido';
    } else {
      return '';
    }
  },
  ieCompany: (value: string) => {
    if (!value) return '';
    if (
      !(
        value.replace(/-|\.|\s|\//g, '').length === 8 ||
        value.replace(/-|\.|\s|\//g, '').length === 9
      )
    ) {
      // period, space and dashes do not count
      return 'Inscrição Estadual são 8 ou 9 números';
    } else if (!ie('BA').validate(value)) {
      return 'Inscrição Estadual inválida';
    } else {
      return '';
    }
  },

  company: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (value.length > 50) {
      return 'Nome muito longo';
    } else {
      return '';
    }
  },
};

const creditCard = {
  name: (value: string) => {
    if (value === '') {
      return 'Nome é obrigatório';
    } else if (value.split(' ').length < 2) {
      return 'Nome incorreto. Verifique o cartão';
    } else if (value.length > 100) {
      return 'Nome muito longo. Max 100 letras';
    } else {
      return '';
    }
  },

  number: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (!value.match(/^\d+$/)) {
      return 'Cartão deve conter apenas números';
    } else if (value.length <= 10 || value.length >= 20) {
      return 'O número deve conter entre 10 e 20 digitos';
    } else {
      return '';
    }
  },

  cvv: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (!value.match(/^\d+$/)) {
      return 'CVV deve conter apenas números';
    } else if (value.length < 2 || value.length > 5) {
      return 'O CVV deve conter entre 2 e 5 dígitos';
    } else {
      return '';
    }
  },

  year: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (!value.match(/^\d+$/)) {
      return 'Ano deve conter apenas números';
    } else if (value.length !== 4 && value.length !== 2) {
      return 'O ano deve ter 2 ou 4 dígitos. Ex: 2025';
    } else if (
      value.length === 4 &&
      (parseInt(value, 10) < 2017 || parseInt(value, 10) > 2100)
    ) {
      return 'Ano inválido';
    } else if (value.length === 2 && parseInt(value, 10) < 17) {
      return 'Ano inválido';
    } else {
      return '';
    }
  },

  month: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (!value.match(/^\d+$/)) {
      return 'Mês deve conter apenas números';
    } else if (value.length !== 2) {
      return 'O mês deve ter 2 dígitos. Ex: 04=Abril.';
    } else if (parseInt(value, 10) < 1 || parseInt(value, 10) > 12) {
      return 'Mês deve ser número entre 1 e 12';
    } else {
      return '';
    }
  },
};

const user = {
  firstName: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (value.length > 30) {
      return 'Nome muito longo';
    } else {
      return '';
    }
  },

  lastName: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (value.length > 30) {
      return 'Nome muito longo';
    } else {
      return '';
    }
  },

  cpf: (value: string) => {
    if (value.length === 0) {
      // CPF is missing or invalid?
      return 'Campo é obrigatório';
    } else if (value.replace(/-|\.|\s/g, '').length !== 11) {
      // period, space and dashes do not count
      return 'CPF são 11 números';
    } else if (!CPF.isValid(value)) {
      return 'CPF inválido';
    } else {
      return '';
    }
  },

  email: (value: string) => {
    if (value === '') {
      // Email is missing
      return 'Campo é obrigatório';
    } else if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value,
      )
    ) {
      return 'Email inválido';
    } else {
      return '';
    }
  },

  password: (value: string) => {
    if (value === '') {
      // Password is missing or invalid?
      return 'Campo é obrigatório';
    } else if (value.length < 6) {
      return 'Senha muito curta. Mínimo de 6 caracteres';
    } else if (value.length > 50) {
      return 'Senha muito longa. Máx de 50 caracteres';
    } else {
      return '';
    }
  },

  passwordConfirmation: (value: string, otherValue: string) => {
    if (value === '') {
      // Confirmation password is mandatory
      return 'Campo é obrigatório';
    } else if (value !== otherValue) {
      // Confirmation password has to match password

      return 'Senha e confirmação não são iguais';
    } else {
      return '';
    }
  },

  acceptedTerms: (value: string) => {
    if (!value) {
      // Accepted terms and conditions
      return 'Favor aceitar os termos de uso';
    } else {
      return '';
    }
  },
};

const blend = {
  name: (value: string) => {
    if (value === '') {
      return 'Campo é obrigatório';
    } else if (value.length > 30) {
      return 'Nome muito longo. Max 30 letras';
    } else {
      return '';
    }
  },
  observation: (value: string) => {
    if (value.length > 140) {
      return 'Descrição muito longa. Max 140 letras';
    } else {
      return '';
    }
  },

  blendItems: (value: Item[]) => {
    if (_.isEmpty(value)) {
      return 'O blend precisa ter ao menos 1 item';
    } else {
      return '';
    }
  },
};

const validationHelpers = {
  address,
  creditCard,
  user,
  blend,
};

export default validationHelpers;
