// @flow
import React, { Component } from 'react';

import './PriceWithUnit.css';

type Props = {
  listPrice: string | number,
  price: string | number,
  unit: 'g' | 'un',
};

class PriceWithUnit extends Component {
  props: Props;
  render() {
    let price = this.props.price;
    let listPrice = this.props.listPrice || -1;
    const productOnPromotion = listPrice > price;

    if (this.props.unit === 'g') {
      price = this.props.price * 1000;
      listPrice = listPrice * 1000;
    }
    return (
      <div
        className={`price-with-unit-main ${
          productOnPromotion ? 'price-with-unit-promotion' : ''
        }`}
      >
        <h5>
          {parseFloat(price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
          /{this.props.unit === 'g' ? 'Kg' : 'un'}
        </h5>

        <h6>
          {parseFloat(listPrice).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
          /{this.props.unit === 'g' ? 'Kg' : 'un'}
        </h6>
      </div>
    );
  }
}

export default PriceWithUnit;
