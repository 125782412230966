// @flow
import React, { Component } from 'react';
import logo from '../images/LogoHorizontal.png';

import { Link } from 'react-router-dom'

import './SimpleLogo.css';

class SimpleLogo extends Component {
  render() {
    return <Link to="" className="simple-logo-main"><img className="simple-logo-image" alt="Big Delivery Simple Logo" src={logo} /></Link>;
  }
}

export default SimpleLogo;
