// @flow
import React from 'react';
// Components
import LoginBox from './LoginBox';
import RegisterBox from './RegisterBox';
import { ColorRow, Button } from '../../common';
// Styles
import './Login.css';

type Props = {
  alertText: string,
  isLoggingIn: boolean,
  isRegistering: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  registrationErrors: {},
  submitLogin: () => void,
  submitRegister: () => void,
  handleOnClickForgotMyPassword: () => void,
};

type State = {
  showLoginOrRegister: '' | 'login' | 'register',
};

class Login extends React.Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      showLoginOrRegister: '',
    };
  }

  render() {
    return (
      <div className="login-main">
        <ColorRow color="red" text={this.props.alertText} />

        {/*Desktop version*/}
        <div className="login-content">
          <div className="login-container">
            <RegisterBox
              onSubmitRegister={this.props.submitRegister}
              isLoading={this.props.isRegistering}
              errors={this.props.registrationErrors}
            />

            <div className="vertical-line" />

            <LoginBox
              onSubmitLogin={this.props.submitLogin}
              isLoading={this.props.isLoggingIn}
              errors={this.props.loginErrors}
            />
          </div>
        </div>

        {/*Mobile version*/}
        <div className="login-content-mobile">
          <div className="login-container">
            <div>
              {this.state.showLoginOrRegister === 'register'
                ? <div>
                    <RegisterBox
                      onSubmitRegister={this.props.submitRegister}
                      isLoading={this.props.isRegistering}
                      errors={this.props.registrationErrors}
                    />
                    <div className="login-button-container">
                      <Button
                        text="Já sou cadastrado"
                        icon=""
                        buttonType="button-secondary"
                        loading={false}
                        onClick={() =>
                          this.setState({ showLoginOrRegister: 'login' })}
                      />
                    </div>
                  </div>
                : null}

              {this.state.showLoginOrRegister === 'login'
                ? <div>
                    <LoginBox
                      onSubmitLogin={this.props.submitLogin}
                      isLoading={this.props.isLoggingIn}
                      errors={this.props.loginErrors}
                    />
                    <div className="login-button-container">
                      <Button
                        text="Quero me cadastrar!"
                        icon=""
                        buttonType="button-secondary"
                        loading={false}
                        onClick={() =>
                          this.setState({ showLoginOrRegister: 'register' })}
                      />
                    </div>
                  </div>
                : null}

              {this.state.showLoginOrRegister === ''
                ? <div>
                    <div className="login-button-container">
                      <Button
                        text="Quero me cadastrar!"
                        icon=""
                        buttonType="button-secondary"
                        loading={false}
                        onClick={() =>
                          this.setState({ showLoginOrRegister: 'register' })}
                      />
                    </div>

                    <div className="login-button-container">
                      <Button
                        text="Já sou cadastrado"
                        icon=""
                        buttonType="button-secondary"
                        loading={false}
                        onClick={() =>
                          this.setState({ showLoginOrRegister: 'login' })}
                      />
                    </div>
                  </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
