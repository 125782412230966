// @flow
import React from 'react';
// Components
import { Helmet } from 'react-helmet';
// Images
import Banner from '../../images/banner_trabalhe.jpg';
// Styles
import './WorkWithUsPage.css';

// Components
import { ColorRow } from '../../common';

const WorkWithUsPage = () => {
  return (
    <div className="work-with-us-main">
      <Helmet>
        <title>Trabalhe Conosco - Bigdelivery, Seu açougue virtual</title>
      </Helmet>

      <ColorRow color="grey" text="Trabalhe Conosco" />
      <img className="work-with-us-image" src={Banner} alt="" />

      <hr className="work-with-us-line" />
      <div className="work-with-us-text">
        <h3> Gostaria de trabalhar conosco? </h3>
        <p>
          Somos uma empresa em crescimento e estamos sempre em busca de talentos
          para se juntarem a nossa equipe. Envie seu currículo para o email{' '}
          <span>rh@bigdelivery.com.br</span> para participar de nosso processo
          de seleção.
        </p>
      </div>
      <hr className="work-with-us-line" />
    </div>
  );
};

export default WorkWithUsPage;
