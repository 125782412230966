// @flow
import React from 'react';
// Styles
import './ContactUs.css';
// Images
import PorkStamp from '../images/porkOnSkate.png';
// Components
import { ColorRow } from '../../common';
import { Helmet } from 'react-helmet';
// Other
import { phone } from '../../common';
import WhatsappButton from '../../common/components/WhatsappButton';

const ContactUs = () => {
  return (
    <div className="about-us-main">
      <Helmet>
        <title>Contato - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="Contato - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="description"
          content={`Para central de relacionamento e vendas ligue para ${phone.asStringFull}.`}
        />
        <meta
          name="og:description"
          content={`Para central de relacionamento e vendas ligue para ${phone.asStringFull}.`}
        />
        <meta property="og:image" content={PorkStamp} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="Porco no skate." />
      </Helmet>

      <ColorRow color="grey" text="Entre em Contato" />

      <hr className="about-us-line" />
      <div className="about-us-text">
        <img className="about-us-stamp" src={PorkStamp} alt="Porco no skate" />
        <p>
          Central de relacionamento e vendas{' '}
          <span className="about-us-telephone">{phone.asStringFull}</span>
          <span className="about-us-telephone-mobile">
            <a href={`tel:${phone.e164}`}>{phone.asStringFull}</a>
          </span>
        </p>
        <p>
          Para qualquer outra dúvida, reclamação ou sugestão{' '}
          <span>sac@bigdelivery.com.br</span>
        </p>

        <div className="contact-us-social-media">
          <div>Siga nossas páginas</div>
          <a
            href="https://www.facebook.com/bigdeliverybr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook-square" aria-hidden="true" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCPEm42gkJb23RUD74jiJBVA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-youtube" aria-hidden="true" />
          </a>
          <a
            href="https://www.instagram.com/bigdeliverybr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
          <a
            href="https://twitter.com/bigdbr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-twitter" aria-hidden="true" />
          </a>
        </div>
        <WhatsappButton />
      </div>
      <hr className="about-us-line" />
    </div>
  );
};

export default ContactUs;
