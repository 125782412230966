// @flow
import React, { Component } from 'react';

import './MainProductDetails.css';

// Types
import type { Product } from '../types';
export type Props = {
  product: Product,
};


class MainProductDetails extends Component {
  props: Props;
  render() {
    const product = this.props.product;
    // TODO: Make sure this works when we have products with descriptions again...
    return (
      <div className="main-product-details-main">
        {(product.description === "" || product.description === null) ?
          <div /> :
          <div>
            <h3>Descrição do Produto</h3>
            <p>{product.description}</p>
          </div>
        }
      </div>
    );
  }
}

export default MainProductDetails;