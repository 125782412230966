// @flow
import React from 'react';
// Components
import { ColorRow } from '../../common';
import FaqHit from '../components/FaqHit';
import { Helmet } from 'react-helmet';
// Data
import faq from '../data/faq';
// Styles
import './FaqPage.css';

const FaqPage = () => {
  return (
    <div className="faq-page-main">
      <Helmet>
        <title>Perguntas Frequentes - Bigdelivery, Seu açougue virtual</title>
      </Helmet>

      <ColorRow color="red" text="Perguntas Frequentes" />

      <div className="faq-page-hits">
        {faq.map((hit, ind) => (
          <FaqHit key={ind} question={hit.question} answer={hit.answer} />
        ))}
      </div>
    </div>
  );
};

export default FaqPage;
