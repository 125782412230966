// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../types';
import type { Address } from '../types';
// Apis
import User from '../api';
import { updateToken } from '../api/session';
import { isAuthenticated } from '../../api';
// Selectors
import { userIdSelector } from '../selectors';
// Logger
import { logException } from '../../logHelper';

// Register
export const registerUserRequest = createAction('REGISTER_USER_REQUEST');
export const registerUser = createAction('REGISTER_USER');

export function register(
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  recaptchaToken: string,
) {
  return async (dispatch: Dispatch) => {
    dispatch(registerUserRequest());
    try {
      const userResponse = await User.register(
        firstName,
        lastName,
        cpf,
        email,
        password,
        passwordConfirmation,
        recaptchaToken,
      );
      dispatch(registerUser(userResponse));
      // Since we succeeded to register the user lets log him in
      // TODO: Is this the best/correct way to do this?
      dispatch(login(email, password, false));
    } catch (err) {
      logException(err);
      dispatch(registerUser(err));
    }
  };
}

// Login
export const loginUserRequest = createAction('LOGIN_USER_REQUEST');
export const loginUser = createAction('LOGIN_USER');

export function login(email: string, password: string, rememberMe: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(loginUserRequest());
    try {
      const userResponse = await User.login(email, password, rememberMe);
      dispatch(loginUser(userResponse));
    } catch (err) {
      logException(err);
      dispatch(loginUser(err));
    }
  };
}

// Logout user
export const logoutUserRequest = createAction('LOGOUT_USER_REQUEST');
export const logoutUser = createAction('LOGOUT_USER');

export function logout() {
  return async (dispatch: Dispatch) => {
    dispatch(logoutUserRequest());
    try {
      await User.logout();
      dispatch(logoutUser());
      window.location.reload(false);
    } catch (err) {
      // If there's an error logging out, still remove tokens
      dispatch(logoutUser());
    }
  };
}

// Validate token
export const validateUserRequest = createAction('VALIDATE_USER_REQUEST');
export const validateUser = createAction('VALIDATE_USER');

export function validate() {
  return async (dispatch: Dispatch) => {
    // No need to attempt to verify if a token is valid if there is no token
    if (!(await isAuthenticated())) {
      return;
    }
    dispatch(validateUserRequest());
    try {
      const userResponse = await User.validateToken();
      dispatch(validateUser(userResponse));
    } catch (err) {
      dispatch(validateUser(err));
    }
  };
}

// Update Information
export const updateUserInformationRequest = createAction(
  'UPDATE_USER_INFORMATION_REQUEST',
);
export const updateUserInformation = createAction('UPDATE_USER_INFORMATION');

export function updateInformation(
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
) {
  return async (dispatch: Dispatch, getStore) => {
    dispatch(updateUserInformationRequest());
    try {
      const response = await User.updateInformation(
        firstName,
        lastName,
        cpf,
        email,
      );
      dispatch(updateUserInformation(response));
    } catch (err) {
      logException(err);
      dispatch(updateUserInformation(err));
    }
  };
}

// Update Password
export const updateUserPasswordRequest = createAction(
  'UPDATE_USER_PASSWORD_REQUEST',
);
export const updateUserPassword = createAction('UPDATE_USER_PASSWORD');

export function updatePassword(
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(updateUserPasswordRequest());
    try {
      const userId = userIdSelector(getState());
      const response = await User.updatePassword(
        userId,
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      );
      dispatch(updateUserPassword(response));
      // TODO: Should remember the customer preference for being remembered or not.
      updateToken(response.token, { rememberMe: false });
      window.location.reload();
    } catch (err) {
      logException(err);
      dispatch(updateUserPassword(err));
    }
  };
}

// Get addresses
export const getUserAddressesRequest = createAction(
  'GET_USER_ADRESSES_REQUEST',
);
export const getUserAddresses = createAction('GET_USER_ADRESSES');

export function getAddresses() {
  return async (dispatch: Dispatch) => {
    dispatch(getUserAddressesRequest());
    try {
      const addresses = await User.getAddresses();
      dispatch(getUserAddresses(addresses));
    } catch (err) {
      logException(err);
      dispatch(getUserAddresses(err));
    }
  };
}

// New address
export const newUserAddressRequest = createAction('NEW_USER_ADDRESS_REQUEST');
export const newUserAddress = createAction('NEW_USER_ADDRESS');

export function newAddress(address: Address) {
  return async (dispatch: Dispatch) => {
    dispatch(newUserAddressRequest());
    try {
      const response = await User.newAddress(address);
      dispatch(newUserAddress(response));
    } catch (err) {
      dispatch(newUserAddress(err));
    }
  };
}

// Update address
export const updateUserAddressRequest = createAction(
  'UPDATE_USER_ADDRESS_REQUEST',
);
export const updateUserAddress = createAction('UPDATE_USER_ADDRESS');

export function updateAddress(address: Address) {
  return async (dispatch: Dispatch) => {
    dispatch(updateUserAddressRequest());
    try {
      const response = await User.updateAddress(address);
      dispatch(updateUserAddress(response));
    } catch (err) {
      dispatch(updateUserAddress(err));
    }
  };
}

// Delete address
export const deleteUserAddressRequest = createAction(
  'DELETE_USER_ADDRESS_REQUEST',
);
export const deleteUserAddress = createAction('DELETE_USER_ADDRESS');

export function deleteAddress(addressId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserAddressRequest(addressId));
    try {
      await User.deleteAddress(addressId);
      dispatch(deleteUserAddress(addressId));
    } catch (err) {
      dispatch(deleteUserAddress(err));
    }
  };
}

// Get credit Cards
export const getUserCreditCardsRequest = createAction(
  'GET_USER_CREDIT_CARDS_REQUEST',
);
export const getUserCreditCards = createAction('GET_USER_CREDIT_CARDS');

export function getCreditCards() {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(getUserCreditCardsRequest());
    try {
      const userId = userIdSelector(getState());
      const addresses = await User.getCreditCards(userId);
      dispatch(getUserCreditCards(addresses));
    } catch (err) {
      logException(err);
      dispatch(getUserCreditCards(err));
    }
  };
}

// Delete CredidCard
export const deleteUserCreditCardRequest = createAction(
  'DELETE_USER_CREDIT_CARD_REQUEST',
);
export const deleteUserCreditCard = createAction('DELETE_CREDIT_CARD_ADDRESS');

export function deleteCreditCard(creditCardId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteUserCreditCardRequest(creditCardId));
    try {
      await User.deleteCreditCard(creditCardId);
      dispatch(deleteUserCreditCard(creditCardId));
    } catch (err) {
      dispatch(deleteUserCreditCard(err));
    }
  };
}

// PUT to reset the user password
export const putUserResetPasswordRequest = createAction(
  'PUT_USER_RESET_PASSWORD_REQUEST',
);
export const putUserResetPassword = createAction('PUT_USER_RESET_PASSWORD');

export function putResetPassword(
  password: string,
  passwordConfirmation: string,
  token: string,
  email: string,
) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(putUserResetPasswordRequest());
    try {
      const response = await User.putResetPassword(
        password,
        passwordConfirmation,
        token,
        email,
      );
      dispatch(putUserResetPassword(response));
    } catch (err) {
      logException(err);
      dispatch(putUserResetPassword(err));
    }
  };
}

// POST to request a password reset email
export const postUserForgotPasswordRequest = createAction(
  'POST_USER_FORGOT_PASSWORD_REQUEST',
);
export const postUserForgotPassword = createAction('POST_USER_FORGOT_PASSWORD');

export function postForgotPassword(email: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(postUserForgotPasswordRequest());
    try {
      const response = await User.postForgotPassword(email);
      dispatch(postUserForgotPassword(response));
    } catch (err) {
      logException(err);
      dispatch(postUserForgotPassword(err));
    }
  };
}
