// @flow
import React, { Component } from 'react';

import './ProductPrice.css';

type Props = {
  productListPrice: number,
  productPrice: number,
  productUnit: string,
};

class ProductPrice extends Component {
  props: Props;
  render() {
    let price = this.props.productPrice;
    let listPrice = this.props.productListPrice || -1;
    const productOnPromotion = listPrice > price;

    if (this.props.productUnit === 'g') {
      price = this.props.productPrice * 1000;
      listPrice = listPrice * 1000;
    }

    return (
      <div
        className={`product-price-main ${
          productOnPromotion ? 'product-price-promotion' : ''
        }`}
      >
        <h5>
          {parseFloat(price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
          /
          {this.props.productUnit === 'g' || this.props.productUnit === 'kg'
            ? 'Kg'
            : 'un'}
        </h5>

        <h6>
          {parseFloat(listPrice).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
          /
          {this.props.productUnit === 'g' || this.props.productUnit === 'kg'
            ? 'Kg'
            : 'un'}
        </h6>
      </div>
    );
  }
}

export default ProductPrice;
