// @flow
import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
import * as actions from '../actions';
// Types
import type { Reducer } from 'redux';
import type { ActionType } from 'redux-actions';

// ORDER
type currentReducerType = string;
const current: Reducer<currentReducerType, ActionType<*>> = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.result,
      throw: (state, action) => state,
    },
  },
  '',
);

const orders = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.orders || state,
      throw: (state, action) => state,
    },
    // Once a line item is added to the order we add it here.
    [actions.addItem]: {
      next: (state, action) => ({
        ...state,
        [action.payload.orderNumber]: {
          ...state[action.payload.orderNumber],
          lineItems: [
            ...state[action.payload.orderNumber].lineItems,
            action.payload.result,
          ],
        },
      }),
    },
    [actions.removeItem]: {
      next: (state, action) => ({
        ...state,
        [action.payload.orderNumber]: {
          ...state[action.payload.orderNumber],
          lineItems: _.without(
            state[action.payload.orderNumber].lineItems,
            action.payload.itemId,
          ),
        },
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// LINE ITEMS
const lineItems = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.lineItems || state,
      throw: (state, action) => state,
    },
    [combineActions(actions.addItem, actions.updateLineItem)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.lineItems,
      }),
    },
    [actions.changeItemQuantity]: {
      next: (state, action) => ({
        ...state,
        [action.payload.itemId]: {
          ...state[action.payload.itemId],
          quantity: action.payload.quantity,
        },
      }),
    },
    [actions.removeItem]: {
      next: (state, action) => _.omit(state, action.payload.itemId),
      throw: (state, action) => state,
    },
  },
  {},
);

// SHIPPING
const shipments = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.shipments || state,
      throw: (state, action) => state,
    },
  },
  {},
);

const shippingMethods = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.shippingMethods || state,
      throw: (state, action) => state,
    },
  },
  {},
);

const shippingRates = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.shippingRates || state,
      throw: (state, action) => state,
    },
  },
  {},
);

const paymentMethods = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.paymentMethods || state,
      throw: (state, action) => state,
    },
  },
  {},
);

const creditCards = handleActions(
  {
    [combineActions(
      actions.fetchOrder,
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPayment,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
      actions.copyFromOrderToOrder,
    )]: {
      next: (state, action) => action.payload.entities.creditCards || state,
      throw: (state, action) => state,
    },
  },
  {},
);

const promotions = handleActions(
  {
    [combineActions(actions.fetchPromotionsByUser)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.promotions,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// ERRORS
const initialErrors = {
  general: [],
  address: [],
  delivery: [],
  promotion: [],
  payment: [],
};
const errors = handleActions(
  {
    [combineActions(actions.fetchOrder, actions.transitionToAddress)]: {
      next: (state, action) => state,
      throw: (state, action) => ({
        ...state,
        general: [...state.general, action.payload.message],
      }),
    },
    [combineActions(actions.transitionToDelivery)]: {
      next: (state, action) => state,
      throw: (state, action) => ({
        ...state,
        address: [...state.address, action.payload.message],
      }),
    },
    [combineActions(actions.transitionFromPromotionToPayment)]: {
      next: (state, action) => state,
      throw: (state, action) => ({
        ...state,
        promotion: [...state.promotion, action.payload.message],
      }),
    },
    [combineActions(actions.transitionToPayment)]: {
      next: (state, action) => state,
      throw: (state, action) => ({
        ...state,
        delivery: [...state.delivery, action.payload.message],
      }),
    },
    [combineActions(
      actions.transitionToConfirmation,
      actions.transitionToComplete,
    )]: {
      next: (state, action) => state,
      throw: (state, action) => ({
        ...state,
        payment: [...state.payment, action.payload.message],
      }),
    },
    [actions.dismissErrors]: (state, action) => initialErrors,
  },
  initialErrors,
);

// Loading
const changingItemsLoading = handleActions(
  {
    [combineActions(actions.changeItemQuantity)]: {
      next: (state, action) => {
        if (action.meta && !action.meta.isOptimistic) {
          // Remove if element exists in the array
          return _.filter(state, o => o !== action.payload.itemId);
        } else {
          return state;
        }
      },
      throw: (state, action) => [],
    },
    [actions.removeItem]: {
      next: (state, action) =>
        _.filter(state, o => o !== action.payload.itemId),
    },
    [combineActions(
      actions.changeItemQuantityRequest,
      actions.removeItemRequest,
    )]: (state, action) => {
      // Only add item if it is not in the array
      if (state.indexOf(action.payload.itemId) === -1) {
        return [...state, action.payload.itemId];
      }
      return state;
    },
  },
  [],
);

// Editing Line Item Obs
const editingItemsLoading = handleActions(
  {
    [combineActions(actions.updateLineItem)]: (state, action) => {
      // Remove if element exists in the array
      return _.filter(state, o => o !== action.payload.productId);
    },
    [combineActions(actions.updateLineItemRequest)]: (state, action) => {
      // Only add item if it is not in the array
      if (state.indexOf(action.payload.productId) === -1) {
        return [...state, action.payload.productId];
      }
      return state;
    },
  },
  [],
);

// Notice that we have a separate reducer for the changing and the adding. The reason being that
// while adding we don't yet have a lineItemId. So we instead, use a productId.
const addingItemsLoading = handleActions(
  {
    [actions.addItem]: {
      next: (state, action) => {
        // Remove if element exists in the array
        return _.filter(state, o => o !== action.payload.productId);
      },
      throw: (state, action) => [],
    },
    [actions.addItemRequest]: (state, action) => {
      // Only add item if it is not in the array
      if (state.indexOf(action.payload.productId) === -1) {
        return [...state, action.payload.productId];
      }
      return state;
    },
  },
  [],
);

// General loading states
const initialLoadingState = {
  gettingOrder: false,
  gettingPromotions: false,
  transitioning: false,
  copyingOrder: false,
  initiatingCheckout: false,
  removingItem: false,
};
const loading = handleActions(
  {
    [actions.fetchOrderRequest]: (state, action) => ({
      ...state,
      gettingOrder: true,
    }),
    [actions.fetchOrder]: (state, action) => ({
      ...state,
      gettingOrder: false,
    }),
    [combineActions(actions.fetchPromotionsByUserRequest)]: (
      state,
      action,
    ) => ({
      ...state,
      gettingOrder: true,
    }),
    [combineActions(actions.fetchPromotionsByUser)]: (state, action) => ({
      ...state,
      gettingOrder: false,
    }),
    [combineActions(
      actions.transitionToAddressRequest,
      actions.transitionToDeliveryRequest,
      actions.transitionFromPromotionToPaymentRequest,
      actions.transitionToPaymentRequest,
      actions.transitionToConfirmationRequest,
      actions.transitionToCompleteRequest,
    )]: (state, action) => ({
      ...state,
      transitioning: true,
    }),
    [combineActions(
      actions.transitionToAddress,
      actions.transitionToDelivery,
      actions.transitionFromPromotionToPaymentRequest,
      actions.transitionToPayment,
      actions.transitionToConfirmation,
      actions.transitionToComplete,
    )]: (state, action) => ({
      ...state,
      transitioning: false,
    }),
    [actions.transitionToAddressRequest]: (state, action) => ({
      ...state,
      initiatingCheckout: true,
    }),
    [actions.transitionToAddress]: (state, action) => ({
      ...state,
      initiatingCheckout: false,
    }),
    // COPY ORDER
    [combineActions(actions.copyFromOrderToOrderRequest)]: (state, action) => ({
      ...state,
      copyingOrder: true,
    }),
    [combineActions(actions.copyFromOrderToOrder)]: (state, action) => ({
      ...state,
      copyingOrder: false,
    }),
    // REMOVE ITEM
    [actions.removeItemRequest]: (state, action) => ({
      ...state,
      removingItem: true,
    }),
    [actions.removeItem]: (state, action) => ({
      ...state,
      removingItem: false,
    }),
  },
  initialLoadingState,
);

const reducers = combineReducers({
  current,
  orders,
  lineItems,
  shipments,
  shippingMethods,
  shippingRates,
  paymentMethods,
  creditCards,
  promotions,
  errors,
  // Loading related
  addingItemsLoading,
  changingItemsLoading,
  editingItemsLoading,
  loading,
});

export default reducers;
