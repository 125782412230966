// @flow
import React, { Component } from 'react';
import './CheckoutContinueButton.css';

type Props = {
  text: string,
  disabled: boolean,
  loading: boolean,
  onClick: any => void,
};

class CheckoutContinueButton extends Component {
  props: Props;

  render() {
    let disabled = '';
    if (this.props.disabled) {
      disabled = 'checkout-continue-disabled';
    }

    let text = this.props.text;

    return (
      <div
        className={`checkout-continue-button-main ${disabled}`}
        onClick={
          this.props.loading
            ? () => console.error('Button clicked while in loading state.')
            : this.props.onClick
        }
      >
        <div className="block"></div>
        {this.props.loading
          ? <i className="fa fa-spinner fa-pulse fa-1x" aria-hidden="true" />
          : null}
        {text}
      </div>
    );
  }
}

export default CheckoutContinueButton;
