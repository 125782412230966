// @flow
import React, { Component } from 'react';
import './MyAccountPassword.css';
// Components
import {
  validationHelpers,
  InputFieldErrorMessage,
  FormButton,
  PageLoader,
} from '../../../common';

type Props = {
  updatingLoading: boolean,
  error: boolean,
  onSubmit: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) => void,
};

type State = {
  passwordStrength: string,
  badOldPassword: string,
  badPassword: string,
  passwordConfirmationError: string,
};

const PasswordStrengthTextAndColor = ({ passwordStrength }) => {
  if (passwordStrength === 'strong') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-strong"> Forte </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'average') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-medium"> Média </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'weak') {
    return (
      <span className="account-password-strength-text">
        Força da senha:{' '}
        <span className="account-password-strength-poor"> Fraca </span>{' '}
      </span>
    );
  } else {
    return <span> </span>;
  }
};

class MyAccountPassword extends Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);

    this.calculatePasswordStrength.bind(this);

    this.state = {
      passwordStrength: '',
      badOldPassword: '',
      badPassword: '',
      passwordConfirmationError: '',
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput(
    event: any,
    oldPassword: string,
    password: string,
    confirmationPassword: string,
  ) {
    event.preventDefault();

    let noValidationErrors: boolean = true;

    const badOldPassword = validationHelpers.user.password(oldPassword);
    this.setState({ badOldPassword });
    if (badOldPassword) {
      noValidationErrors = false;
    }

    const badPassword = validationHelpers.user.password(password);
    this.setState({ badPassword });
    if (badPassword) {
      noValidationErrors = false;
    }

    const passwordConfirmationError = validationHelpers.user.passwordConfirmation(
      password,
      confirmationPassword,
    );
    this.setState({ passwordConfirmationError });
    if (passwordConfirmationError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      this.props.onSubmit(oldPassword, password, confirmationPassword);
    } else {
      console.error('Validation error on registration form.');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If we have a server error show them instead of the local errors
    if (nextProps.error) {
      this.setState({ badOldPassword: 'Senha incorreta' });
    }
  }

  // Sets this.state.passwordStrength based on the characteristics of the password
  calculatePasswordStrength(password: string) {
    if (password === '') this.setState({ passwordStrength: '' });
    else if (password.length < 8) this.setState({ passwordStrength: 'weak' });
    else if (/^[a-zA-Z0-9- ]*$/.test(password) === false)
      this.setState({ passwordStrength: 'strong' });
    else if (password.length > 15)
      this.setState({ passwordStrength: 'strong' });
    else this.setState({ passwordStrength: 'average' });
  }

  render() {
    if (this.props.loading) {
      return <PageLoader />;
    }
    return (
      <div className="my-account-password-main">
        <h3>Alterar senha</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.validateUserInput(
              e,
              this.refs.oldPassword.value,
              this.refs.newPassword.value,
              this.refs.newPasswordConfirmation.value,
            );
          }}
        >
          <div className="my-account-password-form">
            <InputFieldErrorMessage message={this.state.badOldPassword} />
            <input
              ref="oldPassword"
              type="password"
              className="my-account-password-large-input"
              placeholder="Senha atual"
            />

            {this.state.passwordStrength !== '' ? (
              <PasswordStrengthTextAndColor
                passwordStrength={this.state.passwordStrength}
              />
            ) : null}

            <InputFieldErrorMessage message={this.state.badPassword} />
            <input
              ref="newPassword"
              type="password"
              className="my-account-password-large-input"
              placeholder="Nova senha"
              onChange={v => this.calculatePasswordStrength(v.target.value)}
            />

            <InputFieldErrorMessage
              message={this.state.passwordConfirmationError}
            />
            <input
              ref="newPasswordConfirmation"
              type="password"
              className="my-account-password-large-input"
              placeholder="Confirme a nova senha"
            />
          </div>
          <div className="my-account-password-submit-content">
            <div className="my-account-password-submit-button">
              <FormButton
                text={'SALVAR'}
                icon=""
                buttonType={'form-button-tertiary'}
                loading={this.props.updatingLoading} // TODO Put flag here
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default MyAccountPassword;
