// @flow
import React from 'react';

import type { CreditCard } from '../../types';

import './CreditCardOption.css';

import CardImageDefault from '../../images/credit-card.png';
import CardImageAmericanExpress from '../../images/credit-card-american-express.png';
import CardImageDiscover from '../../images/credit-card-discover.png';
import CardImageMaster from '../../images/credit-card-master.png';
import CardImageVisa from '../../images/credit-card-visa.png';

export type Props = {
  creditCard: CreditCard,
  active: boolean,
  cardHasExpired: boolean,
  deleteCreditCard: () => void,
};

class CreditCardOption extends React.Component {
  props: Props;

  handleDelete(e, creditCardId: number) {
    const confirm = window.confirm(`O cartão será deletado. Deseja continuar?`);

    if (confirm) {
      e.stopPropagation();
      this.props.deleteCreditCard(this.props.creditCard.id);
    }
  }

  render() {
    const name = this.props.creditCard.name.toUpperCase();
    let cardImage = CardImageDefault;
    switch (this.props.creditCard.ccType) {
      case 'visa':
        cardImage = CardImageVisa;
        break;
      case 'master':
        cardImage = CardImageMaster;
        break;
      case 'american_express':
        cardImage = CardImageAmericanExpress;
        break;
      case 'discover':
        cardImage = CardImageDiscover;
        break;
      default:
        cardImage = CardImageDefault;
    }
    return (
      <div className="credit-card-option-container">
        <div
          className={`credit-card-option-main ${
            this.props.cardHasExpired
              ? 'credit-card-expired'
              : this.props.active
              ? 'credit-card-selected'
              : ''
          }`}
        >
          <img className="credit-card-option-image" alt="" src={cardImage} />

          <div className="credit-card-option-information">
            <p>{name}</p>
            <p>XXXX XXXX XXXX {this.props.creditCard.lastDigits}</p>
            <p>
              val: {this.props.creditCard.month}/{this.props.creditCard.year}
              {(this.props.cardHasExpired && <span>EXPIRADO</span>) || null}
            </p>
          </div>

          {!this.props.deletingLoading ? (
            <i
              className="fa fa-trash-o credit-card-option-trash"
              aria-hidden="true"
              onClick={e => {
                e.stopPropagation();
                this.handleDelete(e, this.props.creditCard.id);
              }}
            />
          ) : (
            <i
              className="credit-card-spinner fa fa-spinner fa-pulse fa-2x"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    );
  }
}

export default CreditCardOption;
