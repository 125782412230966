// @flow
import React, { Component } from 'react';
// Types
import type { LineItem } from '../types';
//Helpers
import { portioningString } from '../../common/commonHelpers';
// Styles
import './CartPreview.css';
//Components
import { priceConverter } from '../../common';

type Props = {
  items: LineItem[],
  orderNumber: string,
  totalPrice: string,
  itemTotal: string,
  adjustmentTotal: number,
  shipTotal: string,
  canSubmit: boolean,
  loading: boolean, // Transitioning
  blendsByOrderNumber: orderNumber => void,
  onSubmit: () => void,
};

class CartPreview extends Component {
  props: Props;
  render() {
    const blends = this.props.blendsByOrderNumber(this.props.orderNumber);
    return (
      <div className="cart-preview">
        <div className="cart-preview-main">
          <h4> MEU CARRINHO </h4>

          <div className="cart-preview-line-items">
            {this.props.items.map((item, i) => (
              <div className="cart-preview-line" key={i}>
                <p className="cart-preview-line-name">{`${
                  item.variant.name
                } ${portioningString(item)}`}</p>
                <p className="cart-preview-line-quantity">
                  {item.variant.unitForQuantity === 'g'
                    ? (item.quantity / 1000).toLocaleString('pt-br', {
                        currency: 'BRL',
                      }) + ' Kg'
                    : item.quantity}
                </p>
                <p className="cart-preview-line-price">{item.displayAmount}</p>
              </div>
            ))}
            {blends.map((blend, i) => (
              <div className="cart-preview-line" key={i}>
                <p className="cart-preview-line-name">{blend.name}</p>
                <p className="cart-preview-line-quantity">
                  {(blend.quantity / 1000).toLocaleString('pt-br', {
                    currency: 'BRL',
                  }) + ' Kg'}
                </p>
                <p className="cart-preview-line-price">
                  {priceConverter(parseFloat(blend.price) * blend.quantity)}
                </p>
              </div>
            ))}
          </div>
          <div className="cart-preview-totals">
            <p>
              Preço estimado*:{' '}
              {parseFloat(this.props.itemTotal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            <p>
              Taxa de Entrega:{' '}
              {parseFloat(this.props.shipTotal).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
            {this.props.adjustmentTotal !== '0.0' ? (
              <p>
                Desconto:{' '}
                {parseFloat(
                  Math.abs(this.props.adjustmentTotal),
                ).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            ) : null}
            <p>
              Total:{' '}
              {parseFloat(this.props.totalPrice).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </div>
          {/*<div className="cart-preview-button">
            <button
              onClick={this.props.onSubmit}
              disabled={!this.props.canSubmit || this.props.loading}
              className={`cart-preview-btn ${this.props.canSubmit ? '' : 'cart-preview-btn-disabled'}`}
            >
              <div className="cart-preview-button-sub-container">
                {!this.props.loading
                  ? 'FINALIZAR COMPRA'
                  : <i
                      className="fa fa-spinner fa-pulse fa-1x"
                      aria-hidden="true"
                    />}
              </div>
            </button>
          </div>*/}

          <p className="cart-preview-price-text">
            * Faremos o possível para que o peso pedido seja igual ao entregue.
            Porém, variações na carne e cortes podem ocasionar em pesos
            diferentes do selecionado. Caso isso aconteça, você pagará o preço
            de acordo com peso e não pelo valor estimado. Ex: Se o pedido foi de
            1,5 Kg mas pesamos 1,35 Kg, cobraremos apenas pelos 1,35 Kg. O mesmo
            ocorre caso o peso exceda o pedido.
          </p>
        </div>
      </div>
    );
  }
}

export default CartPreview;
