// @flow
/**
 * USER MODULE EXPORTS
 * This is the "interface" file for the user module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import default exports from files inside
 * the module
 */
import reducer from './reducer';
import CartIndicator from './containers/CartIndicator';
import AddToCartButton from './containers/AddToCartButton';
import CartPage from './containers/CartPage';
import CheckoutPage from './containers/CheckoutPage';
import ConfirmationPage from './containers/ConfirmationPage';
import currentOrderNumberConnector from './connectors/currentOrderNumberConnector';
import addItemToCartConnector from './connectors/addItemToCartConnector';
import CreditCardOption from './components/payment/CreditCardOption';

/**
 * Reducer
 * =======
 * The default export is the reducer to be integrated in the Redux Store.
 * Its state is defined too, and exported as the type UserStoreState.
 */
export default reducer;
export type { StoreState as CurrentOrderStoreState } from './types/StoreState';

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export { reducer, CartIndicator, AddToCartButton, CreditCardOption };

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */
export { CartPage, CheckoutPage, ConfirmationPage };

/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */
export { currentOrderNumberConnector, addItemToCartConnector };
