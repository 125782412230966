// @flow
import { combineReducers } from 'redux';
import order from '../current-order';
import products from '../products';
import { reducer as search } from '../search';
import user from '../user';
import pastOrders from '../past-orders';
import blends from '../blend';
import recipes from '../recipes';
import departments from '../departments';
import barbecue from '../barbecue'

const rootReducer = combineReducers({
  products,
  order,
  user,
  search,
  pastOrders,
  recipes,
  departments,
  blends,
  barbecue,
});

export default rootReducer;
