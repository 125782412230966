// @flow
import React, { Component } from 'react';
// Components
import Logo from '../components/Logo';
import TopRow from '../components/TopRow';
import BottomRow from '../components/BottomRow';
import HamburgerMenu from '../components/HamburgerMenu';
// Styles
import './Header.css';

type Props = {};

type State = {
  showHamburgerMenu: boolean,
};

class Header extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      showHamburgerMenu: false,
    };
  }

  // This is needed so that the hamburguer menu closes after actions on it
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ showHamburgerMenu: false });
  }

  render() {
    return (
      <div>
        <div className="header-main">
          <Logo />
          <i
            className="fa fa-bars header-menu"
            aria-hidden="true"
            onClick={() =>
              this.setState({
                showHamburgerMenu: !this.state.showHamburgerMenu,
              })}
          />
          <div className="header-rows">
            <TopRow />
            <BottomRow />
          </div>
        </div>
        {this.state.showHamburgerMenu
          ? <div className="hamburger-menu">
            <div
              className="hamburger-menu-outer"
              onClick={() =>
                this.setState({
                  showHamburgerMenu: false,
                })}
            />
            <HamburgerMenu />
          </div>
          : null}

      </div>
    );
  }
}

export default Header;
