/** @flow
 * CHECKOUT ERRORS
 * Component to display the current errors returned by the server during
 * the checkout process
 */
import React, { Component } from 'react';
import _ from 'lodash';
import { logException } from '../../logHelper';
// Styles
import './CheckoutErrors.css';

type Props = {
  errors: string[],
  dismiss: () => void,
};

class CheckoutErrors extends Component {
  props: Props;
  parseError: (error: string) => string;

  // Returns a stringified version of the error without wrapping objects
  parseError(error: string) {
    try {
      const parsedError = JSON.parse(error);
      let errorString = '';

      if (parsedError.error) {
        errorString += parsedError.error;
      }

      if (parsedError.errors) {
        const values = _.values(parsedError.errors);

        for (let i = 0; i < values.length; i++) {
          errorString += ` ${values[i]}`;
        }
      }

      return errorString;
    } catch (err) {
      logException(err);

      return error;
    }
  }

  render() {
    if (this.props.errors.length === 0) {
      return null;
    }

    return (
      <div className="checkout-error-main">
        <div className="checkout-error alert alert-danger" role="alert">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.dismiss}
          >
            <span aria-hidden="true">×</span>
          </button>
          <ul>
            {this.props.errors.map((error, ind) => (
              <li key={ind}>{this.parseError(error)}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CheckoutErrors;
