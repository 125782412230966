// @flow
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// Components
import Slider from 'react-slick';
import ProductSquare from './ProductSquare';
import { scroller } from 'react-scroll';
import { PageLoader } from '../../common';
import { Link } from 'react-router-dom';
// Types
import type { Product } from '../types';
// Styling
import './PaginatedScroller.css';
import 'slick-carousel/slick/slick.css';

type Props = {
  products: Product[],
  title: string,
  slug: string,
  isLoading: boolean,
  isLoadingPage: boolean,
  hideBottomLine: boolean,
  scrollable: boolean,
  requestNewPage: () => {},
};

// Slick Slider Arrows
const SampleNextArrow = ({ className, style, onClick, isLoadingPage }) => (
  <div
    className={className}
    style={style}
    onClick={(!isLoadingPage && onClick) || null}
  >
    <div className="paginated-scroller-arrow-container">
      {(isLoadingPage && (
        <i
          className="fa fa-spinner fa-pulse fa-2x paginated-scroller-icon"
          aria-hidden="true"
        ></i>
      )) || (
        <i
          className="fa fa-caret-right fa-4x paginated-scroller-icon"
          aria-hidden="true"
        />
      )}
    </div>
  </div>
);

const SamplePrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <div className="paginated-scroller-arrow-container">
      <i
        className="fa fa-caret-left fa-4x paginated-scroller-icon"
        aria-hidden="true"
      />
    </div>
  </div>
);

// This is the PaginatedScroller
const PaginatedScroller = (props: Props) => {
  useEffect(() => {
    if (!props.isLoading && props.scrollable && props.products.length > 0) {
      scroller.scrollTo(props.slug, {
        duration: 1500,
        smooth: 'easeInOutQuart',
      });
    }
  }, [props]);

  useEffect(() => {
    setShowMore(true);
    setQuantityOfItems(props.products.length);
  }, [props.products.length]);

  const [showMore, setShowMore] = useState(true);
  const [quantityOfItems, setQuantityOfItems] = useState(props.products.length);
  const [firstIndexToShow, setFirstIndexToShow] = useState(0);

  useEffect(() => {
    if (!props.isLoading && props.scrollable && props.products.length > 0) {
      scroller.scrollTo(props.slug, {
        duration: 1500,
        smooth: 'easeInOutQuart',
      });
    }
  }, [props]);
  // Note that Product PaginatedScroller must have an even number of items if scroll by 2 will behave properly
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    accessibility: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    swipe: true,
    vertical: false,
    variableWidth: true,
    nextArrow: <SampleNextArrow isLoadingPage={props.isLoadingPage} />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: newIndex =>
      props.requestNewPage ? props.requestNewPage(newIndex) : null,
  };

  if (props.isLoading) {
    return <PageLoader />;
  }

  if (props.products.length > 0) {
    return (
      <div className="paginated-scroller-main">
        <div className="paginated-scroller-header" id={props.slug}>
          <hr className="paginated-scroller-small-line" />
          <h3>
            <Link to={props.slug || ''} className="paginated-scroller-link">
              {props.title}
            </Link>
          </h3>
          <hr className="paginated-scroller-medium-line" />
        </div>
        <div className={'paginated-scroller'}>
          <Slider {...settings}>
            {props.products.map((product, i) => (
              <div key={i}>
                <ProductSquare
                  productImage={product.images[0]}
                  productName={
                    product.name.length < 40
                      ? product.name
                      : product.name.substring(0, 38) + '...'
                  }
                  productManufacturer={product.manufacturer}
                  productListPrice={product.listPrice}
                  productPrice={product.price}
                  productUnit={product.unitForQuantity}
                  productSlug={product.slug}
                  productId={product.id}
                  parentProductId={product.productId}
                  productTotalOnHand={product.totalOnHand}
                  defaultQuantity={product.defaultQuantity}
                  incrementQuantity={product.incrementQuantity}
                  inStock={product.inStock}
                  analyticsViewSource={props.title} // USE ANALYTICS
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="paginated-scroller-mobile">
          <div className="scroller-mobile-products">
            {props.products.map((product, i) => {
              if (i < firstIndexToShow + 4) {
                return (
                  <div key={i}>
                    <ProductSquare
                      productImage={product.images[0]}
                      productName={
                        product.name.length < 40
                          ? product.name
                          : product.name.substring(0, 40) + '...'
                      }
                      productManufacturer={product.manufacturer}
                      productListPrice={product.listPrice}
                      productPrice={product.price}
                      productUnit={product.unitForQuantity}
                      productSlug={product.slug}
                      productId={product.id}
                      parentProductId={product.productId}
                      productTotalOnHand={product.totalOnHand}
                      defaultQuantity={product.defaultQuantity}
                      incrementQuantity={product.incrementQuantity}
                      inStock={product.inStock}
                      analyticsViewSource={props.title} // USE ANALYTICS
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
          {showMore && props.products.length > 4 ? (
            <div className="scroller-mobile-see-more">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setFirstIndexToShow(firstIndexToShow + 4);
                  if (props.requestNewPage) {
                    props.requestNewPage(firstIndexToShow);
                  }
                }}
              >
                ver mais
              </a>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default PaginatedScroller;
