// @flow
import React, { Component } from 'react';
import './PaneTitle.css';

type Props = {
  title: string,
  number: number,
  active: boolean,
  children?: any,
};

class PaneTitle extends Component {
  props: Props;
  render() {
    return (
      <div className="pane-title-main">
        <div className="pane-title">
          {this.props.number}. {this.props.title}
        </div>
        {/*<div
          className={this.props.active ? 'pane-arrow-up' : 'pane-arrow-down'}
        />*/}
        <div className="pane-title-details">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PaneTitle;
