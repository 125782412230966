// @flow
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import './SearchResultHorizontal.css';
// Components
import SearchResultImage from './SearchResultImage';
import SearchResultHorizontalBottomRow from './SearchResultHorizontalBottomRow';
// Analytics
import * as Analytics from '../../common/analytics';
// Helpers
import { camelizeKeys } from 'humps';
// Types
import type { Hit } from '../types';

type Props = {
  hit: Hit,
};

const SearchResultHorizontal = ({ hit }) => {
  // USE ANALYTICS
  const [visibility, setVisibility] = useState(false);

  const camelizedHit: Hit = camelizeKeys(hit);

  useEffect(() => {
    if (visibility) {
      Analytics.logProductImpression(
        camelizedHit,
        window.location.pathname,
        'search result',
      );
    }
  }, [visibility]);
  // USE ANALYTICS

  return (
    <VisibilitySensor
      onChange={isVisible => {
        setVisibility(isVisible);
      }}
    >
      <div className="search-result-hit-horizontal-main">
        <SearchResultImage hit={camelizedHit} />

        <div className="search-result-hit-container">
          <Link to={`/produtos/${hit.slug}/`}>
            <h2 className="search-result-hit-horizontal-title">
              {camelizedHit.name}
            </h2>
          </Link>

          <SearchResultHorizontalBottomRow
            productId={camelizedHit.id}
            manufacturer={camelizedHit.manufacturer}
            listPrice={camelizedHit.listPrice}
            price={camelizedHit.price}
            cookingMethods={camelizedHit.cookingRecommendations}
            unit={camelizedHit.unitForQuantity}
            defaultQuantity={camelizedHit.defaultQuantity}
            incrementQuantity={camelizedHit.incrementQuantity}
            inStock={camelizedHit.inStock}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default SearchResultHorizontal;
