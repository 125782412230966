// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Components
import LoggedInOptions from '../components/LoggedInOptions';
import LoggedOutOptions from '../components/LoggedOutOptions';
// Analytics
import * as Analytics from '../../common/analytics';
// Types
import type { Connector } from 'react-redux';
// Selectors
import {
  userIdSelector,
  firstNameSelector,
  loggedInSelector,
  analyticsInformationSelector,
} from '../selectors';

type Props = {
  loggedIn: boolean,
  firstName: string,
};

const CurrentUserIndicator = ({
  userId,
  loggedIn,
  firstName,
  informations,
}: Props) => {
  // USE ANALYTICS
  useEffect(() => {
    Analytics.init(userId);

    if (loggedIn) {
      Analytics.setUserProperties(informations);
    }
  }, [userId, loggedIn, informations]);
  // USE ANALYTICS

  if (loggedIn) {
    return <LoggedInOptions name={firstName} />;
  }

  return <LoggedOutOptions />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: userIdSelector(state),
    loggedIn: loggedInSelector(state),
    firstName: firstNameSelector(state),
    informations: analyticsInformationSelector(state), // USE ANALYTICS
  };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);
export default connector(CurrentUserIndicator);
