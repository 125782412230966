// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  itemSelectorByProductId,
  changingItemLoadingSelector,
} from '../selectors';
// Types
import type { Connector } from 'react-redux';
import type { LineItem } from '../types';

import './BuyPrice.css';

const scaleAndFormatPrice = (price: number, unitForQuantity: 'g' | 'un') => {
  return (
    parseFloat(
      unitForQuantity === 'g' ? price * 1000 : price,
    ).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }) +
    ' /' +
    (unitForQuantity === 'g' ? 'Kg' : 'un')
  );
};

const SinglePrice = ({ price, unit, listPrice, showListPrice }) => (
  <div className={`buy-price-single ${showListPrice}`}>
    <h6>{scaleAndFormatPrice(listPrice, unit)}</h6>
    <h3>{scaleAndFormatPrice(price, unit)}</h3>
  </div>
);

const DoublePrice = ({ unit, price, quantity }) => (
  <div className="buyprice-estimated-content">
    <h3 className="buyprice-estimated-value">
      {parseFloat(price * quantity).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}
    </h3>

    <h3 className="buyprice-value">{scaleAndFormatPrice(price, unit)}</h3>
  </div>
);

export type Props = {
  productId: number,
  item: LineItem,
  listPrice: number,
  price: number,
  showPromo: boolean,
  unit: 'g' | 'un',
  loading: boolean,
};

class BuyPrice extends Component {
  props: Props;
  render() {
    let showListPrice = '';
    if (this.props.showPromo) {
      showListPrice = 'buyprice-show-list-price';
    }

    return (
      <div className="buyprice-main">
        {this.props.item ? (
          <DoublePrice
            unit={this.props.unit}
            price={parseFloat(this.props.item.price)}
            quantity={this.props.item.quantity}
          />
        ) : (
          <SinglePrice
            price={this.props.price}
            listPrice={this.props.listPrice}
            showListPrice={showListPrice}
            unit={this.props.unit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: Props) => {
  return {
    item: itemSelectorByProductId(state, ownProps.productId),
    loading: changingItemLoadingSelector(state, ownProps.productId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => {
  return {};
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(BuyPrice);
