// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import { CPF } from 'cpf_cnpj';
// Components
import {
  FormButton,
  PageLoader,
  InputFieldErrorMessage,
  validationHelpers,
} from '../../../common';
// Styles
import './MyAccountInformation.css';

type State = {
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,

  firstNameError: string,
  lastNameError: string,
  cpfError: string,
  emailError: string,
};

type Props = {
  information: {
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  },
  loading: boolean,
  editingLoading: boolean,
  onSubmitUpdate: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
  ) => void,
};

class MyAccountInformation extends Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: props.information ? props.information.firstName : '',
      lastName: props.information ? props.information.lastName : '',
      cpf: props.information ? props.information.cpf : '',
      email: props.information ? props.information.email : '',

      firstNameError: '',
      lastNameError: '',
      cpfError: '',
      emailError: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    // If the user information has changed we should update our state
    if (!_.isEqual(nextProps.information, this.props.information)) {
      this.setState({
        firstName: nextProps.information ? nextProps.information.firstName : '',
        lastName: nextProps.information ? nextProps.information.lastName : '',
        cpf: nextProps.information ? nextProps.information.cpf : '',
        email: nextProps.information ? nextProps.information.email : '',
      });
    }
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput() {
    let noValidationErrors: boolean = true;

    const firstNameError = validationHelpers.user.firstName(
      this.state.firstName,
    );
    this.setState({ firstNameError });
    if (firstNameError) {
      noValidationErrors = false;
    }

    const lastNameError = validationHelpers.user.lastName(this.state.lastName);
    this.setState({ lastNameError });
    if (lastNameError) {
      noValidationErrors = false;
    }

    const cpfError = validationHelpers.user.cpf(this.state.cpf);
    this.setState({ cpfError });
    if (cpfError) {
      noValidationErrors = false;
    }

    const emailError = validationHelpers.user.email(this.state.email);
    this.setState({ emailError });
    if (emailError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      this.props.onSubmitUpdate(
        this.state.firstName,
        this.state.lastName,
        this.state.cpf,
        this.state.email,
      );
    } else {
      console.error('Validation error on user editing form.');
    }
  }

  render() {
    if (this.props.loading) {
      return <PageLoader />;
    }

    return (
      <div className="my-account-information">
        <h3>Meus Dados</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.validateUserInput();
          }}
        >
          <div className="my-account-information-content">
            <div className="my-account-information-medium-content">
              <InputFieldErrorMessage message={this.state.firstNameError} />
              <input
                type="text"
                className="my-account-information-medium-input"
                placeholder="Nome"
                value={this.state.firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
              />
            </div>

            <div className="my-account-information-medium-content">
              <InputFieldErrorMessage message={this.state.lastNameError} />
              <input
                type="text"
                className="my-account-information-medium-input"
                placeholder="Sobrenome"
                value={this.state.lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
              />
            </div>
          </div>

          <div className="my-account-information-content">
            <div className="my-account-information-medium-content">
              <InputFieldErrorMessage message={this.state.cpfError} />
              <input
                type="text"
                className="my-account-information-medium-input"
                placeholder="CPF (apenas números)"
                value={`***.***.${CPF.format(this.state.cpf).substring(8, 14)}`}
                onChange={e => this.setState({ cpf: e.target.value })}
                disabled={this.props.information.cpf.length > 0}
              />
            </div>

            <div className="my-account-information-medium-content">
              <InputFieldErrorMessage message={this.state.emailError} />
              <input
                type="text"
                className="my-account-information-medium-input"
                placeholder="Email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                disabled={true}
              />
            </div>
          </div>

          <div className="my-account-information-submit-content">
            <div className="my-account-information-submit-button">
              <FormButton
                text={'SALVAR'}
                icon=""
                buttonType={'form-button-tertiary'}
                loading={this.props.editingLoading}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default MyAccountInformation;
