// @flow
import React from 'react';
// Components
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
// Analytics
import * as Analytics from '../../common/analytics';
// Styles
import './ImageCarousel.css';

type Props = {
  images: {
    src: string,
    link: string,
    alt: string,
  }[],
};

// Slick Slider Arrows
class SampleNextArrow extends React.Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <div className="banner-arrow-container-right">
          <i
            className="fa fa-caret-right fa-4x scroller-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

class SamplePrevArrow extends React.Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <div className="banner-arrow-container-left">
          <i
            className="fa fa-caret-left fa-4x scroller-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

// TODO: Add dots for selecting which image to scroll to
class ImageCarousel extends React.Component {
  props: Props;
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 10000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      swipe: true,
      vertical: false,
      lazyLoad: true, // Loads one image at a time starting with the slide that appears first
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div>
        <Slider {...settings}>
          {this.props.images.map((img, ind) => (
            <Link
              to={img.link}
              key={ind}
              data-index={ind}
              onClick={() => Analytics.logTappedBanner(img.alt)}
            >
              <img className="image-carousel-img" src={img.src} alt={img.alt} />
            </Link>
          ))}
        </Slider>
      </div>
    );
  }
}

export default ImageCarousel;
