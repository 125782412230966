// @flow
import React, { Component } from 'react';
// Components
import PaneTitle from '../PaneTitle';
import CreditCardAddNewButton from './CreditCardAddNewButton';
import CreditCardEditor from './CreditCardEditor';
import CheckoutContinueButton from '../CheckoutContinueButton';
import PaymentSummary from './PaymentSummary';
import CreditCardList from '../../containers/CreditCardList';
import CheckoutErrors from '../CheckoutErrors';
import { validationHelpers } from '../../../common';
import PaymentMethodSelector from './PaymentMethodSelector';
import Select from 'react-select';
// Analytics
import * as Analytics from '../../../common/analytics';
//Style
import './PaymentPane.css';
// Types
import type {
  PaymentSource,
  PaymentMethod,
  CreditCard,
  PaymentSourceCreditCard,
} from '../../types';
// Logger
import { logException } from '../../../logHelper';
// Other
import { phone } from '../../../common';

type Props = {
  orderNumber: string,
  errors: string[],
  promotionErrors: string[],
  selected: PaymentMethod,
  active: boolean,
  paymentMethods: PaymentMethod[],
  creditCards: CreditCard[],
  promotions: any,
  promotionsLoading: boolean,
  loading: boolean,
  submitPayment: (
    method: PaymentMethod,
    source: CreditCard | PaymentSource,
    existing?: boolean,
  ) => void,
  submitPromotion: (couponCode: string) => void,
  adjustmentTotal: string,
  dismissErrors: () => void,
};

type State = {
  selected: ?PaymentMethod,
  existingCard: boolean,
  paymentSource: null | CreditCard | PaymentSource,
  editing: boolean,
  newCardValidationErrors: {
    name: string,
    number: string,
    verificationValue: string,
    year: string,
    month: string,
  },
  newCard: boolean,
};

const createInitialState = (props: Props) => ({
  selected: null,
  paymentSource: null,
  editing: false,
  existingCard: false,
  newCardValidationErrors: {
    name: '',
    number: '',
    verificationValue: '',
    year: '',
    month: '',
  },
  newCard: false,
  selectedPromotions: [],
});

class PaymentPane extends Component {
  props: Props;
  state: State;
  editorComponent: Function;

  constructor(props: Props) {
    super(props);
    this.state = createInitialState(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.creditCards.length === 0 &&
      this.props.paymentMethods.length === 0 &&
      nextProps.paymentMethods.length !== 0
    ) {
      this.openCardEditor.bind(this)(nextProps);
    }

    if (
      this.props.creditCards.length === 0 &&
      nextProps.creditCards.length !== 0
    ) {
      this.closeCardEditor.bind(this)();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.paymentSource === undefined) {
      this.openCardEditor.bind(this)(nextProps);
    }
  }
  getOnlineGateway(paymentMethods: PaymentMethod[]) {
    const filteredMethods = paymentMethods.filter(
      method => method.methodType === 'gateway',
    );
    return filteredMethods.length > 0 ? filteredMethods[0] : null;
  }

  getOfflineGateway(paymentMethods: PaymentMethod[]) {
    const filteredMethods = paymentMethods.filter(
      method => method.methodType !== 'gateway',
    );
    return filteredMethods;
  }

  selectExistingCard(card: CreditCard) {
    const gatewayMethod = this.getOnlineGateway(this.props.paymentMethods);
    this.setState((state: State, props: Props): State => ({
      ...state,
      selected: gatewayMethod,
      paymentSource: card,
      existingCard: true,
      editing: false,
      newCard: false,
    }));
  }

  openCardEditor(props: Props, existingCard: boolean = false) {
    const gatewayMethod = this.getOnlineGateway(props.paymentMethods);
    this.setState((state: State, props: Props): State => ({
      ...state,
      selected: gatewayMethod,
      paymentSource: {
        name: '',
        number: '',
        month: '',
        year: '',
        verificationValue: '',
      },
      editing: true,
      existingCard,
      newCard: true,
    }));
  }

  closeCardEditor() {
    this.setState((state: State, props: Props): State => ({
      ...state,
      editing: false,
    }));
  }

  addNewCard(card: PaymentSourceCreditCard) {
    const gatewayMethod = this.getOnlineGateway(this.props.paymentMethods);
    this.setState((state: State, props: Props): State => ({
      ...state,
      selected: gatewayMethod,
      paymentSource: card,
      existingCard: false,
      newCard: true,
    }));
  }

  selectOfflinePaymentMethod(method: PaymentMethod) {
    this.setState((state: State, props: Props): State => ({
      ...state,
      selected: method,
      editing: true,
      existingCard: false,
      paymentSource: method.methodType,
      newCard: false,
    }));
  }

  submitPayment(e: Event) {
    e.preventDefault();
    const { selected, paymentSource, existingCard, newCard } = this.state;

    // If the payment source is a new credit card than we should validate the input
    // TODO: What happens if the paymentSource is cash?
    let noValidationErrors: boolean = true;

    let name = '';
    let number = '';
    let verificationValue = '';
    let year = '';
    let month = '';

    if (paymentSource && newCard) {
      name = validationHelpers.creditCard.name(paymentSource.name);
      if (name) {
        noValidationErrors = false;
      }

      number = validationHelpers.creditCard.number(paymentSource.number);
      if (number) {
        noValidationErrors = false;
      }

      verificationValue = validationHelpers.creditCard.cvv(
        paymentSource.verificationValue,
      );
      if (verificationValue) {
        noValidationErrors = false;
      }

      year = validationHelpers.creditCard.year(paymentSource.year);
      if (year) {
        noValidationErrors = false;
      }

      month = validationHelpers.creditCard.month(paymentSource.month);
      if (month) {
        noValidationErrors = false;
      }
    }

    const newCardValidationErrors = {
      name,
      number,
      verificationValue,
      year,
      month,
    };

    this.setState((prevState, props) => ({
      ...prevState,
      newCardValidationErrors,
    }));

    if (selected != null && noValidationErrors) {
      this.props.submitPayment(selected, paymentSource, existingCard);
      Analytics.logCompletedOrder();
    } else {
      console.error('Validation error on payment form.');
      logException(JSON.stringify(newCardValidationErrors));
    }
  }

  render() {
    const gatewayMethod = this.getOnlineGateway(this.props.paymentMethods);
    const offlineGatewayMethod = this.getOfflineGateway(
      this.props.paymentMethods,
    );
    const availablePromotions = this.props.promotions.map(promotion => {
      return { value: promotion.code, label: promotion.name };
    });

    const handleSubmitPromotion = value => {
      this.setState({ selectedPromotions: value });
      this.props.submitPromotion(value.value);
    };

    return (
      <div>
        <PaneTitle
          active={this.props.active}
          number={3}
          title={'Escolha como pagar'}
        >
          {!this.props.active && this.props.selected ? (
            <PaymentSummary payment={this.props.selected} />
          ) : null}
        </PaneTitle>

        {this.props.active && (
          <div className="payment-pane-content">
            {(this.props.promotions.length && (
              <div className="payment-pane-select-coupon">
                <CheckoutErrors
                  errors={this.props.promotionErrors}
                  dismiss={this.props.dismissErrors}
                />
                <h3 className="payment-pane-payment-information-title">
                  Cupom de Desconto
                </h3>
                {(!Number(this.props.adjustmentTotal) && (
                  <p className="payment-pane-discount-coupon-information">
                    Possui cupons de desconto? É possível selecionar estes nesse
                    momento.
                  </p>
                )) || (
                  <p className="payment-pane-discount-coupon-apply-information">
                    {`Foi aplicado um desconto de ${Math.abs(
                      parseFloat(this.props.adjustmentTotal),
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`}
                  </p>
                )}
                <Select
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#c0e8b7',
                    },
                  })}
                  placeholder={'Pesquise por seu Cupom'}
                  loading={this.props.promotionsLoading || this.props.loading}
                  onChange={value => handleSubmitPromotion(value)}
                  options={availablePromotions}
                />
              </div>
            )) ||
              null}
            <div>
              <CheckoutErrors
                errors={this.props.errors}
                dismiss={this.props.dismissErrors}
              />
              {gatewayMethod && (
                <div>
                  <h3 className="payment-pane-payment-information-title">
                    Cartão de crédito online
                  </h3>
                  {this.state.selected === gatewayMethod &&
                  this.state.editing ? (
                    <CreditCardEditor
                      card={this.state.paymentSource}
                      validationErrors={this.state.newCardValidationErrors}
                      onUpdate={this.addNewCard.bind(this)}
                    />
                  ) : (
                    <CreditCardAddNewButton
                      onClick={() => this.openCardEditor.bind(this)(this.props)}
                      isEditing={false}
                    />
                  )}
                </div>
              )}
              <CreditCardList
                onClick={this.selectExistingCard.bind(this)}
                selectedCardId={
                  this.state.paymentSource && this.state.paymentSource.id
                    ? this.state.paymentSource.id
                    : null
                }
              />
              <PaymentMethodSelector
                offlineGatewayMethod={offlineGatewayMethod}
                selected={this.state.selected}
                selectOfflinePaymentMethod={this.selectOfflinePaymentMethod.bind(
                  this,
                )}
              />
              <div className="payment-pane-checkout-continue-button">
                <CheckoutContinueButton
                  onClick={this.submitPayment.bind(this)}
                  disabled={this.state.selected == null}
                  text="Finalizar Compra"
                  loading={this.props.loading}
                />
              </div>
              <div className="payment-pane-methods-message">
                * Para outros métodos de pagamento entre em contato conosco pelo
                whatsapp {phone.asStringFull}. Seu código de pedido é{' '}
                {this.props.orderNumber}.
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaymentPane;
