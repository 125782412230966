// @flow
import React from 'react';
import './CartTotal.css';
import styled from 'styled-components';
import PriceInformationNotice from '../../common/components/PriceInformationNotice';
import QuestionMarkIcon from '../../images/question-mark.png';
import { quantityConverter } from '../../common';

const Price = styled.p`
  font-size: 23px;
  font-weight: bold;
  line-height: 28px;
  color: #c34b4b;
  padding: 0px 10px;
`;

const TotalQuestion = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  color: #0066c0;
  margin-top: -7px;
`;

const Subtotal = styled.h2`
  font-size: 18px;
  line-height: 19px;
  color: #4a4a4a;
  margin-top: 5px;
`;

type Props = {
  totalPrice: number,
  numberOfItems: number,
  totalKg: number,
  totalUnit: number,
};

class CartTotal extends React.Component {
  props: Props;
  render() {
    return (
      <div className="cart-total-container">
        <div className="cart-total-sub-container">
          <Subtotal>
            Subtotal estimado ({this.props.numberOfItems} produtos em{' '}
            {this.props.totalKg
              ? quantityConverter(this.props.totalKg, 'g')
              : ''}{' '}
            {this.props.totalKg && this.props.totalUnit ? 'e' : ''}{' '}
            {this.props.totalUnit
              ? quantityConverter(this.props.totalUnit, ' un')
              : ''}
            )
          </Subtotal>

          <div className="cart-total-price-info-container">
            <TotalQuestion>
              <img
                src={QuestionMarkIcon}
                alt=""
                className="buy-box-question-mark"
              />
              Entenda o preço
            </TotalQuestion>

            <div className="cart-total-price-info">
              <PriceInformationNotice />
            </div>
          </div>

          <Price>
            {parseFloat(this.props.totalPrice).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Price>
        </div>
      </div>
    );
  }
}

export default CartTotal;
