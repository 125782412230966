// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import PaginatedScroller from '../../components/PaginatedScroller';
// Actions
import { findPromotionProducts, clearPromotionProducts } from '../../actions';
// Selectors
import {
  promotionProductsSelector,
  promotionProductsLoadingSelector,
  promotionProductLastPageSelector,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  products: Product[],
  isLoading: boolean,
  findProducts: () => void,
  isLastPage: boolean,
};

type State = {
  currentPage: number,
  perPage: number,
};

const ProductPromotionScroller = ({
  products,
  isLastPage,
  isLoading,
  findProducts,
  clearProducts,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const perPage = 10;

  useEffect(() => {
    // Used to clear outdated products (due to preload caching)
    clearProducts();

    findProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestNewPage = index => {
    if (Math.floor(index / perPage) + 1 === currentPage && !isLastPage) {
      findProducts(currentPage + 1, 10);
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <PaginatedScroller
      title="PROMOÇÕES"
      products={products}
      isLoading={isLoading && currentPage === 1}
      isLoadingPage={isLoading}
      requestNewPage={requestNewPage}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: promotionProductsSelector(state),
    isLoading: promotionProductsLoadingSelector(state),
    isLastPage: promotionProductLastPageSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: (page, perPage) => {
      dispatch(findPromotionProducts(page, perPage));
    },
    clearProducts: (page, perPage) => {
      dispatch(clearPromotionProducts(page, perPage));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductPromotionScroller);
