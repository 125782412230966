//@flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './OptionsSelector.css';

import type { ProductOptions } from '../types';
import OptionBox from './OptionBox';

type Props = {
  options: ProductOptions[],
};

class OptionsSelector extends Component {
  props: Props;

  render() {
    return (
      <div className="option-main">
        {this.props.options.map((option, i) => (
          <div key={i}>
            <h4 className="option-name">{option.presentationName}</h4>
            <div className="option-container">
              {option.values.map((
                value,
                i /*TODO: Figure out how to disable this link*/,
              ) => (
                <Link
                  key={i}
                  className="option-link"
                  to={`/produtos/${String(value.slug)}/`}
                >
                  <OptionBox
                    imgSrc={value.imageUrl}
                    text={value.presentationName}
                    isSelected={value.selected}
                  />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default OptionsSelector;