// @flow
import React, { Component } from 'react';

import './MainProductContent.css';

// Components
import ImageGallery from './ImageGallery';
import OptionsSelector from './OptionsSelector';
import BuyBox from '../../current-order/components/BuyBox';

// Types
import type { Product } from '../types';
export type Props = {
  product: Product,
};

class MainProductContent extends Component {
  props: Props;
  render() {
    const product = this.props.product;
    return (
      <div className="main-product-content-main">
        <div className="main-product-container">
          {/* TODO: Add loading state */}
          <ImageGallery images={product.images} isLoading={false} />
          <BuyBox product={product} />
        </div>

        <OptionsSelector options={product.options} />

      </div>
    );
  }
}

export default MainProductContent;
