// @flow
import React, { Component } from 'react';
//Components
import { ColorRow, PageLoader } from '../../common';
import DepartmentHit from './DepartmentHit';
// Types
import type { Department } from '../types';

import './DepartmentsIndexPage.css';

type Props = {
  departments: Department[],
  loading: boolean,
};

class DepartmentsIndexPage extends Component {
  props: Props;

  render() {
    if (this.props.loading && this.props.departments.length === 0) {
      return (
        <div>
          <ColorRow color="grey" text="DEPARTAMENTOS" />
          <PageLoader />
        </div>
      );
    }

    return (
      <div className="departments-index-main">
        <ColorRow color="grey" text="DEPARTAMENTOS" />

        <div className="departments-index-flex-container">
          {this.props.departments.map((department, ind) => (
            <DepartmentHit
              key={ind}
              id={department.id}
              title={department.name}
              categories={department.subdepartments}
              image={department.imageUrl}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default DepartmentsIndexPage;
