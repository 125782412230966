// @flow
import React, { useEffect } from 'react';
import _ from 'lodash';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Components
import UnconnectedProductPage from '../components/ProductPage';
// Selectors
import {
  currentProductSelector,
  currentProductLoadingSelector,
} from '../selectors';

import { recipesByProductIdSelector } from '../../recipes/selectors';
// Actions
import { findProduct } from '../actions';
import { findRecipesByProduct } from '../../recipes/actions';
// Types
import type { Connector } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';
import type { Dispatch } from '../../types';
import type { Product } from '../types';

type RouterProps = ContextRouter;
type ReduxProps = {
  product: Product,
  loading: boolean,
  findProduct: (slug: string | number) => void,
};

type Props = RouterProps & ReduxProps;

const ProductPage = ({
  match,
  product,
  recipes,
  findProduct,
  getRecipesByProduct,
  loading,
}) => {
  const slug = match.params.id;

  useEffect(() => {
    findProduct(slug);
  }, [match]);

  useEffect(() => {
    if (product) {
      getRecipesByProduct(product.productId);
    }
  }, [product]);

  return (
    <UnconnectedProductPage
      product={product}
      match={match}
      recipes={recipes((product && product.productId) || null)}
      loading={loading}
    />
  );
};

const mapStateToProps = (state, ownProps: Props) => {
  return {
    product: currentProductSelector(state),
    loading: currentProductLoadingSelector(state),
    recipes: productId => recipesByProductIdSelector(state, productId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProduct: slug => {
      dispatch(findProduct(slug));
    },
    getRecipesByProduct: id => {
      dispatch(findRecipesByProduct(id));
    },
  };
};

const connector: Connector<RouterProps, ReduxProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(connector(ProductPage));
