// @flow
import React from 'react';
// Styles
import './AboutUsPage.css';
// Components
import { ColorRow } from '../../common';
import { Helmet } from 'react-helmet';
import { Video } from '../../common';

const AboutUsPage = () => {
  return (
    <div className="about-us-main">
      <Helmet>
        <title>Sobre Nós - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:image"
          content="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F639809393_1280x720.webp&amp;src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F639809393_1280x720.webp&amp;src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <meta
          property="og:video:url"
          content="https://player.vimeo.com/video/221520060?autoplay=1"
        />
        <meta
          property="og:video:secure_url"
          content="https://player.vimeo.com/video/221520060?autoplay=1"
        />
        <meta property="og:video:type" content="text/html" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />
      </Helmet>

      <ColorRow color="grey" text="Sobre Nós" />
      <div>
        <Video videoUrl={'https://player.vimeo.com/video/221520060'} />
      </div>

      <hr className="about-us-line" />
      <div className="about-us-text">
        <h3> Quem Somos? </h3>
        <p>
          Já atuamos há 35 anos no mercado de carnes da Bahia com frigoríficos,
          lojas e açougues próprios. Nos sentíamos praticamente completos, mas
          um pequeno fator sempre nos incomodou; as filas. Percebemos então, que
          a melhor forma de acabar com elas é invertendo a lógica do negócio, ao
          invés do cliente ir até o açougue, o açougue vai até o cliente. Sim,
          simples assim, somos a novidade que a comodidade sempre esperou, somos
          o Big Delivery, carne fresca e de qualidade na porta da sua casa, no
          seu dia, na sua hora e do seu jeito. Chega a ser difícil de acreditar,
          mas deixe-nos impressionar ainda mais, basta fazer o primeiro pedido!
        </p>

        <div className="about-us-container">
          <div>
            <h3>Missão</h3>
            <p>
              Facilitar o dia a dia de pessoas através da entrega de produtos
              alimentícios com foco em carnes com excelente padrão de qualidade.
            </p>
          </div>

          <div>
            <h3>Visão</h3>
            <p>Inovação constante para melhor atender os nossos clientes.</p>
          </div>
        </div>

        <h3>Valores da Empresa</h3>
        <div className="about-us-values-container">
          <div>
            <p>1. Pessoas em 1º lugar </p>
            <p>2. Qualidade Superior</p>
            <p>3. Eficiência </p>
          </div>
          <div>
            <p>4. Comprometimento</p>
            <p>5. Respeito</p>
            <p>6. Transparência</p>
          </div>
        </div>
      </div>
      <hr className="about-us-line" />
    </div>
  );
};

export default AboutUsPage;
