// @flow
import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  loginUserRequest,
  loginUser,
  registerUserRequest,
  registerUser,
  logoutUser,
  getUserAddressesRequest,
  getUserAddresses,
  validateUserRequest,
  validateUser,
  updateUserInformationRequest,
  updateUserInformation,
  newUserAddressRequest,
  newUserAddress,
  updateUserAddressRequest,
  updateUserAddress,
  deleteUserAddressRequest,
  deleteUserAddress,
  getUserCreditCardsRequest,
  getUserCreditCards,
  postUserForgotPasswordRequest,
  postUserForgotPassword,
  putUserResetPasswordRequest,
  putUserResetPassword,
  deleteUserCreditCardRequest,
  deleteUserCreditCard,
  updateUserPasswordRequest,
  updateUserPassword,
} from '../actions';

// Types
import type { Reducer } from 'redux';
import type { Action } from '../../types';

// User Logged In
const loggedIn: Reducer<string, Action> = handleActions(
  {
    [loginUser]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [validateUser]: {
      next: (state, action) => true,
      throw: (state, action) => false,
    },
    [logoutUser]: (state, action) => false,
  },
  false,
);

// Information
const initialUserInformation = {
  firstName: '',
  lastName: '',
  cpf: '',
  email: '',
  addresses: [],
  creditCards: [],
};
const information = handleActions(
  {
    [combineActions(loginUser, validateUser, updateUserInformation)]: {
      next: (state, action) =>
        action.payload.entities.user[action.payload.result],
      throw: (state, action) => initialUserInformation,
    },
    [logoutUser]: (state, action) => initialUserInformation,
    [getUserAddresses]: {
      next: (state, action) => ({
        ...state,
        addresses: action.payload.result,
      }),
      throw: (state, action) => state,
    },
    [newUserAddress]: {
      next: (state, action) => ({
        ...state,
        addresses: [...state.addresses, action.payload.result],
      }),
      throw: (state, action) => state,
    },
    [deleteUserAddress]: {
      next: (state, action) => ({
        ...state,
        addresses: _.filter(state.addresses, o => o !== action.payload),
      }),
      throw: (state, action) => state,
    },
    [getUserCreditCards]: {
      next: (state, action) => ({
        ...state,
        creditCards: action.payload.result || [],
      }),
      throw: (state, action) => state,
    },
  },
  initialUserInformation,
);

// Loading
const initialLoadingState = {
  loggingIn: false,
  registering: false,
  validating: false,
  gettingAddresses: false,
  addingAddress: false,
  editingAddress: false,
  deletingAddress: [], // IDs of the addresses that are being deleted
  editingInformation: false,
  gettingCreditCards: false,
  forgotPassword: false,
  resetPassword: false,
  deletingCreditCard: false,
  updatingPassword: false,
};
const loading = handleActions(
  {
    [loginUserRequest]: (state, action) => ({ ...state, loggingIn: true }),
    [loginUser]: (state, action) => ({ ...state, loggingIn: false }),
    [registerUserRequest]: (state, action) => ({ ...state, registering: true }),
    [registerUser]: (state, action) => ({ ...state, registering: false }),
    [validateUserRequest]: (state, action) => ({ ...state, validating: true }),
    [validateUser]: (state, action) => ({ ...state, validating: false }),
    [getUserAddressesRequest]: (state, action) => ({
      ...state,
      gettingAddresses: true,
    }),
    [getUserAddresses]: (state, action) => ({
      ...state,
      gettingAddresses: false,
    }),
    [newUserAddressRequest]: (state, action) => ({
      ...state,
      addingAddress: true,
    }),
    [newUserAddress]: (state, action) => ({ ...state, addingAddress: false }),
    [updateUserAddressRequest]: (state, action) => ({
      ...state,
      editingAddress: true,
    }),
    [updateUserAddress]: (state, action) => ({
      ...state,
      editingAddress: false,
    }),
    [deleteUserAddressRequest]: (state, action) => ({
      ...state,
      deletingAddress: _.union(state.deletingAddress, [action.payload]),
    }),
    [deleteUserAddress]: (state, action) => ({
      ...state,
      deletingAddress: _.filter(
        state.deletingAddress,
        o => o !== action.payload,
      ),
    }),
    [updateUserInformationRequest]: (state, action) => ({
      ...state,
      editingInformation: true,
    }),
    [updateUserInformation]: (state, action) => ({
      ...state,
      editingInformation: false,
    }),
    [getUserCreditCardsRequest]: (state, action) => ({
      ...state,
      gettingCreditCards: true,
    }),
    [getUserCreditCards]: (state, action) => ({
      ...state,
      gettingCreditCards: false,
    }),
    [postUserForgotPasswordRequest]: (state, action) => ({
      ...state,
      forgotPassword: true,
    }),
    [postUserForgotPassword]: (state, action) => ({
      ...state,
      forgotPassword: false,
    }),
    [putUserResetPasswordRequest]: (state, action) => ({
      ...state,
      resetPassword: true,
    }),
    [putUserResetPassword]: (state, action) => ({
      ...state,
      resetPassword: false,
    }),
    [deleteUserCreditCardRequest]: (state, action) => ({
      ...state,
      deletingCreditCard: true,
    }),
    [deleteUserCreditCard]: (state, action) => ({
      ...state,
      deletingCreditCard: false,
    }),
    [updateUserPasswordRequest]: (state, action) => ({
      ...state,
      updatingPassword: true,
    }),
    [updateUserPassword]: (state, action) => ({
      ...state,
      updatingPassword: false,
    }),
  },
  initialLoadingState,
);

// Addresses
const addresses = handleActions(
  {
    [combineActions(validateUser, getUserAddresses)]: {
      next: (state, action) => action.payload.entities.address,
      throw: (state, action) => state,
    },
    [combineActions(updateUserAddress, newUserAddress)]: {
      next: (state, action) => ({
        ...state,
        ...action.payload.entities.address,
      }),
      throw: (state, action) => state,
    },
  },
  {},
);

// Credit Cards
const creditCards = handleActions(
  {
    [combineActions(getUserCreditCards)]: {
      next: (state, action) => action.payload.entities.creditCards || {},
      throw: (state, action) => state,
    },

    [deleteUserCreditCard]: {
      next: (state, action) => _.omit(state, action.payload),
      throw: (state, action) => state,
    },
  },
  {},
);

// Allowed Payment Methods
const allowedPaymentMethods = handleActions(
  {
    [combineActions(validateUser, loginUser)]: {
      next: (state, action) =>
        action.payload.entities.allowedPaymentMethods || {},
      throw: (state, action) => state,
    },
  },
  {},
);

// Error
const initialErrorState = {
  wrongLoginUsername: false,
  wrongLoginPassword: false,
  registration: {},
  forgotPassword: {},
  resetPassword: {},
  wrongUpdatePassword: false,
};
const errors = handleActions(
  {
    [loginUser]: {
      next: (state, action) => ({
        ...state,
        wrongLoginUsername: false,
        wrongLoginPassword: false,
      }),
      throw: (state, action) => ({
        ...state,
        wrongLoginUsername: true,
        wrongLoginPassword: true,
      }),
    },
    [updateUserPassword]: {
      next: (state, action) => ({
        ...state,
        wrongUpdatePassword: initialErrorState.wrongUpdatePassword,
      }),
      throw: (state, action) => ({
        ...state,
        wrongUpdatePassword: action.error,
      }),
    },
    [registerUser]: {
      next: (state, action) => ({
        ...state,
        registration: initialErrorState.registration,
      }),
      throw: (state, action) => ({
        ...state,
        registration: JSON.parse(action.payload.message),
      }),
    },
    [postUserForgotPassword]: {
      next: (state, action) => ({
        ...state,
        forgotPassword: initialErrorState.forgotPassword,
      }),
      throw: (state, action) => ({
        ...state,
        forgotPassword: JSON.parse(action.payload.message),
      }),
    },
    [putUserResetPassword]: {
      next: (state, action) => ({
        ...state,
        resetPassword: initialErrorState.resetPassword,
      }),
      throw: (state, action) => ({
        ...state,
        resetPassword: JSON.parse(action.payload.message),
      }),
    },
  },
  initialErrorState,
);

const reducers = combineReducers({
  loggedIn,
  information,
  addresses,
  creditCards,
  allowedPaymentMethods,

  errors,
  loading,
});

export default reducers;
