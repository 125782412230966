// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import { translateAasm, getTimeFormat3l } from '../../common';
import { orderTrackerStateName } from '../../common/commonHelpers';
// Components
import { Link } from 'react-router-dom';
// Selectors
import { copyingOrderLoadingSelector } from '../../current-order/selectors';
//Conectors
// TODO: FIX THIS IMPORT!! For some reason cannot import from index.js
import currentOrderNumberConnector from '../../current-order/connectors/currentOrderNumberConnector';
// Actions
import { copyingOrder } from '../../current-order/actions';
// Styles
import './OrderLineItem.css';

type Props = {
  orderNumber: number,
  fulfillmentState: string,
  deliveryState: string,
  orderDate: string,
  orderQuantity: number,
  copyingOrderLoading: boolean,
};

class OrderLineItem extends Component {
  props: Props;

  handleClick = (orderNumber, orderNumberFrom) => {
    const conf = window.confirm(
      `Itens do pedido ${orderNumberFrom} serão adicionados ao seu carrinho (pedido ${orderNumber}). Deseja continuar?`,
    );

    if (conf) {
      this.props.copyingOrder(orderNumber, orderNumberFrom);
    }
  };

  render() {
    return (
      <div className="order-line-item-div">
        <Link
          className="order-line-item-main"
          to={`/minha-conta/pedidos/${this.props.orderNumber}/`}
        >
          <p className="order-line-order-number">
            Pedido #{this.props.orderNumber}
          </p>
          <p>{this.props.orderQuantity} produto(s)</p>
          <p>
            {this.props.orderDate
              ? getTimeFormat3l(this.props.orderDate)
              : '--'}
          </p>
          <p className="order-line-order-status">{`${orderTrackerStateName(
            this.props.fulfillmentState,
            this.props.deliveryState,
          )}`}</p>
        </Link>

        {this.props.copyingOrderLoading ? (
          <button className="order-line-order-copy-button">
            <i className="fa fa-spinner fa-pulse" />
          </button>
        ) : (
          <button
            className="order-line-order-copy-button"
            onClick={() =>
              this.handleClick(
                this.props.currentOrderNumber,
                this.props.orderNumber,
              )
            }
          >
            <i className="fa fa-copy fa-fw" title="Copiar pedido." />
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    copyingOrderLoading: copyingOrderLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    copyingOrder: (orderNumber: string, orderNumberFrom: string) => {
      dispatch(copyingOrder(orderNumber, orderNumberFrom));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default currentOrderNumberConnector(connector(OrderLineItem));
