// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';

// Components
import Scroller from '../../components/Scroller';
// Actions
import { clearMostSoldProducts, findMostSoldProducts } from '../../actions';
// Selectors
import {
  mostSoldProductsSelector,
  mostSoldProductsLoadingSelector,
  mostSoldProductLastPageSelector,
} from '../../selectors';
// Types
import type { Product } from '../../types';

type Props = {
  products: Product[],
  isLoading: boolean,
  findProducts: () => void,
};

const ProductMostSoldScroller = ({
  products,
  isLastPage,
  isLoading,
  findProducts,
  clearProducts,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  useEffect(() => {
    clearProducts();

    findProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const requestNewPage = (index) => {
  //   if (Math.floor(index / perPage) + 1 === currentPage && !isLastPage) {
  //     findProducts(currentPage + 1, 10);
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  return (
    <Scroller title="MAIS VENDIDOS" products={products} isLoading={isLoading} />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    products: mostSoldProductsSelector(state),
    isLoading: mostSoldProductsLoadingSelector(state),
    isLastPage: mostSoldProductLastPageSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findProducts: (page, perPage) => {
      dispatch(findMostSoldProducts(page, perPage));
    },
    clearProducts: (page, perPage) => {
      dispatch(clearMostSoldProducts(page, perPage));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(ProductMostSoldScroller);
