// @flow
import React, { Component } from 'react';
import './SearchResultHorizontalBottomRow.css';
import { AddToCartButton } from '../../current-order';
// Components
import { ProductPrice } from '../../common';

// Types

type Props = {
  productId: number | string,
  manufacturer: string,
  listPrice: number,
  price: number,
  cookingMethods: any[],
  unit: 'g' | 'un',
  defaultQuantity: number,
  incrementQuantity: number,
  inStock: boolean,
};

class SearchResultHorizontalBottomRow extends Component {
  props: Props;
  render() {
    // const price = this.props.unit === 'g' ? this.props.price * 1000 : this.props.price;
    return (
      <div className="search-result-hit-horizontal-bottom-row-main">
        <div className="search-result-hit-content">
          <div className="search-result-price-value">
            <ProductPrice
              productListPrice={this.props.listPrice}
              productPrice={this.props.price}
              productUnit={this.props.unit}
            />
          </div>

          <AddToCartButton
            productId={this.props.productId}
            unitForQuantity={this.props.unit}
            defaultQuantity={this.props.defaultQuantity}
            incrementQuantity={this.props.incrementQuantity}
            inStock={this.props.inStock}
            analyticsEventType={'search result'} // USE ANALYTICS
          />
        </div>

        <div className="search-result-hit-details-container">
          <p className="search-result-hit-details">{this.props.manufacturer}</p>
          <div className="search-result-hit-detail-list">
            {this.props.cookingMethods.map((c, ind) => (
              <img key={ind} src={c.smallUrl} alt={c.name} />
            ))}
          </div>

          <div className="search-result-hit-details-price">
            <ProductPrice
              productListPrice={this.props.listPrice}
              productPrice={this.props.price}
              productUnit={this.props.unit}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResultHorizontalBottomRow;
