// @flow
import React from 'react';
// Styles
import './ComplementaryCategoryPicker.css';
// Types
type Props = {
  id: number,
  name: string,
  handleClick: () => void,
  isSelected: boolean,
};

const ComplementaryCategoryPicker = ({
  id,
  name,
  handleClick,
  isSelected,
}: Props) => {
  return (
    <div
      className="accompaniment"
      onClick={() => {
        handleClick(id);
      }}
    >
      <div className={`checkbox ${isSelected ? 'selected' : ''}`}>
        <span className={`checkbox-input ${isSelected ? 'selected' : ''}`}>
          <input
            type="checkbox"
            name="checkbox"
            className="complementary-category-checkbox-input"
          />
          <span className="checkbox-icon">
            <i className="fas fa-check"></i>
          </span>
        </span>
        <span className="checkbox-text">{name}</span>
      </div>
    </div>
  );
};

export default ComplementaryCategoryPicker;
