// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './LoggedOutOptions.css';

const LoggedOutOptions = () => {
  return (
    <div className="logged-out-options-main">
      <Link className="logged-out-options-register" to="/login/">
        CADASTRAR
      </Link>
      <Link className="logged-out-options-login" to="/login/">
        ENTRAR
      </Link>
    </div>
  );
};

export default LoggedOutOptions;
