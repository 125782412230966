// @flow
import React, { Component } from 'react';

import './Button.css';

type Props = {
  text: string,
  icon: string,
  buttonType: string,
  loading: boolean,
  disabled: boolean,
  onClick: () => void,
};

class Button extends Component {
  props: Props;
  render() {
    let text = this.props.text;
    if (this.props.loading) {
      text = 'Carregando...';
    }
    const disabled = this.props.loading | this.props.disabled;

    return (
      <button
        className={`button-main ${this.props.buttonType} ${
          (disabled && 'button-disabled') || ''
        }`}
        onClick={this.props.onClick}
        disabled={disabled}
      >
        {this.props.icon !== '' ? (
          <div className="row button-row">
            <img className="button-icon" alt="" src={this.props.icon} /> &nbsp;{' '}
            {this.props.text}
          </div>
        ) : (
          <div className="row button-row">
            {this.props.loading ? (
              <i className="fa fa-spinner fa-pulse fa-1x" aria-hidden="true" />
            ) : (
              <div />
            )}
            <span className="button-just-text">{text}</span>
          </div>
        )}
      </button>
    );
  }
}

export default Button;
