// @flow
import { createAction } from 'redux-actions';
// Types
import type { Dispatch } from '../../types';
// Apis
import Blend from '../api';
// Selectors
import * as selectors from '../selectors';
import { orderNumberSelector } from '../../current-order/selectors';
// External actions
import { findOrCreateOrder } from '../../current-order/actions';
// Logger
import { logException } from '../../logHelper';

/////////////////////////////// BLEND TEMPLATE RELATED ////////////////////////////

// Create Blend Template
export const newBlendTemplateRequest = createAction(
  'NEW_BLEND_TEMPLATE_REQUEST',
);
export const newBlendTemplate = createAction('NEW_BLEND_TEMPLATE');

export function createBlendTemplate() {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(newBlendTemplateRequest());

      const response = await Blend.createBlendTemplate('', '', []);
      dispatch(newBlendTemplate(response));
    } catch (err) {
      logException(err);
      dispatch(newBlendTemplate(err));
    }
  };
}

// Get Blend Templates (many)
export const fetchBlendTemplatesRequest = createAction(
  'FETCH_BLEND_TEMPLATES_REQUEST',
);
export const fetchBlendTemplates = createAction('FETCH_BLEND_TEMPLATES');

export function getBlendTemplates() {
  return async (dispatch: Dispatch) => {
    dispatch(fetchBlendTemplatesRequest());
    try {
      const response = await Blend.getBlendTemplates();
      dispatch(fetchBlendTemplates(response));
    } catch (err) {
      logException(err);
      dispatch(fetchBlendTemplates(err));
    }
  };
}

// Get a Specific Blend Template (only one)
export const fetchBlendTemplateRequest = createAction(
  'FETCH_BLEND_TEMPLATE_REQUEST',
);
export const fetchBlendTemplate = createAction('FETCH_BLEND_TEMPLATE');

export function getBlendTemplate(blendId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(fetchBlendTemplateRequest());
    try {
      const response = await Blend.getBlendTemplate(blendId);
      dispatch(fetchBlendTemplate(response));
    } catch (err) {
      logException(err);
      dispatch(fetchBlendTemplate(err));
    }
  };
}

// Update Blend Template
export const changeBlendTemplateRequest = createAction(
  'CHANGE_BLEND_TEMPLATE_REQUEST',
);
export const changeBlendTemplate = createAction('CHANGE_BLEND_TEMPLATE');

export function updateBlendTemplate(
  blendId: number,
  name: ?string,
  observation: ?string,
  complete: ?boolean,
  fatPercentage: ?number,
) {
  return async (dispatch: Dispatch) => {
    dispatch(changeBlendTemplateRequest());
    try {
      const response = await Blend.updateBlendTemplate(
        blendId,
        name,
        observation,
        complete,
        fatPercentage,
      );
      dispatch(changeBlendTemplate(response));
    } catch (err) {
      logException(err);
      dispatch(changeBlendTemplate(err));
    }
  };
}

// Delete Blend Template
export const removeBlendTemplateRequest = createAction(
  'REMOVE_BLEND_TEMPLATE_REQUEST',
);
export const removeBlendTemplate = createAction('REMOVE_BLEND_TEMPLATE');

export function deleteBlendTemplate(templateId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(removeBlendTemplateRequest(templateId));
    try {
      await Blend.deleteBlendTemplate(templateId);
      dispatch(removeBlendTemplate(templateId));
    } catch (err) {
      logException(err);
      dispatch(removeBlendTemplate(err));
    }
  };
}

/////////////////////////// BLEND RELATED //////////////////////////////
// Note: Here we are dealing with actual blends. Not templates.

// Add Blend to Cart (creates a new blend from a blend template)
export const addBlendtoCartRequest = createAction('ADD_BLEND_TO_CART_REQUEST');
export const addBlendtoCart = createAction('ADD_BLEND_TO_CART');

export function createBlend(blendTemplateId: number, quantity: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(addBlendtoCartRequest({ blendTemplateId }));

      // Get the orderNumber to which this blend should be added.
      const orderNumber = orderNumberSelector(getState());

      const response = await Blend.createBlend(
        blendTemplateId, // Blend will be created from this template
        quantity,
        orderNumber,
      );
      dispatch(addBlendtoCart({ ...response, blendTemplateId }));

      // Since we succeeded we should update the order to reflect the new lineItems added to it
      dispatch(findOrCreateOrder()); // Update the entire order to make sure application is up to date
    } catch (err) {
      logException(err);
      dispatch(addBlendtoCart(err));
    }
  };
}

// Gets the blends that belong to the given order
export const fetchBlendsRequest = createAction('FETCH_BLENDS_REQUEST');
export const fetchBlends = createAction('FETCH_BLENDS');

export function getBlends(orderNumber: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(fetchBlendsRequest());
      const response = await Blend.getBlendsByOrderNumber(orderNumber);
      dispatch(fetchBlends(response));
    } catch (err) {
      logException(err);
      dispatch(fetchBlends(err));
    }
  };
}

// Update Blend. Note: A Blend is an "instance" of the blendTemplate and as such has line items in the cart. Updating it here propagates the changes to the corresponding line items.
export const changeBlendRequest = createAction(
  'CHANGE_BLEND_REQUEST',
  blendId => ({ blendId }),
);
export const changeBlend = createAction(
  'CHANGE_BLEND',
  (blendId, quantity) => ({ blendId, quantity }),
  (blendId, quantity, originalQuantity, isOptimistic) => ({
    originalQuantity,
    isOptimistic: isOptimistic || false,
  }),
);

// Remove blend and its associated line_items from the cart.
export const removeBlendRequest = createAction(
  'REMOVE_BLEND_REQUEST',
  blendId => ({ blendId }),
);
export const removeBlend = createAction('REMOVE_BLEND');

// Stores data required for optimistic loading and reverting on error
const optimisticBlendData = {};
export function updateBlendQuantity(blendId: number, quantity: number) {
  return (dispatch: Dispatch, getState: () => State) => {
    // Get the order number and blend
    const orderNumber = orderNumberSelector(getState());
    const blend = selectors.blendByOrderNumberAndBlendIdSelector(
      getState(),
      orderNumber,
      blendId,
    );
    const minimumQuantity = blend.minQuantity;

    // Clear debounce for current lineItem
    if (optimisticBlendData[blend.id]) {
      clearTimeout(optimisticBlendData[blend.id].debouncedIncreaseClick);
    } else {
      // If we don't have optimisticBlendData for this blend create object
      optimisticBlendData[blend.id] = {
        debouncedIncreaseClick: null,
        originalQuantity: null,
      };
    }
    dispatch(changeBlendRequest(blend.id)); // Trigger loading state
    // Store away original quantity in case we get an error anywhere
    if (optimisticBlendData[blend.id].originalQuantity == null) {
      optimisticBlendData[blend.id].originalQuantity = blend.quantity;
    }

    const nextQuantity = blend.quantity + quantity;

    // Update reducer optimistically if we are not deleting
    if (nextQuantity >= minimumQuantity) {
      dispatch(
        changeBlend(
          blend.id,
          nextQuantity,
          optimisticBlendData[blend.id].originalQuantity,
          true,
        ),
      ); // True since this one is optimistic
    }

    // Set timeout to update via api
    optimisticBlendData[blend.id].debouncedIncreaseClick = setTimeout(
      async () => {
        try {
          if (nextQuantity < minimumQuantity) {
            // Remove blend if the quantity is below minimum
            await Blend.deleteBlend(blend.id);
            dispatch(removeBlend({ blendId: blend.id }));
          } else {
            const response = await Blend.updateBlend(blend.id, nextQuantity);
            const nextBlend = response.entities.blends[response.result];
            dispatch(
              changeBlend(
                nextBlend.id,
                nextBlend.quantity,
                optimisticBlendData[blend.id].originalQuantity,
              ),
            );
          }
        } catch (err) {
          // TODO: This error should set the product quantity back to the original. Implement in reducer
          logException(err, {
            originalQuantity: optimisticBlendData[blend.id].originalQuantity,
          });
          dispatch(
            changeBlend(err, optimisticBlendData[blend.id].originalQuantity),
          );
        } finally {
          optimisticBlendData[blend.id].originalQuantity = null;
          dispatch(findOrCreateOrder()); // Update the entire order to make sure application is up to date
        }
      },
      700,
    );
  };
}

export function deleteBlend(blendId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(removeBlendRequest(blendId));
    try {
      await Blend.deleteBlend(blendId); // Delete does not return anything useful
      dispatch(removeBlend({ blendId })); // We need to pass ID into after action so that we can remove the corresponding blend from cart
      dispatch(findOrCreateOrder()); // Update the entire order to make sure application is up to date
    } catch (err) {
      logException(err);
      dispatch(removeBlend(err));
    }
  };
}

/////////////////////////////// BLEND TEMPLATE ITEM RELATED ////////////////////////////

// Add Item to a blend template
export const addItemToBlendTemplateRequest = createAction(
  'ADD_ITEM_TO_BLEND_TEMPLATE_REQUEST',
);
export const addItemToBlendTemplate = createAction(
  'ADD_ITEM_TO_BLEND_TEMPLATE',
);

export function createBlendTemplateItem(
  blendId: number,
  itemId: number,
  quantity: number,
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(addItemToBlendTemplateRequest({ itemId }));

      const response = await Blend.createBlendTemplateItem(
        blendId,
        itemId,
        quantity,
      );
      dispatch(addItemToBlendTemplate({ ...response, blendId }));
      dispatch(getBlendTemplate(blendId));
    } catch (err) {
      logException(err);
      dispatch(addItemToBlendTemplate(err));
    }
  };
}

// Change quantity of item in blend template. Might be used for changing other properties in the future.
export const changeBlendTemplateItemRequest = createAction(
  'CHANGE_BLEND_TEMPLATE_ITEM_REQUEST',
  itemId => ({ itemId }),
);
export const changeBlendTemplateItem = createAction(
  'CHANGE_BLEND_TEMPLATE_ITEM',
  (itemId, quantity) => ({ itemId, quantity }),
  (itemId, quantity, originalQuantity, isOptimistic) => ({
    originalQuantity,
    isOptimistic: isOptimistic || false,
  }),
);

export const removeItemFromBlendTemplateRequest = createAction(
  'REMOVE_ITEM_FROM_BLEND_TEMPLATE_REQUEST',
  itemId => ({ itemId }),
);
export const removeItemFromBlendTemplate = createAction(
  'REMOVE_ITEM_FROM_BLEND_TEMPLATE',
);

// Stores data required for optimistic loading and reverting on error
const optimisticBlendTemplateItemData = {};
export function updateBlendTemplateItem(
  templateId: number,
  itemId: number,
  quantity: number,
) {
  return (dispatch: Dispatch, getState: () => State) => {
    // Get the item and its original quantity
    const item = selectors.getBlendTemplateItemByIdSelector(getState(), itemId);
    const minimumQuantity = item.variant.minimumQuantity;

    // Clear debounce for current lineItem
    if (optimisticBlendTemplateItemData[item.id]) {
      clearTimeout(
        optimisticBlendTemplateItemData[item.id].debouncedIncreaseClick,
      );
    } else {
      // If we don't have optimisticBlendTemplateItemData for this line item create object
      optimisticBlendTemplateItemData[item.id] = {
        debouncedIncreaseClick: null,
        originalQuantity: null,
      };
    }
    dispatch(changeBlendTemplateItemRequest(item.id)); // Trigger loading state
    // Store away original quantity in case we get an error anywhere
    if (optimisticBlendTemplateItemData[item.id].originalQuantity == null) {
      optimisticBlendTemplateItemData[item.id].originalQuantity = item.quantity;
    }

    const nextQuantity = item.quantity + quantity;

    // Update reducer optimistically if we are not deleting
    if (nextQuantity >= minimumQuantity) {
      dispatch(
        changeBlendTemplateItem(
          item.id,
          nextQuantity,
          optimisticBlendTemplateItemData[item.id].originalQuantity,
          true,
        ),
      ); // True since this one is optimistic
    }
    // Set timeout to update via api
    optimisticBlendTemplateItemData[
      item.id
    ].debouncedIncreaseClick = setTimeout(async () => {
      try {
        const finalUpdateQuantity =
          nextQuantity < minimumQuantity ? minimumQuantity : nextQuantity;
        const response = await Blend.updateBlendTemplateItem(
          item.id,
          finalUpdateQuantity,
        );
        const nextLineItem =
          response.entities.blendTemplateItems[response.result];
        dispatch(
          changeBlendTemplateItem(
            nextLineItem.id,
            nextLineItem.quantity,
            optimisticBlendTemplateItemData[item.id].originalQuantity,
          ),
        );
      } catch (err) {
        // TODO: This error should set the product quantity back to the original. Implement in reducer
        logException(err, {
          originalQuantity:
            optimisticBlendTemplateItemData[item.id].originalQuantity,
        });
        dispatch(
          changeBlendTemplateItem(
            err,
            optimisticBlendTemplateItemData[item.id].originalQuantity,
          ),
        );
      } finally {
        optimisticBlendTemplateItemData[item.id].originalQuantity = null;
        // Sync the blend template
        const template = selectors.getBlendTemplateByBlendTemplateItemIdSelector(
          getState(),
          item.id,
        );
        dispatch(getBlendTemplate(template.id));
      }
    }, 700);
  };
}

// Delete Item from blend template
export function deleteBlendTemplateItem(templateId: number, itemId: number) {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(removeItemFromBlendTemplateRequest(itemId));
    try {
      await Blend.deleteBlendLineItem(itemId);
      dispatch(removeItemFromBlendTemplate({ itemId }));
      dispatch(getBlendTemplate(templateId));
    } catch (err) {
      logException(err);
      dispatch(removeItemFromBlendTemplate(err));
    }
  };
}

// Get lineItems
export const fetchItemsRequest = createAction('FETCH_ITEMS_REQUEST');
export const fetchItems = createAction('FETCH_ITEMS');

export function getLineItems(blendId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchItemsRequest());

      const response = await Blend.getBlendTemplateItems(blendId);
      dispatch(fetchItems({ ...response, blendId }));
    } catch (err) {
      logException(err);
      dispatch(fetchItems(err));
    }
  };
}
