// @flow
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UnauthenticatedRedirect, userAddressConnector } from '../../user';
// Components
import { Helmet } from 'react-helmet';
import { ColorRow } from '../../common';
import { Redirect } from 'react-router-dom';
import { PageLoader } from '../../common';
import CheckoutPageComponent from '../components/CheckoutPage';
import './CheckoutPage.css';

// Action creators
import {
  submitAddress,
  submitDelivery,
  submitPayment,
  submitConfirmation,
  submitPromotion,
  findCheckoutOrder,
  initiateCheckout,
  dismissErrors,
  findPromotionsByUser,
} from '../actions';
// Selectors
import {
  itemsSelector,
  itemCountSelector,
  itemSubtotalSelector,
  shippingSubtotalSelector,
  totalSelector,
  adjustmentTotalSelector,
  shippingRatesSelector,
  orderStateSelector,
  paymentMethodsSelector,
  creditCardsSelector,
  orderNumberSelector,
  currentOrderShippingAddressSelector,
  currentOrderDeliverySelector,
  currentOrderPaymentSelector,
  transitioningLoadingSelector,
  allPromotionsSelector,
  gettingPromotionsLoadingSelector,
  addressErrorsSelector,
  deliveryErrorsSelector,
  paymentErrorsSelector,
  promotionErrorsSelector,
  initiatingCheckoutLoadingSelector,
  gettingOrderLoadingSelector,
} from '../selectors';
//Connectors
import blendsListConnector from '../../blend/connectors/blendsListConnector';
// Types
import type { Connector } from 'react-redux';
import type { Props as ComponentProps } from '../components/CheckoutPage';
import type { UserAddress } from '../../user';
import type { ShippingRate } from '../types';

type Props = ComponentProps & {
  orderNumber: string,
  initialLoading: boolean,
  initiateCheckout: () => void,
  //Connector
  getBlends: orderNumber => void,
  blendsByOrderNumber: orderNumber => void,
  findPromotionsByUser: string => void,
};

type OwnProps = {
  location: Location,
};

class CheckoutPage extends Component {
  props: Props & OwnProps;

  constructor(props) {
    super(props);
    if (!props.orderNumber) {
      props.findCheckoutOrder();
    } else {
      props.initiateCheckout();
    }

    this.props.getBlends(this.props.orderNumber); //TODO: Only call this API is there are blends in the order
    if (props.userId) {
      props.findPromotionsByUser(props.userId);
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.orderNumber == null && this.props.orderNumber != null) {
  //     console.log('initiating checkout');
  //     this.props.initiateCheckout();
  //   }
  // }

  render() {
    if (this.props.initialLoading) {
      return (
        <div>
          <ColorRow color="red" text="CHECKOUT" />
          <PageLoader text="Preparando..." />
        </div>
      );
    }

    // TODO: Reimplement this protection in a way that does not redirect user to home page if he refreshes the page
    // if (!this.props.orderNumber) {
    //   return <Redirect to="/" />;
    // }

    if (this.props.orderState === 'complete') {
      return <Redirect to={`/confirmacao?id=${this.props.orderNumber}`} />;
    }

    return (
      <UnauthenticatedRedirect>
        <Helmet>
          <title>Checkout - Bigdelivery, Seu açougue virtual</title>
        </Helmet>
        <CheckoutPageComponent {...this.props} />
      </UnauthenticatedRedirect>
    );
  }
}

const mapStateToProps = (state, ownProps: {}) => {
  return {
    orderNumber: orderNumberSelector(state),
    // We should trigger this loading if we are either getting an order or transitioning it to checkout
    initialLoading:
      initiatingCheckoutLoadingSelector(state) ||
      gettingOrderLoadingSelector(state),
    items: itemsSelector(state),
    totalItemPrice: itemSubtotalSelector(state),
    shippingPrice: shippingSubtotalSelector(state),
    totalPrice: totalSelector(state), // Includes shipping cost
    adjustmentTotal: adjustmentTotalSelector(state),
    numberOfItems: itemCountSelector(state),
    shippingRates: shippingRatesSelector(state),
    orderState: orderStateSelector(state),
    paymentMethods: paymentMethodsSelector(state),
    creditCards: creditCardsSelector(state),
    orderShippingAddress: currentOrderShippingAddressSelector(state),
    orderDelivery: currentOrderDeliverySelector(state),
    orderPayment: currentOrderPaymentSelector(state),
    promotions: allPromotionsSelector(state),
    promotionsLoading: gettingPromotionsLoadingSelector(state),
    transitioningLoading: transitioningLoadingSelector(state),
    addressErrors: addressErrorsSelector(state),
    deliveryErrors: deliveryErrorsSelector(state),
    promotionErrors: promotionErrorsSelector(state),
    paymentErrors: paymentErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  findCheckoutOrder() {
    dispatch(findCheckoutOrder());
  },
  initiateCheckout() {
    dispatch(initiateCheckout());
  },
  submitAddress(address: UserAddress) {
    dispatch(submitAddress(address));
  },
  submitShippingRate(shippingRate: ShippingRate) {
    dispatch(submitDelivery(shippingRate));
  },
  submitPayment(paymentMethod, paymentSource, existingCard: boolean) {
    dispatch(submitPayment(paymentMethod, paymentSource, existingCard));
  },
  submitConfirmation() {
    dispatch(submitConfirmation());
  },
  submitPromotion(couponCode: string) {
    dispatch(submitPromotion(couponCode));
  },
  dismissErrors() {
    dispatch(dismissErrors());
  },
  findPromotionsByUser(userId: number) {
    dispatch(findPromotionsByUser(userId));
  },
});

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default withRouter(
  connector(userAddressConnector(blendsListConnector(CheckoutPage))),
);
