/** @flow
 * SHIPPING RATE PICKER
 *
 * This picker component takes ShippingRates as options and displays
 * them (including delivery window) so that the user can select one
 * of them. When an option is selected, the provided function is
 * called.
 */
import React, { Component } from 'react';
import './DeliveryPicker.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
// Types
import type { ShippingRate } from '../../../types';
import DeliveryPickerOption from './DeliveryPickerOption';

/**
 * PROPS
 */
type Props = {
  options: ShippingRate[],
  selected: ?ShippingRate,
  onSelect: ShippingRate => void,
};

// Slick Slider Arrows
class SampleDownArrow extends React.Component {
  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <div className="delivery-picker-arrow-container">
          <i
            className="fa fa-caret-down fa-3x delivery-picker-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

class SampleUpArrow extends React.Component {
  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        <div className="delivery-picker-arrow-container">
          <i
            className="fa fa-caret-up fa-3x delivery-picker-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

/**
 * COMPONENT DEFINITION
 */
class DeliveryPicker extends Component {
  props: Props;

  render() {
    // Note that Scroller must have an even number of items if scroll by 2 will behave properly
    const settings = {
      dots: false,
      infinite: false,
      accessibility: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
      arrows: true,
      swipe: true,
      vertical: true,
      variableWidth: false,
      nextArrow: <SampleDownArrow />,
      prevArrow: <SampleUpArrow />,
    };

    if (this.props.options.length > 0 && Array.isArray(this.props.options)) {
      return (
        <div className={`delivery-picker-main`}>
          <div className="delivery-picker-scroller">
            <Slider {...settings}>
              {this.props.options.map((option, i) => (
                <div key={i}>
                  <DeliveryPickerOption
                    id={i}
                    option={option}
                    isSelected={option === this.props.selected}
                    onSelect={() => this.props.onSelect(option)}
                    deliveriesRemaining={option.deliveriesRemaining}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      );
    }
  }
}

export default DeliveryPicker;
