import { authGet, authPut } from '../../api';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import * as schema from './schema';

const Product = {
  async get(slug: string | number) {
    const response = await authGet(`/api/v1/variants/${slug}`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.product);
    return normalized;
  },
  async putNotifyMe(productId: string | number) {
    const response = await authPut(`/api/v1/variants/${productId}/notify_me`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.product);
    return normalized;
  },
  async putUnnotifyMe(productId: string | number) {
    const response = await authPut(`/api/v1/variants/${productId}/unnotify_me`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.product);
    return normalized;
  },
  async getRecentlyViewedProductsRequest(slug: string | number) {
    const response = await authGet(`/v2/products/recently_viewed`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },

  // TODO: Ids are now hard coded. Implement appropriate path
  async getRelatedProductsRequest(slug: string | number) {
    const ids = [121, 122, 123, 124, 13, 14, 16, 17, 22, 23, 33, 34];
    const response = await authGet(`/v2/products?by_id=[${ids}]`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async getPromotionProductsRequest(page = 1, perPage = 10) {
    /*const ids = ;*/
    const response = await authGet(
      `/v2/products/?q[by_in_promotion]=true&page=${page}&per_page=${perPage}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async getProductsRecommendedForUserRequest(page = 1, perPage = 10) {
    const response = await authGet(
      `/v2/products/?q[discounted_eq]=true&page=${page}&per_page=${perPage}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async getMostSoldProductsRequest(page = 1, perPage = 20) {
    /*const ids = [129, 124, 128, 28, 20, 74, 51, 6];*/
    const response = await authGet(
      `/v2/products?q[s]=sales_volume+desc&page=${page}&per_page=${perPage}`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async getFrequentlyPurchasedTogetherProductsRequest(
    variantId,
    page = 0,
    perPage = 10,
  ) {
    const response = await authGet(
      `/v2/products/${variantId}/frequently_purchased_together`,
    );
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
  async byIds(ids: number[]) {
    const response = await authGet(`/v2/products?by_id=[${ids}]`);
    const data = await response.json().then(b => camelizeKeys(b));
    const normalized = normalize(data, schema.products);
    return normalized;
  },
};

export default Product;
