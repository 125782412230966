// flow
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import OrderTracker from '../../common/components/OrderTracker';
import { PageLoader } from '../../common';
// Selectors
import {
  ordersListSelector,
  gettingOrdersLoadingSelector,
} from '../../past-orders/selectors';
// Actions
import { getPastOrders } from '../../past-orders/actions';
// common
import { orderTrackerStateName } from '../../common/commonHelpers';
import './LastOrderInfo.css';

class LastOrderInfo extends React.Component {
  constructor(props: Props) {
    super(props);
    // Get past orders
    this.props.getPastOrders(1, 10);
    this.state = { order: null };
  }

  componentDidUpdate(prevProps, prevState) {
    const selectedOrder = _.first(
      _.filter(
        this.props.orders,
        o =>
          o.state === 'complete' &&
          (orderTrackerStateName(o.fulfillmentState, o.deliveryState) !==
            'erro' ||
            orderTrackerStateName(o.fulfillmentState, o.deliveryState) !==
              'canceled'),
      ),
    );
    if (
      selectedOrder &&
      (!prevState.order || prevState.order.id !== selectedOrder.id)
    ) {
      this.setState({ order: selectedOrder });
    }
  }
  render() {
    if (this.props.loading) return <PageLoader />;
    if (!this.state.order) return null;
    if (
      moment
        .duration(moment(moment.now()).diff(this.state.order.updatedAt))
        .asDays() > 2
    )
      return null;

    return (
      <>
        <div className="last-order-info-header">
          <hr className="scroller-small-line" />
          <h3>
            <Link
              to={`/minha-conta/pedidos/${this.state.order.number}`}
              className="scroller-link"
            >
              ÚLTIMO PEDIDO
            </Link>
          </h3>
          <hr className="scroller-medium-line" />
        </div>
        <div className="last-order-info-container">
          {this.state.order && (
            <OrderTracker order={this.state.order} loading={false} />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orders: ordersListSelector(state),
    loading: gettingOrdersLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getPastOrders: (page: number, perPage: number) => {
      dispatch(getPastOrders(page, perPage));
    },
  };
};
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(LastOrderInfo);
