// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
// Types

// Style
import './OrderTotal.css';

const formatAsCurrency = (value: number | string) => {
  return parseFloat(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

type Props = {
  lineItems: LineItem[],
  shipTotal: number,
  adjustmentTotal: string,
  fulfillmentState: string,
  producedAmount: string,
  itemTotal: number,
};

class OrderTotal extends Component {
  props: Props;
  render() {
    const lineItems = this.props.lineItems || [];

    const lineItemTotal =
      this.props.fulfillmentState !== 'ready'
        ? this.props.itemTotal
        : this.props.producedAmount;

    const finalOrderTotalBasedOnWeighedItems =
      parseFloat(lineItemTotal) +
      parseFloat(this.props.shipTotal) +
      parseFloat(this.props.adjustmentTotal);
    return (
      <div className="order-total-main">
        <hr className="order-total-line" />
        <div className="order-total-main-container">
          <div>
            <p>Total dos itens:</p>
            <p>Custo de entrega:</p>
            {(Number(this.props.adjustmentTotal) && <p>Descontos:</p>) || null}
            <p>{`Total${
              this.props.fulfillmentState !== 'ready' ? ' (estimado)' : ''
            }:`}</p>
          </div>

          <div>
            <h3>{formatAsCurrency(lineItemTotal)}</h3>
            <h3
              className={
                !Number(this.props.shipTotal) && 'order-total-green-text'
              }
            >
              {Number(this.props.shipTotal)
                ? formatAsCurrency(this.props.shipTotal)
                : 'Grátis'}
            </h3>
            {(Number(this.props.adjustmentTotal) && (
              <h3 className="order-total-green-text ">
                {formatAsCurrency(this.props.adjustmentTotal)}
              </h3>
            )) ||
              null}
            <h3 className="order-total-final-price">
              {formatAsCurrency(
                _.max([finalOrderTotalBasedOnWeighedItems, 0.0]),
              )}
            </h3>
          </div>
        </div>
        {this.props.fulfillmentState !== 'ready' && (
          <p className="order-total-alert-weigh">
            * Atualizaremos o total do seu pedido aqui assim que todos os itens
            forem pesados.
          </p>
        )}
      </div>
    );
  }
}

export default OrderTotal;
