// @flow
import React, { Component } from 'react';
import './MyAccountNavigator.css';

// Components
import { NavLink } from 'react-router-dom';

class MyAccountNavigator extends Component {
  render() {
    return (
      <div className="my-account-navigator-main">
        {/*<div className="my-account-mobile-navigator">
          Navegador
        </div>*/}

        <div className="my-account-navigator">
          <NavLink
            to="/minha-conta/informacoes"
            className="my-account-navigator-main-link"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            MEUS DADOS
          </NavLink>
          <NavLink
            to="/minha-conta/senha"
            className="my-account-navigator-main-link"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            ALTERAR SENHA
          </NavLink>
          <NavLink
            to="/minha-conta/enderecos"
            className="my-account-navigator-main-link"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            MEUS ENDEREÇOS
          </NavLink>
          <NavLink
            to="/minha-conta/pedidos"
            className="my-account-navigator-main-link"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            MEUS PEDIDOS
          </NavLink>
          <NavLink
            to="/minha-conta/cartoes"
            className="my-account-navigator-main-link"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            MEUS CARTÕES
          </NavLink>
          <NavLink
            to="/logout"
            className="my-account-navigator-main-link my-account-navigator-logout"
            activeClassName="my-account-navigator-main-link-active"
          >
            {' '}
            LOGOUT
          </NavLink>
        </div>
      </div>
    );
  }
}

export default MyAccountNavigator;
