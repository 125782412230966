// @flow
//import './SearchPage.css';
import React, { Component } from 'react';
import './NotFoundPage.css'
import chickenMagnify from '../images/chicken_magnify_glass.png'
import { logException } from '../../logHelper';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'



class NotFoundPage extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { location } = this.props;
    const error = { currentURL: location.pathname, statusCode: '404' }

    console.error(error);
    logException(error);
  }

  render() {
    return (
      <div className="not-found-main">
        <div className="not-found-content">
          <img src={chickenMagnify} alt="" className="not-found-image" />
          <p>Oops! Essa página não existe. </p>
        </div>
      </div>
    );
  }
}

export default withRouter(NotFoundPage);
