// @flow
import React from 'react';
import './Video.css';

type Props = {
  videoUrl: string,
};

class Video extends React.Component {
  props: Props;

  render() {
    return (
      <div className="video-player-container">
        <iframe
          title="Video"
          src={this.props.videoUrl}
          className="video"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    );
  }
}

export default Video;
