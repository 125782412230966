// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
import { Link } from 'react-router-dom';
// Types
import type { SubDepartment } from '../types';
// Styles
import './DepartmentHit.css';

type Props = {
  id: number,
  title: string,
  image: string,
  categories: SubDepartment[],
};

class DepartmentHit extends Component {
  props: Props;

  render() {
    const title = _.upperCase(this.props.title);
    return (
      <div className="department-hit-main">
        <Link to={`/departamentos/${this.props.id}/`}>
          <div className="department-hit-image-container">
            {this.props.image === null ? (
              <div className="department-placeholder" />
            ) : (
              <img
                className="department-hit-image"
                src={this.props.image}
                alt={`Departamento de ${_.lowerCase(this.props.title)}`}
              />
            )}
          </div>
        </Link>

        <div className="department-hit-text-group">
          <Link
            className="department-hit-title"
            to={`/departamentos/${this.props.id}/`}
          >
            <h3>{title} </h3>
          </Link>

          {this.props.categories.map((category, ind) => {
            if (ind < 4) {
              return (
                <Link
                  key={ind}
                  className="department-hit-entries"
                  to={`/departamentos/${this.props.id}/${category.slug}/`}
                >
                  {_.capitalize(category.name)}
                </Link>
              );
            } else {
              return <div key={ind} />;
            }
          })}

          {this.props.categories.length > 4 ? (
            <div className="deparment-hit-see-more">
              <Link to={`/departamentos/${this.props.id}/`}>
                {' '}
                Ver mais &gt;
              </Link>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

export default DepartmentHit;
