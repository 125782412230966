// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Analytics
import * as Analytics from '../../common/analytics';
//Composers
import { userLoggedInConnector } from '../../user';

import './NavList.css';

import type { Department } from '../types';

type Props = {
  departments: Department[],
};

class NavList extends Component {
  props: Props;

  render() {
    // We should only show up to 5 departments
    const departments = this.props.departments.slice(0, 5);
    return (
      <nav className="nav-list-main">
        <h3>
          <Link
            onClick={() => Analytics.logTappedNavList('departamentos')}
            to="/departamentos/"
          >
            DEPARTAMENTOS
          </Link>
        </h3>
        {departments.map((d, ind) => (
          <Link
            onClick={() => Analytics.logTappedNavList(d.name.toLowerCase())}
            to={`/departamentos/${d.id}/`}
            key={ind}
          >
            {d.name ? d.name.toUpperCase() : null}
          </Link>
        ))}
        <h3 className="nav-list-blend">
          {this.props.loggedIn ? (
            <Link
              onClick={() => Analytics.logTappedNavList('meus blends')}
              to="/blend/meus-blends/"
            >
              MEUS BLENDS
            </Link>
          ) : (
            <Link
              onClick={() => Analytics.logTappedNavList('blends')}
              to="/blend/"
            >
              BLENDS
            </Link>
          )}
        </h3>
        <h3 className="nav-list-barbecue-calculator">
          <Link
            onClick={() => Analytics.logTappedNavList('churrascometro')}
            to="/bigchurrasco/"
          >
            CHURRASCÔMETRO
          </Link>
        </h3>
        {/*TODO: Implement Recipes later <h3 className="nav-list-recipes"><Link to="/receitas">RECEITAS</Link></h3>*/}
      </nav>
    );
  }
}
export default userLoggedInConnector(NavList);
