// @flow
import React from 'react';

// Components
import Button from './Button';
import { Helmet } from 'react-helmet';

// Images
import FirstBoxImage from '../images/barbecue-landing-first-box-image.jpg';
import SecondBoxImage from '../images/barbecue-landing-second-box-image.jpg';

import './BarbecueCalculatorLanding.css';
const BarbecueCalculatorLanding = () => {
  return (
    <div className="barbecue-calculator-landing-container">
      <Helmet>
        <title>Churrascômetro - Bigdelivery - Seu açougue virtual</title>
        <meta
          property="og:title"
          content="Churrascômetro - Bigdelivery - Seu açougue virtual"
        />
        <meta
          name="description"
          content="O seu melhor churrasco começa aqui!"
        />
        <meta
          name="og:description"
          content="O seu melhor churrasco começa aqui!"
        />
      </Helmet>
      <div className="barbecue-calculator-landing-banner-background">
        <div className="barbecue-calculator-landing-banner-container">
          <div className="barbecue-calculator-landing-banner-content">
            <div className="barbecue-calculator-landing-banner-button-content">
              <Button color="orange" />
            </div>
          </div>
        </div>
      </div>

      <div className="barbecue-calculator-landing-header">
        <div className="phrase">Veja como é fácil se organizar conosco:</div>
      </div>

      <div className="barbecue-calculator-landing-first-box-content">
        <div className="barbecue-calculator-landing-first-box-text">
          <div className="barbecue-calculator-landing-first-box-title title">
            Quantas pessoas vão participar do churrasco?
          </div>
          <div className="barbecue-calculator-landing-first-box-subtitle subtitle">
            Para um encontro, entre amigos ou uma festa?
            <br />
            Para toda ocasião, temos um churrasco perfeito para você.
          </div>
        </div>

        <img
          className="barbecue-calculator-landing-first-box-image"
          src={FirstBoxImage}
          alt="churrasco-com-amigos"
        ></img>
      </div>

      <div className="barbecue-calculator-landing-second-box-content">
        <img
          className="barbecue-calculator-landing-second-box-image"
          src={SecondBoxImage}
          alt="churrasco"
        ></img>
        <div className="barbecue-calculator-landing-second-box-text">
          <div className="barbecue-calculator-landing-second-box-title title">
            Nós te ajudaremos a escolher as melhores carnes
          </div>
          <div className="barbecue-calculator-landing-second-box-subtitle subtitle">
            Nós sabemos que escolher as carnes certas em um churrasco pode ser
            difícil, então nos diga as suas preferências e nós escolheremos as
            melhores carnes para você!
          </div>
        </div>
      </div>

      <div className="barbecue-calculator-landing-button-box-content">
        <div className="barbecue-calculator-landing-button-box-text">
          <div className="barbecue-calculator-landing-button-box-title">
            E aí, vamos fazer um churrasco?
          </div>
        </div>
        <div className="barbecue-calculator-landing-button-box-button">
          <Button color="white" />
        </div>
      </div>
    </div>
  );
};

export default BarbecueCalculatorLanding;
