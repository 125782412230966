// @flow
import React, { useEffect, useState } from 'react';
//Components
import { Helmet } from 'react-helmet';
import { ColorRow } from '../../common';
import MainProductContent from './MainProductContent';
import ProductPromotionScroller from '../containers/scrollers/ProductPromotionScroller';
import ProductFrequentlyPurchasedTogetherScroller from '../containers/scrollers/ProductFrequentlyPurchasedTogetherScroller';
import MainProductDetails from './MainProductDetails';
// Analytics
import * as Analytics from '../../common/analytics';
// import RecipeDisplay from '../components/RecipeDisplay' // TODO: Recipes
import Recipe from '../../recipes/components/Recipe';
import PageLoader from '../../common/components/PageLoader';
import NoProduct from './NoProduct';
// Styles
import './ProductPage.css';

// Types
import type { Product } from '../types';

export type Props = {
  product: Product,
  loading: boolean,
  recipes: any,
};

export type State = {
  showFullRecipes: boolean,
};

const ProductPage = ({ match, product, recipes, loading }) => {
  const [showFullRecipes, setShowFullRecipes] = useState(false);

  // USE ANALYTICS
  const [lastProductId, setLastProductId] = useState(null);

  useEffect(() => {
    if (
      product &&
      product.id !== lastProductId &&
      product.slug === match.params.id
    ) {
      setLastProductId(product.id);
      Analytics.logProductView(product, window.location.pathname, 'visit');
    }
  }, [product]);
  // USE ANALYTICS

  // The loaders shows when the loading state is true and the product is not in the reducer
  if (!product && loading) {
    return (
      <div>
        <ColorRow color="grey" text={''} />
        <PageLoader />
      </div>
    );
  }

  // TODO: Add updating loader! So that the user knows that info on the page is still being updated.

  if (!product) return <NoProduct />; // TODO: Product does not exist page!

  return (
    <div className="product-page-main">
      {
        // <!-- Open Graph / Facebook -->
      }
      <Helmet>
        <title>{product.name} - Bigdelivery - Seu açougue virtual</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta property="og:description" content={product.description} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="Bigdelivery.com.br" />
        <meta property="keywords" content={product.metaTags} />
        <meta
          property="og:availability"
          content={`${product.inStock ? 'in stock' : 'out of stock'}`}
        />
      </Helmet>
      <Helmet>
        {
          // <!-- Primary Meta Tags -->
        }
        <meta
          name="title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta name="description" content={product.description} />
      </Helmet>
      <Helmet>
        {
          // <!-- Twitter -->
        }
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta
          property="twitter:title"
          content={`${product.name} - Bigdelivery - Seu açougue virtual`}
        />
        <meta property="twitter:description" content={product.description} />
      </Helmet>
      {
        // <!-- Images related -->
      }
      {product.images.length > 0 && (
        <Helmet>
          <meta property="og:image" content={product.images[0].largeUrl} />
          <meta property="twitter:image" content={product.images[0].largeUrl} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="340" />
          <meta property="og:image:alt" content={product.images[0].alt} />
        </Helmet>
      )}
      <ColorRow color="grey" text={product.name} />
      <MainProductContent product={product} />
      <MainProductDetails product={product} />
      {(recipes && recipes.length && (
        <>
          <div
            className={`${!showFullRecipes ? 'product-page-recipe-main' : ''}`}
          >
            <div className="product-page-recipe-header">
              <hr className="product-page-recipe-small-line" />
              <h1>DICA DE RECEITA</h1>
              <hr className="product-page-recipe-medium-line" />
            </div>
            {recipes.map((recipe, key) => (
              <Recipe key={key} recipe={recipe} />
            ))}
          </div>
          <div className="product-page-recipe-main-fadeout" />
          <div className="product-page-recipe-button-see-more ">
            <button
              className="button-show-full-recipes"
              onClick={() => setShowFullRecipes(!showFullRecipes)}
            >
              {showFullRecipes ? 'Ver mais ▾' : 'Ver menos ▴'}
            </button>
          </div>
        </>
      )) ||
        null}
      <ProductFrequentlyPurchasedTogetherScroller variantId={product.id} />
      <ProductPromotionScroller />
    </div>
  );
};

export default ProductPage;
