// @flow
import React from 'react';
// Components
import Login from '../components/Login';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Composers
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Import Actions
import { login, register } from '../actions';
// Selectors
import {
  loggedInSelector,
  loggingInLoadingSelector,
  registeringLoadingSelector,
  loginErrorsSelector,
  registrationErrorsSelector,
} from '../selectors';
// Types
import type { ContextRouter } from 'react-router-dom';

type RouterProps = ContextRouter;
type Props = {
  loggedIn: boolean,
  isLoggingIn: boolean,
  isRegistering: boolean,
  loginErrors: {
    badUsername: boolean,
    badPassword: boolean,
  },
  registrationErrors: {},
  submitLogin: () => void,
  submitRegister: () => void,
} & RouterProps;

class LoginPage extends React.Component {
  props: Props;
  render() {
    if (this.props.loggedIn) {
      const nextRoute =
        this.props.location.state && this.props.location.state.to
          ? this.props.location.state.to
          : '/';
      return <Redirect to={nextRoute} />;
    }
    return (
      <div>
        <Helmet>
          <title>Login e Cadastro - Bigdelivery - Seu açougue virtual</title>
          <meta
            property="og:title"
            content="Login e Cadastro - Bigdelivery - Seu açougue virtual"
          />
          <meta
            name="description"
            content="Crie sua conta ou faça login aqui."
          />
          <meta
            name="og:description"
            content="Crie sua conta ou faça login aqui."
          />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/image_logo.png`}
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="900" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>
        <Login
          alertText={
            this.props.location.state ? this.props.location.state.message : ''
          }
          submitLogin={this.props.submitLogin}
          submitRegister={this.props.submitRegister}
          isLoggingIn={this.props.isLoggingIn}
          isRegistering={this.props.isRegistering}
          loginErrors={this.props.loginErrors}
          registrationErrors={this.props.registrationErrors}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: loggedInSelector(state),
    isLoggingIn: loggingInLoadingSelector(state),
    isRegistering: registeringLoadingSelector(state),
    loginErrors: loginErrorsSelector(state),
    registrationErrors: registrationErrorsSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, stateProps) => {
  return {
    submitLogin: (username, password, rememberMe) => {
      dispatch(login(username, password, rememberMe));
    },
    submitRegister: (
      firstName,
      lastName,
      cpf,
      email,
      password,
      passwordConfirmation,
      recaptchaToken,
    ) => {
      dispatch(
        register(
          firstName,
          lastName,
          cpf,
          email,
          password,
          passwordConfirmation,
          recaptchaToken,
        ),
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage),
);
