// Other
import { phone } from '../../common';

const faq = [
  {
    question: 'Qual a conexão com o Frigorífico do Mané?',
    answer:
      'O Frigorífico do Mané é uma rede com mais de 25 casas de carnes que atende a clientes em Salvador, Alagoinhas e outras cidades. Nós somos parceiros deles, permitindo que você desfrute do mesmo padrão de excelência do conforto de sua casa.',
  },
  {
    question: 'Vocês aceitam cartão de débito?',
    answer: `No momento aceitamos débito apenas pagando na maquineta no momento da entrega. Caso queira fazer o pagamento dessa forma, conclua o seu pedido pela central de vendas, através do telefone ${phone.asStringFull}.`,
  },
  {
    question: 'Vocês aceitam voucher?',
    answer: `Aceitamos os vouchers Sodexo e Alelo como pagamento no momento da entrega. Caso queira fazer o pagamento dessa forma, conclua o seu pedido pela central de vendas, através do telefone ${phone.asStringFull}.`,
  },
  {
    question: 'Vocês aceitam devoluções?',
    answer:
      'Sim! Se tiver algum produto fora do padrão, errado, ou diferente do pedido, basta nos ligar que marcaremos a data para o nosso entregador buscar/trocar o produto.',
  },
  {
    question: 'Vocês aceitam quais formas de pagamento? Fazem parcelamento?',
    answer:
      'Para garantir a segurança de nossos motoristas, não trabalhamos com dinheiro. Em pagamentos online aceitamos cartões de crédito, entre eles Master, Visa, Hiper e American Express. Para pagamentos no momento da entrega, aceitamos cartões de débito e os vouchers Sodexo e Alelo. No momento, não fazemos compras parceladas.',
  },
  {
    question:
      'Não entendi como funciona o pagamento em quilo. Os pesos são exatos?',
    answer:
      'Faremos o possível para que o peso entregue seja igual ao que você pediu. Porém, variações na carne e cortes podem ocasionar em pesos diferentes do selecionado. Caso isso aconteça, você pagará o preço de acordo com peso da mercadoria que você vai receber, e não pelo valor estimado. Ex: Se o pedido foi de 1,5 Kg mas pesamos 1,35 Kg, cobraremos apenas pelos 1,35 Kg. Para compras online, você sempre irá pagar um valor igual ou menor ao mostrado no site. ',
  },
  {
    question: 'Como funciona o pagamento online?',
    answer:
      'Quando você completar a compra será gerada uma "autorização" no seu cartão no valor do seu pedido. Uma vez que a gente processe os seus produtos iremos "capturar" o valor exato que será sempre igual ou menor que o valor autorizado. Basicamente, você paga apenas pelo que você recebe e nunca a mais do que o que lhe mostramos. Note que seu banco pode lhe enviar um SMS com o valor autorizado mas o valor na fatura poderá ser menor.',
  },
  {
    question:
      'Fiz um pedido, gostaria de saber se posso incluir mais produtos ou mudar a data de entrega.',
    answer: `Com certeza! Entre em contato com nossa central de vendas no telefone ${phone.asStringFull} ou pelo chat e faremos as alterações.`,
  },
  {
    question: 'Pode dividir o mesmo produto em vários sacos?',
    answer:
      'Sim! Por padrão dividimos qualquer compra grande em embalagens menores para ficarem mais práticas para o cliente. Ex: Se você pedir 10Kg de carne moída, geralmente enviaremos 10 pacotes de 1Kg.',
  },
  {
    question: 'Quais são os horários de entrega e como funcionam?',
    answer:
      'A disponibilidade dos nossos horários depende da data e destino. Funcionamos de segunda-feira a sábado. Nas segundas, temos 3 horários para agendamento: manhã (8h-13h), tarde (13h-17h) e tarde (15h-20h), enquanto de terça a sábado entregamos em quatro horários: manhã (8h-13h), tarde (13h-17h), tarde (15h-20h) e noite (17-21h) . Você pode agendar para amanhã até 23:59 de hoje. Para entrega expressa o pedido chega em até 3 horas.',
  },
  {
    question: 'Em quais locais vocês entregam?',
    answer:
      'Efetuamos entregas nas cidades de Salvador, Lauro de Freitas e Vila de Abrantes.',
  },
  {
    question: 'Tem alguma promoção? Estou achando produto X muito caro.',
    answer:
      'Sim! Mantemos uma lista de produtos em promoção na nossa página principal. Também buscamos trabalhar com preços bastante competitivos em todo o nosso site. Se estiver achando algum produto caro, nos avise que nossa diretoria irá revisar! ',
  },
  {
    question: 'Como faço para vender produtos através de seu site?',
    answer:
      'Estamos sempre aumentando a nossa oferta de produtos. Se você é fornecedor ou produtor, entre em contato através do email compras@bigdelivery.com.br para iniciarmos essa parceria.',
  },
  {
    question: 'Porque pede o CPF para se cadastrar no site?',
    answer:
      'Por obrigações legais todos os nossos pedidos geram notas fiscais de venda. Sendo que emitimos notas fiscais (NFe) para translado de mercadoria, não temos a opção de emitir sem CPF.',
  },
  {
    question: 'Vocês vendem para empresas?',
    answer:
      'Sim! Durante o processo de checkout, ao preencher o endereço de entrega, terá uma caixinha para selecionar caso a venda seja para empresa. Ao selecionar, você poderá colocar o CNPJ e razão social para emissão da nota fiscal.',
  },
];

export default faq;
