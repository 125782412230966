// @flow
import Raven from 'raven-js';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRavenMiddleware from 'raven-for-redux';
import { locationChangeListener } from './history';
// Google Tag Manager Related
import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { eventsMapper } from './common/middleware/googleTagManagerEventsMap';

Raven.config('https://2fb4535076f74ee99d454f4d852ae14c@sentry.io/175778', {
  release: process.env.REACT_APP_BUILD_COMMIT,
  environment: process.env.NODE_ENV, // TODO: NODE_ENV is harcodede as production for the staging environment as well. Use a different env that can differentiate between production and staging.
}).install();

export default function configureStore(preloadedState) {
  const gtm = GoogleTagManager();

  // Create middleware that is always used
  let middleware = [
    thunk,
    createRavenMiddleware(Raven, {
      stateTransformer: state => {
        // Since the entire state can become significantly larger than Sentry's 200 KB limit we will filter only to the current order and user data.
        // This is required to avoid 413 when sending data to Sentry.
        const { order, user } = { ...state };
        return { order, user };
      },
    }),
    createMiddleware(eventsMapper, gtm),
  ];

  // Add middleware that should only be available during development
  if (process.env.NODE_ENV !== 'production') {
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger();
    middleware = [...middleware, logger];
  }

  // Create store
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );

  locationChangeListener(store);

  return store;
}
