// @flow
import React, { Component } from 'react';
import './PaymentSummary.css';
// Types

type Props = {
  payment: any,
};

class PaymentSummary extends Component {
  props: Props;

  render() {
    return (
      <div className="payment-summary-main">
        <p>{this.props.payment.paymentMethod.name}</p>
      </div>
    );
  }
}
export default PaymentSummary;
