// @flow
import React, { Component } from 'react';
// Components
import { Video } from '../../common';

import './Recipe.css';

export type Props = {
  recipe: any,
};

const Recipe = (props: Props) => (
  <div className="recipe-main">
    <div className="recipe-body-main-content">
      <Video videoUrl={props.recipe.vimeoUrl || props.recipe.videoUrl} />
      <h2 className="recipe-header-title">{props.recipe.title}</h2>
      <div className="recipe-body-main-content-recipe-steps">
        {props.recipe.text.split('\n').map((str, key) => (
          <span key={key}>
            {str}
            <br />
          </span>
        ))}
      </div>
    </div>
  </div>
);

export default Recipe;
