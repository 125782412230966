// @flow
import React from 'react';
import _ from 'lodash';
import { validationHelpers } from '../../common';
// Components
import {
  Button,
  InputFieldErrorMessage,
  Checkbox,
  InputWithValidation,
} from '../../common';
import { Link } from 'react-router-dom';
import Reaptcha from 'reaptcha';
// Styles
import './RegisterBox.css';
// Logger
// import { logException } from '../../logHelper';

type State = {
  firstName: string,
  lastName: string,
  cpf: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  acceptedTerms: string,

  passwordStrength: string,

  firstNameError: string,
  lastNameError: string,
  cpfError: string,
  emailError: string,
  passwordError: string,
  passwordConfirmationError: string,
  acceptedTermsError: string,
};

/*
    Returns the "Password String Text and Color" based on the value of passwordStrength
    Price:
        PasswordStrenth 'Forte': Shows PasswordStrenth in Green
        PasswordStrenth 'Médio': Shows PasswordStrenth in Orange
        PasswordStrenth 'Fraca': Shows PasswordStrenth in Red
        All else: Shows no text
    Also places the correct class for styling
*/
const PasswordStrengthTextAndColor = ({ passwordStrength }) => {
  if (passwordStrength === 'strong') {
    return (
      <span className="password-strength-text">
        Força da senha:{' '}
        <span className="password-strength-strong"> Forte </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'average') {
    return (
      <span className="password-strength-text">
        Força da senha:{' '}
        <span className="password-strength-medium"> Média </span>{' '}
      </span>
    );
  } else if (passwordStrength === 'weak') {
    return (
      <span className="password-strength-text">
        Força da senha: <span className="password-strength-poor"> Fraca </span>{' '}
      </span>
    );
  } else {
    return <span> </span>;
  }
};

type Props = {
  isLoading: boolean,
  onSubmitRegister: (
    firstName: string,
    lastName: string,
    cpf: string,
    email: string,
    password: string,
    confirmationPassword: string,
  ) => void,
  errors: { base?: string[] },
};

class RegisterBox extends React.Component {
  state: State;
  validateUserInput: () => void;

  constructor(props: Props) {
    super(props);

    this.captcha = null;
    this.calculatePasswordStrength.bind(this);

    this.state = {
      firstName: '',
      lastName: '',
      cpf: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      acceptedTerms: '',

      passwordStrength: '',

      firstNameError: '',
      lastNameError: '',
      cpfError: '',
      emailError: '',
      passwordError: '',
      passwordConfirmationError: '',
      acceptedTermsError: '',
    };
  }

  validationErrorExists() {
    return (
      !!this.state.firstNameError ||
      !!this.state.lastNameError ||
      !!this.state.cpfError ||
      !!this.state.emailError ||
      !!this.state.passwordError ||
      !!this.state.passwordConfirmationError
    );
  }

  // Sets this.state.passwordStrength based on the characteristics of the password
  calculatePasswordStrength(password: string) {
    if (password === '') this.setState({ passwordStrength: '' });
    else if (password.length < 8) this.setState({ passwordStrength: 'weak' });
    else if (/^[a-zA-Z0-9- ]*$/.test(password) === false)
      this.setState({ passwordStrength: 'strong' });
    else if (password.length > 15)
      this.setState({ passwordStrength: 'strong' });
    else this.setState({ passwordStrength: 'average' });
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput() {
    let noValidationErrors = !this.validationErrorExists();

    const acceptedTermsError = validationHelpers.user.acceptedTerms(
      this.state.acceptedTerms,
    );
    this.setState({ acceptedTermsError });
    if (acceptedTermsError) {
      noValidationErrors = false;
    }

    if (noValidationErrors) {
      this.captcha.execute();
    } else {
      console.error('Validation error on registration form.');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.isLoading &&
      !nextProps.isLoading &&
      !_.isEmpty(nextProps.errors)
    ) {
      if (nextProps.errors.base) {
        this.setState({ emailError: nextProps.errors.base[0] });
      }
    }
  }

  onVerify = recaptchaToken => {
    this.props.onSubmitRegister(
      this.state.firstName,
      this.state.lastName,
      this.state.cpf,
      this.state.email,
      this.state.password,
      this.state.passwordConfirmation,
      recaptchaToken,
    );
    this.captcha.reset();
  };

  render() {
    return (
      <div className="register-box-main">
        <h3>Quero me Cadastrar!</h3>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.validateUserInput();
          }}
        >
          <InputWithValidation
            className={`register-box-input ${
              (this.state.firstNameError && 'register-box-input-error') || ''
            }`}
            type="text"
            placeholder="Nome"
            value={this.state.firstName}
            onChange={e => this.setState({ firstName: e.target.value })}
            validationError={this.state.firstNameError}
            onFocus={e => this.setState({ firstName: e.target.value })}
            onBlur={() => {
              this.setState({
                firstNameError: validationHelpers.user.firstName(
                  this.state.firstName,
                ),
              });
            }}
          />
          <InputWithValidation
            className={`register-box-input ${
              (this.state.lastNameError && 'register-box-input-error') || ''
            }`}
            type="text"
            placeholder="Sobrenome"
            value={this.state.lastName}
            onChange={e => this.setState({ lastName: e.target.value })}
            validationError={this.state.lastNameError}
            onFocus={e => this.setState({ lastName: e.target.value })}
            onBlur={() => {
              this.setState({
                lastNameError: validationHelpers.user.lastName(
                  this.state.lastName,
                ),
              });
            }}
          />
          <InputWithValidation
            className={`register-box-input ${
              (this.state.cpfError && 'register-box-input-error') || ''
            }`}
            type="text"
            placeholder="CPF (apenas números)"
            value={this.state.cpf}
            onChange={e => this.setState({ cpf: e.target.value })}
            validationError={this.state.cpfError}
            onFocus={e => this.setState({ cpf: e.target.value })}
            onBlur={() => {
              this.setState({
                cpfError: validationHelpers.user.cpf(this.state.cpf),
              });
            }}
          />

          <InputWithValidation
            className={`register-box-input ${
              (this.state.emailError && 'register-box-input-error') || ''
            }`}
            type="text"
            placeholder="Email"
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
            validationError={this.state.emailError}
            onFocus={e => this.setState({ email: e.target.value })}
            onBlur={() => {
              this.setState({
                emailError: validationHelpers.user.email(this.state.email),
              });
            }}
          />

          {this.state.passwordStrength !== '' ? (
            <PasswordStrengthTextAndColor
              passwordStrength={this.state.passwordStrength}
            />
          ) : null}

          <InputWithValidation
            className={`register-box-input ${
              (this.state.passwordError && 'register-box-input-error') || ''
            }`}
            type="password"
            placeholder="Senha"
            value={this.state.password}
            onChange={e => {
              this.setState({ password: e.target.value });
              this.calculatePasswordStrength(e.target.value);
            }}
            validationError={this.state.passwordError}
            onFocus={e => this.setState({ password: e.target.value })}
            onBlur={() => {
              this.setState({
                passwordError: validationHelpers.user.password(
                  this.state.password,
                ),
              });
            }}
          />
          <InputWithValidation
            className={`register-box-input ${
              (this.state.passwordConfirmationError &&
                'register-box-input-error') ||
              ''
            }`}
            type="password"
            placeholder="Confirme sua senha"
            value={this.state.passwordConfirmation}
            onChange={e =>
              this.setState({ passwordConfirmation: e.target.value })
            }
            validationError={this.state.passwordConfirmationError}
            onFocus={e =>
              this.setState({ passwordConfirmation: e.target.value })
            }
            onBlur={() => {
              this.setState({
                passwordConfirmationError: validationHelpers.user.passwordConfirmation(
                  this.state.passwordConfirmation,
                  this.state.password,
                ),
              });
            }}
          />

          <InputFieldErrorMessage message={this.state.acceptedTermsError} />
          <div className="register-box-terms-of-use">
            <Checkbox
              className="register-box-terms-of-use"
              value={this.state.acceptedTerms}
              onChange={e => this.setState({ acceptedTerms: e.target.checked })}
              label={
                <span>
                  Li e aceito os <Link to="/termos/">termos de uso</Link> e as{' '}
                  <Link to="/termos/">políticas de privacidade</Link>
                </span>
              }
            />
          </div>

          {/* No need to render the reaptcha unless the user is attempting to register. */}
          {this.state.firstName && (
            <Reaptcha
              ref={e => (this.captcha = e)}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onVerify={this.onVerify}
              size="invisible"
              badge="bottomleft"
              hl="pt-BR"
            />
          )}

          <Button
            type="submit"
            text="CADASTRAR"
            icon=""
            disabled={
              !this.state.firstName ||
              !this.state.lastName ||
              !this.state.cpf ||
              !this.state.email ||
              !this.state.password ||
              !this.state.passwordConfirmation ||
              this.validationErrorExists()
            }
            buttonType="button-primary"
            loading={this.props.isLoading}
          />
        </form>
      </div>
    );
  }
}

export default RegisterBox;
