// @flow

// import HomePage from './HomePage';
import { Home } from './home';
import { BarbecueCalculator, BarbecueCalculatorLanding } from './barbecue';
import { CartPage, CheckoutPage, ConfirmationPage } from './current-order';
import { LoginPage, LogoutPage } from './user';
import { ProductPage } from './products';
import { SearchPage } from './search';
// import RecipePage from './RecipePage';
import {
  MyAccountInformationPage,
  MyAccountOrdersPage,
  MyAccountOrderPage,
  MyAccountAddressesPage,
  MyAccountPasswordPage,
  PasswordRecoveryPage,
  ForgotPasswordPage,
  MyAccountPaymentsPage,
} from './user';
import { DepartmentPage, DepartmentsIndexPage } from './departments';
import { BlendLadingPage, BlendCalculatorPage, BlendsIndexPage } from './blend';
import {
  AboutUsPage,
  TermsOfUsagePage,
  WorkWithUsPage,
  ContactUs,
  FaqPage,
} from './static';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/bigchurrasco',
    component: BarbecueCalculatorLanding,
    exact: true,
  },
  {
    path: '/bigchurrasco/meuchurrasco',
    component: BarbecueCalculator,
  },
  {
    path: '/carrinho',
    component: CartPage,
  },
  {
    path: '/produtos/:id',
    component: ProductPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/logout',
    component: LogoutPage,
  },
  {
    path: '/esqueci-senha',
    component: ForgotPasswordPage,
  },
  {
    path: '/resetar-senha',
    component: PasswordRecoveryPage,
  },
  {
    path: '/checkout',
    component: CheckoutPage,
  },
  {
    path: '/busca',
    component: SearchPage,
  },
  // {
  //   path: '/receitas/:id',
  //   component: RecipePage,
  //   navigate: actions.routerNavigatedToRecipePage,
  // },
  {
    path: '/minha-conta/informacoes',
    component: MyAccountInformationPage,
  },
  {
    path: '/minha-conta/pedidos/:orderNumber',
    component: MyAccountOrderPage,
  },
  {
    path: '/minha-conta/pedidos',
    component: MyAccountOrdersPage,
  },
  {
    path: '/minha-conta/enderecos',
    component: MyAccountAddressesPage,
  },
  {
    path: '/minha-conta/senha',
    component: MyAccountPasswordPage,
  },
  {
    path: '/minha-conta/cartoes',
    component: MyAccountPaymentsPage,
  },
  {
    path: '/departamentos',
    component: DepartmentsIndexPage,
    exact: true,
  },
  {
    path: '/departamentos/:id/:subdepartment?',
    component: DepartmentPage,
  },
  {
    path: '/confirmacao/:id?',
    component: ConfirmationPage,
  },
  // Blend pages
  {
    path: '/blend',
    component: BlendLadingPage,
    exact: true,
  },
  {
    path: '/blend/calculadora/:id',
    component: BlendCalculatorPage,
  },
  {
    path: '/blend/meus-blends',
    component: BlendsIndexPage,
  },
  // Static pages
  {
    path: '/sobre',
    component: AboutUsPage,
  },
  {
    path: '/trabalhe',
    component: WorkWithUsPage,
  },
  {
    path: '/contato',
    component: ContactUs,
  },
  {
    path: '/termos',
    component: TermsOfUsagePage,
  },
  {
    path: '/perguntas',
    component: FaqPage,
  },
];

export default routes;
