import React, { Component } from 'react';
import history from './history';
import routes from './routes';
// Components
import { Router, Switch, Route } from 'react-router-dom';
import { Header, SimpleHeader } from './header';
import { Footer } from './footer';
import { UserValidate } from './user';
import { ScrollToTop, NotFoundPage, Alert } from './common';
import { TopLevelErrorBoundary } from './common';
import WhatsappFloatButton from './WhatsappFloatButton';
// Connectors
// Styles
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'instantsearch.css/themes/reset.css';

class App extends Component {
  render() {
    return (
      <TopLevelErrorBoundary>
        <div>
          <Router history={history}>
            <ScrollToTop>
              <div id="app">
                <UserValidate />
                <Alert />
                <WhatsappFloatButton />

                <header>
                  <Switch>
                    <Route path="/checkout" component={SimpleHeader} />
                    <Route path="/login" component={SimpleHeader} />
                    <Route path="/cadastro" component={SimpleHeader} />
                    <Route path="/registro" component={SimpleHeader} />
                    <Route path="/esqueci-senha" component={SimpleHeader} />
                    <Route path="/resetar-senha" component={SimpleHeader} />
                    <Route path="/confirmacao" component={SimpleHeader} />
                    <Route component={Header} />
                  </Switch>
                </header>
                <main>
                  <Switch>
                    {routes.map((route, i) => {
                      const { component, ...options } = route;
                      return (
                        <Route key={i} component={component} {...options} />
                      );
                    })}
                    <Route component={NotFoundPage} />
                  </Switch>
                </main>
                <footer>
                  <Switch>
                    <Route component={Footer} />
                  </Switch>
                </footer>
              </div>
            </ScrollToTop>
          </Router>
        </div>
      </TopLevelErrorBoundary>
    );
  }
}

export default App;
