// @flow
import './SearchPage.css';
import React, { Component } from 'react';
// Components
import {
  Configure,
  Pagination,
  Hits,
  Stats,
  connectStateResults,
} from 'react-instantsearch-dom';
import SearchNoResult from './SearchNoResult';
// import PriceSlider from './PriceSlider';
import SearchResultHorizontal from './SearchResultHorizontal';
import { ColorRow, PageLoader } from '../../common';
import ViewLine from './ViewLine';
import RefinementMenu from './RefinementMenu';

class SearchPage extends Component {
  render() {
    // Get a substring of the query so that we can display it
    let query = '';
    if (this.props.query !== undefined) {
      query = this.props.query;
      if (this.props.query.length > 15) {
        query = this.props.query.substring(0, 15) + '...';
      }
    }

    // Determine if we have search results
    const noResults = this.props.searchResults
      ? this.props.searchResults.nbHits === 0
      : false;

    return (
      <div className="search-page-main">
        <ColorRow color="grey" text={`RESULTADOS DA PESQUISA`} />

        <Configure query={this.props.query} />

        {/*TODO: LOADING STATE*/}
        {this.props.searching ? <PageLoader text="Pesquisando..." /> : null}

        <div
          className={`search-page-container ${
            this.props.searching ? 'search-page-hidden' : ''
          }`}
        >
          <RefinementMenu />
          <div className="search-page-hits">
            <div className="search-page-hit-data">
              Resultados para "{query}"{' '}
              <span className="pull-right">
                <Stats
                  translate={(name, n, ms) =>
                    n + ' resultados em ' + ms + ' ms'
                  }
                />
              </span>
            </div>
            <ViewLine />

            <div>
              {noResults ? <SearchNoResult /> : null}
              <Hits hitComponent={SearchResultHorizontal} />
            </div>

            <Pagination showLast={true} />
          </div>
        </div>
      </div>
    );
  }
}

export default connectStateResults(SearchPage);
