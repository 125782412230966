// @flow
import React from 'react';
// Components
import { SearchBar } from '../../search';
import { CurrentUserIndicator } from '../../user';
import { CartIndicator } from '../../current-order';
// Styles
import './TopRow.css';

const TopRow = () => {
  return (
    <div className="toprow-main">
      <SearchBar />
      <CurrentUserIndicator />
      <CartIndicator />
    </div>
  );
};

export default TopRow;
