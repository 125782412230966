// @flow
import React, { Component } from 'react';
import { CNPJ } from 'cpf_cnpj';
import _ from 'lodash';
// Helpers
import { truncateString, GoogleStaticMapsImage } from '../../../common';
// Components
// Images
//Types
import type { UserAddress } from '../../types';
//Styles
import './MyAccountAddressItem.css';

type Props = {
  address: UserAddress,
  isSelected?: boolean,
  deletingLoading: boolean,
  onClickSelect?: () => void,
  onClickEdit: () => void,
  onClickDelete: () => void,
};

class MyAccountAddressItem extends Component {
  props: Props;

  render() {
    const name = truncateString(
      _.upperCase(this.props.address.receiverName),
      29,
    );
    const company = truncateString(
      _.upperCase(this.props.address.company || ''),
      29,
    );
    const addressLine1 = truncateString(
      _.upperCase(this.props.address.addressLine1 || ''),
      29,
    );
    const addressLine2 = truncateString(
      _.upperCase(this.props.address.addressLine2 || ''),
      29,
    );

    return (
      <div
        className={`my-account-address-item-main ${
          this.props.isSelected ? 'address-selected' : ''
        } ${' '}
         ${
           this.props.onClickSelect ? 'my-account-address-item-main-hover' : ''
         }`}
        onClick={() =>
          this.props.onClickSelect
            ? this.props.onClickSelect()
            : console.log('Action no permitted!')
        }
      >
        <div className="my-account-image-container">
          <GoogleStaticMapsImage
            className={
              this.props.address.cnpj
                ? this.props.address.ieCompany
                  ? 'my-account-address-item-company-image-with-ie'
                  : 'my-account-address-item-company-image'
                : 'my-account-address-item-image'
            }
            size={
              this.props.address.cnpj
                ? this.props.address.ieCompany
                  ? '90x142'
                  : '90x130'
                : '90x90'
            }
            address={this.props.address}
            zoom={14}
          />
        </div>
        <div className="my-account-address-item-information">
          <p>{company}</p>
          <p>{name}</p>
          <p>{addressLine1}</p>
          <p>{addressLine2}</p>
          <p>
            {_.upperCase(this.props.address.city)}{' '}
            {_.upperCase(this.props.address.neighborhood)}{' '}
            {_.upperCase(this.props.address.zipcode)}
          </p>
          {this.props.address.cnpj ? (
            <p>CNPJ: {CNPJ.format(this.props.address.cnpj)}</p>
          ) : null}
          {this.props.address.ieCompany ? (
            <p>
              IE:{' '}
              {this.props.address.ieCompany.slice(
                0,
                this.props.address.ieCompany.length - 2,
              ) +
                '-' +
                this.props.address.ieCompany.slice(-2)}
            </p>
          ) : null}
        </div>

        <i
          className="fa fa-pencil my-account-address-item-pencil"
          aria-hidden="true"
          onClick={e => {
            e.stopPropagation();
            this.props.onClickEdit();
          }}
        />
        {!this.props.deletingLoading ? (
          <i
            className="fa fa-trash-o my-account-address-item-trash"
            aria-hidden="true"
            onClick={e => {
              e.stopPropagation();
              this.props.onClickDelete();
            }}
          />
        ) : (
          <i className="fa fa-spinner fa-pulse fa-2x" aria-hidden="true" />
        )}
      </div>
    );
  }
}
export default MyAccountAddressItem;
