// @flow
import React, { Component } from 'react';
// Components
import { Link } from 'react-router-dom';
import WhatsappButton from '../../common/components/WhatsappButton';
// Style
import TelephoneIcon from '../images/telephone.png';
import './HamburgerMenu.css';
// Composers
import { userLoggedInConnector } from '../../user';
// Other
import { phone } from '../../common';

class HamburgerMenu extends Component {
  render() {
    return (
      <div className="hamburger-menu-main">
        <Link to="/">HOME</Link>
        <Link to="/departamentos/">DEPARTAMENTOS</Link>
        <Link to="/minha-conta/informacoes/">MINHA CONTA</Link>
        {this.props.loggedIn ? (
          <Link to="/blend/meus-blends/">MEUS BLENDS</Link>
        ) : (
          <Link to="/blend/">BLENDS</Link>
        )}
        <Link to="/bigchurrasco">CHURRASCÔMETRO</Link>
        <Link to="/sobre/">SOBRE NÓS</Link>
        <Link to="/trabalhe/">TRABALHE CONOSCO</Link>
        <Link to="/perguntas/">PERGUNTAS FREQUENTES</Link>
        {!this.props.loggedIn ? (
          <Link className="hamburger-menu-login" to="/login/">
            CADASTRAR/LOG IN
          </Link>
        ) : (
          <Link className="hamburger-menu-login" to="/logout/">
            SAIR
          </Link>
        )}
        <h5>Fale com a gente</h5>
        <h4>sac@bigdelivery.com.br</h4>

        <span className="hamburger-menu-telephone-container">
          <img
            className="hamburger-menu-telephone-image"
            alt=""
            src={TelephoneIcon}
          />
          <a
            className="hamburger-menu-standard-text"
            href={`tel:${phone.e164}`}
          >
            {' '}
            <span>{phone.ddd}</span> {phone.asStringNumber}{' '}
          </a>
        </span>
        <WhatsappButton />
      </div>
    );
  }
}

export default userLoggedInConnector(HamburgerMenu);
