// @flow
import React, { Component } from 'react';
// Components

// Styles
import './FaqHit.css';

type Props = {
  question: string,
  answer: string,
};

class FaqHit extends Component {
  props: Props;

  render() {
    return (
      <div className="faq-hit-main">
        <h3>
          {this.props.question}
        </h3>

        <p>
          {this.props.answer}
        </p>
      </div>
    );
  }
}

export default FaqHit;
