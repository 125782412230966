// @flow
import React, { Component } from 'react';
import logo from '../images/LogoVertical.png';

import { Link } from 'react-router-dom'

import './Logo.css';

class Logo extends Component {
  render() {
    return <Link to="" className="logo-main"><img className="logo-image" alt="Big Delivery Logo" src={logo} /></Link>;
  }
}

export default Logo;
