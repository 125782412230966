// @flow
import React, { Component } from 'react';
// Helper functions
import { getTimeWindow } from '../../../common';
// Analytics
import * as Analytics from '../../../common/analytics';
// Styles
import './DeliveryPickerOption.css';
// Types
import type { ShippingRate } from '../../../types';

type Props = {
  option: ShippingRate,
  onSelect: () => void,
  isSelected: boolean,
  id: number,
  deliveriesRemaining: number,
};

const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Maio',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];
class DeliveryPickerOption extends Component {
  props: Props;

  date(deliveryWindow: Date) {
    let month = months[deliveryWindow.getMonth()];
    let day = deliveryWindow.getDate();
    return (
      <div className="delivery-picker-date">
        <div>{month}</div>
        <div>{day}</div>
      </div>
    );
  }

  speed(name: string, cost: string) {
    return (
      <div className="delivery-picker-speed">
        <div className="delivery-picker-speed-name">{name}</div>
        <div>
          {cost === '0.0' ? (
            <div className="delivery-picker-free">Grátis!</div>
          ) : (
            parseFloat(cost).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          )}
        </div>
      </div>
    );
  }

  deliveriesRemaining(count: number) {
    if (count === null) {
      return null;
    }
    if (count === 0) {
      return <div className="delivery-picker-deliveries-full">Lotado</div>;
    }
    if (count < 10) {
      return (
        <div className="delivery-picker-deliveries-avaible">
          {count} Restantes
        </div>
      );
    }
    if (count < 20) {
      return <div className="delivery-picker-deliveries-popular">Popular</div>;
    }

    return null;
  }

  render() {
    let selected = 'delivery-picker-option-selected';
    let optionColor = 'delivery-picker-light';
    if (this.props.id % 2 === 0) {
      optionColor = 'delivery-picker-dark';
    }
    return (
      <div
        className={`delivery-picker-option-main ${optionColor} ${
          this.props.isSelected ? selected : ''
        } ${this.props.deliveriesRemaining === 0 ? 'disable' : ''}`}
        onClick={() => {
          this.props.onSelect();
          Analytics.logSelectedDelivery(
            this.props.deliveriesRemaining,
            this.props.option.deliveryWindowStart,
            this.props.option.deliveryWindowEnd,
          );
        }}
      >
        <label>
          <div className="delivery-picker-date-container">
            {this.date(this.props.option.deliveryWindowStart)}
          </div>

          <div
            className={`delivery-picker-time-container ${
              this.props.deliveriesRemaining === null ? 'express' : ''
            }`}
          >
            {getTimeWindow(
              this.props.option.deliveryWindowStart,
              this.props.option.deliveryWindowEnd,
            )}
          </div>
          <div className="delivery-picker-deliveries-container">
            {this.deliveriesRemaining(this.props.deliveriesRemaining)}
          </div>

          <div className="delivery-picker-speed-container">
            {this.speed(this.props.option.name, this.props.option.cost)}
          </div>
        </label>
      </div>
    );
  }
}

export default DeliveryPickerOption;
