// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
import { Link } from 'react-router-dom';
import { ItemListImage } from '../../common';
//Connectors
import blendsListConnector from '../../blend/connectors/blendsListConnector';
// Types
import type { LineItem } from '../types';
// Style
import './LineItemList.css';

// Takes in unitForQuantity and returns displayable units
const unitConverter = (unitForQuantity: string) => {
  return unitForQuantity === 'g' ? 'Kg' : 'un';
};

// Converts quantity into locale specific value taking into account quantity
const quantityConverter = (quantity: number, unitForQuantity: string) => {
  if (quantity == null) {
    return '- -';
  }

  return (
    parseFloat(
      unitForQuantity === 'g' ? quantity / 1000 : quantity,
    ).toLocaleString('pt-br', {
      // style: 'currency',
      currency: 'BRL',
    }) +
    ' ' +
    unitConverter(unitForQuantity)
  );
};

// Return specific mix of item
const mix = (blends, mixId) => {
  return _.filter(blends, function (o) {
    return o.id === mixId;
  }).pop();
};

type Props = {
  items: LineItem[],
};

class LineItemList extends Component {
  props: Props;

  componentDidMount() {
    // If we already have an order number we should get all the blends.
    if (this.props.orderNumber) {
      this.props.getBlends(this.props.orderNumber);
    }
  }

  render() {
    const blends = this.props.blendsByOrderNumber(this.props.orderNumber);

    return (
      <div className="line-item-list-main">
        {this.props.items.map((item, i) => (
          <div className="line-item-list-container" key={i}>
            <hr className="line-item-list-line" />
            <div className="line-item-list-subcontainer">
              <ItemListImage variant={item.variant} />

              <div className="line-item-list-content">
                <Link to={'/produtos/' + item.variant.slug}>
                  <h3>
                    {item.variant.name}
                    <span className="line-item-list-mix-name">
                      {mix(blends, item.mixId)
                        ? ` - ${mix(blends, item.mixId).name}`
                        : ''}
                    </span>
                  </h3>
                </Link>
                <p>{item.variant.manufacturer}</p>

                <h3>
                  {parseFloat(
                    item.variant.unitForQuantity === 'g'
                      ? item.price * 1000
                      : item.price,
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}{' '}
                  /{unitConverter(item.variant.unitForQuantity)}
                </h3>
              </div>

              {/* Related to amount requested by user */}
              <div className="line-item-list-price-content-estimated">
                <p>Quantidade Pedido</p>
                <h3>
                  {quantityConverter(
                    item.quantity,
                    item.variant.unitForQuantity,
                  )}
                </h3>

                <p>Preço estimado</p>
                <h3>
                  {parseFloat(item.price * item.quantity).toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    },
                  )}
                </h3>
              </div>

              {/* Actual amount received by the user */}
              <div className="line-item-list-price-content">
                <p>Quantidade pesada</p>
                <h3>
                  {quantityConverter(
                    item.producedQuantity,
                    item.variant.unitForQuantity,
                  )}
                </h3>

                <p>Preço final</p>
                <h3 className="line-item-list-price-content-final-price">
                  {parseFloat(
                    Math.round(item.price * item.producedQuantity * 100) / 100,
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default blendsListConnector(LineItemList);
