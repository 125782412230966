// @flow
import React, { useEffect, useState } from 'react';
import { AddBeforeExistsButton } from '../../common';
import { AddAfterExistsButton } from '../../common';
// Composers
import { connect } from 'react-redux';
import { userLoggedInConnector } from '../../user';
// Selectors
import {
  itemSelectorByProductId,
  changingItemLoadingSelector,
  addingItemLoadingSelector,
} from '../selectors';
// Components
import { NotifyMeWhenStockRenewed } from '../../products';
// Analytics
import * as Analytics from '../../common/analytics';
// Types
import type { Connector } from 'react-redux';
import type { LineItem } from '../types';
// Actions
import { addItemToCart, changeQuantity } from '../actions';
// Styles
import './AddToCartButton.css';

type OwnProps = {
  showNotifyMeButton: boolean,
  productId: number,
  unitForQuantity: 'g' | 'un',
  incrementQuantity: number,
  defaultQuantity: number,
  inStock: boolean,
  observation: string,
};

type Props = {
  /* :: ...OwnProps, */
  addingLoading: boolean,
  changingLoading: boolean,
  item: LineItem,
  disableInsteadOfDeleteOnMinimumQuantity?: boolean,
  handleAddItem: () => void,
  handleIncreaseQuantity: () => void,
  handleDecreaseQuantity: () => void,
};
const AddToCartButton = ({
  showNotifyMeButton,
  inStock,
  loggedIn,
  item,
  productId,
  unitForQuantity,
  changingLoading,
  addingLoading,
  handleAddItem,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  handleNotifyMeWhenStockRenewed,
  handleUnnotifyMeWhenStockRenewed,
  disableInsteadOfDeleteOnMinimumQuantity,
  // USE ANALYTICS
  analyticsViewSource,
}) => {
  const [addedItem, setAddedItem] = useState(false);
  const [decreasedQuantity, setDecreasedQuantity] = useState(false);
  const [itemCopy, setItemCopy] = useState(null);

  useEffect(() => {
    if (item && addedItem) {
      Analytics.logLineItemAdd(
        item,
        window.location.pathname,
        analyticsViewSource,
      );
      setAddedItem(false);
    }
  }, [item, addedItem]);

  useEffect(() => {
    if (!item && decreasedQuantity) {
      Analytics.logLineItemRemove(
        itemCopy,
        window.location.pathname,
        analyticsViewSource,
      );
      setDecreasedQuantity(false);
    }
  }, [item, decreasedQuantity]);

  const handleAddItemAction = event => {
    setAddedItem(true);
    handleAddItem();
  };

  const handleDecreaseQuantityAction = event => {
    setDecreasedQuantity(true);
    setItemCopy({ ...item });
    handleDecreaseQuantity();
  };

  // USE ANALYTICS

  // If a product is out of stock
  if (!inStock && loggedIn && showNotifyMeButton) {
    return (
      <div className="container">
        <h3 className="out-of-stock-text">Fora de estoque</h3>
        <NotifyMeWhenStockRenewed productId={productId} />
      </div>
    );
  } else if (!inStock) {
    return (
      <div className="container">
        <h3 className="out-of-stock-text without-button">Fora de estoque</h3>
      </div>
    );
  }

  // Current product was not found in the lineItems. Show add to cart button
  if (!item) {
    return (
      <AddBeforeExistsButton
        addItem={handleAddItemAction}
        loading={addingLoading}
      />
    );
  } else {
    const disableDecreaseQuantity =
      item.variant.minimumQuantity >= item.quantity &&
      disableInsteadOfDeleteOnMinimumQuantity;
    const disableIncrementQuantity =
      item.quantity + item.variant.incrementQuantity >
      item.variant.maximumQuantity;
    return (
      <AddAfterExistsButton
        unitForQuantity={unitForQuantity}
        currentQuantity={item.quantity}
        increaseQuantity={handleIncreaseQuantity}
        decreaseQuantity={handleDecreaseQuantityAction}
        loading={changingLoading}
        disableDecreaseQuantity={disableDecreaseQuantity}
        disableIncrementQuantity={disableIncrementQuantity}
      />
    );
  }
};

const mapStateToProps = (state, ownProps: OwnProps) => {
  const item = itemSelectorByProductId(state, ownProps.productId);
  const itemId = item ? item.id : null;
  return {
    item: item,
    addingLoading: addingItemLoadingSelector(state, ownProps.productId),
    changingLoading: changingItemLoadingSelector(state, itemId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleAddItem: () => {
      dispatch(addItemToCart(ownProps.productId, ownProps.defaultQuantity));
    },
    handleIncreaseQuantity: () => {
      dispatch(
        changeQuantity(
          ownProps.productId,
          ownProps.incrementQuantity,
          ownProps.observation,
        ),
      );
    },
    handleDecreaseQuantity: () => {
      dispatch(
        changeQuantity(
          ownProps.productId,
          -ownProps.incrementQuantity,
          ownProps.observation,
        ),
      );
    },
  };
};

AddToCartButton.defaultProps = {
  disableInsteadOfDeleteOnMinimumQuantity: false,
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default userLoggedInConnector(connector(AddToCartButton));
