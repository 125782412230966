// @flow
import React, { Component } from 'react';
// Composers
import { connect } from 'react-redux';
// Components
import { Helmet } from 'react-helmet';
import UnconnectedDepartmentsIndexPage from '../components/DepartmentsIndexPage';
// Selectors
import {
  indexDepartmentsSelector,
  currentDepartmentsIndexLoadingSelector,
} from '../selectors';
// Actions
import { findDepartmentsIndex } from '../actions';
// Types
import type { Connector } from 'react-redux';
import type { Dispatch } from '../../types';
import type { Department } from '../types';

type Props = {
  departments: Department[],
  loading: boolean,
  findDepartmentsIndex: () => void,
};

class DepartmentsIndexPage extends Component {
  props: Props;

  constructor(props: Props) {
    super(props);
    props.findDepartmentsIndex();
  }

  render() {
    return (
      <>
        {' '}
        <Helmet>
          <title>Departamentos - Bigdelivery - Seu açougue virtual</title>
          <meta property="og:title" content="Departamentos - Bigdelivery" />
          <meta
            name="description"
            content="Encontre aqui os seus produtos por departamento."
          />
          <meta
            name="og:description"
            content="Carne fresca e de qualidade na porta da sua casa, na sua hora e do seu jeito."
          />
          <meta
            property="og:image"
            content={`${process.env.PUBLIC_URL}/image_logo.png`}
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="900" />
          <meta property="og:image:height" content="900" />
          <meta property="og:image:alt" content="Bigdelivery logo" />
        </Helmet>
        <UnconnectedDepartmentsIndexPage
          departments={this.props.departments}
          loading={this.props.loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    departments: indexDepartmentsSelector(state),
    loading: currentDepartmentsIndexLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    findDepartmentsIndex: () => {
      dispatch(findDepartmentsIndex());
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default connector(DepartmentsIndexPage);
