// @flow
import React, { Component } from 'react';

import './FreeShippingNotice.css';

type Props = {
  currentPrice: number,
  shouldDisplay: boolean,
};

class FreeShippingNotice extends Component {
  props: Props;
  render() {
    if (!this.props.shouldDisplay) return null;

    return (
      <div className="free-shipping-notice-main">
        Adicione mais{' '}
        {(99 - this.props.currentPrice).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}{' '}
        em produtos e ganhe frete grátis!
      </div>
    );
  }
}

export default FreeShippingNotice;
