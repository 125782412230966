// @flow
import React, { useEffect, useState } from 'react';
// Composers
import { connect } from 'react-redux';
// Actions
import {
  notifyMeWhenStockRenewed,
  unnotifyMeWhenStockRenewed,
} from '../actions';
// Selectors
import {
  shouldNotifyUserSelector,
  shouldNotifyUserLoadingByIdSelector,
} from '../selectors';
// Style
import './NotifyMeWhenStockRenewed.css';

const NotifyMeWhenStockRenewed = ({
  productId,
  // Internal Props
  isLoading,
  shouldNotifyUser,
  handleNotifyMeWhenStockRenewed,
  handleUnnotifyMeWhenStockRenewed,
}) => {
  if (isLoading) {
    return (
      <div className="notify-me-button loading-button">
        <i
          className="fa fa-spinner fa-pulse fa-4x loading-icon"
          aria-hidden="true"
        />
      </div>
    );
  }
  if (!shouldNotifyUser) {
    return (
      <button
        className="notify-me-button no-focus"
        onClick={() => {
          handleNotifyMeWhenStockRenewed(productId);
        }}
      >
        <div className="darker-button">
          <div className="icon">
            <i className="far fa-envelope"></i>
          </div>
        </div>
        <div className="notify-me-text">
          <div className="notify-me-bold-text">Me avise</div>
          <div className="notify-me-normal-text">quando chegar!</div>
        </div>
      </button>
    );
  } else {
    return (
      <button
        className="notify-me-button-clicked noFocus"
        onClick={() => {
          handleUnnotifyMeWhenStockRenewed(productId);
        }}
      >
        <div className="darker-button">
          <div className="icon">
            <i className="fas fa-check"></i>
          </div>
        </div>
        <div className="notify-me-text">
          <div className="notify-me-bold-text">Pronto!</div>
          <div className="notify-me-normal-text">Agora é só esperar!</div>
        </div>
      </button>
    );
  }
};
const mapStateToProps = (state, ownProps) => {
  return {
    shouldNotifyUser: shouldNotifyUserSelector(state, ownProps.productId),
    isLoading: shouldNotifyUserLoadingByIdSelector(state, ownProps.productId),
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleNotifyMeWhenStockRenewed: () => {
      dispatch(notifyMeWhenStockRenewed(ownProps.productId));
    },
    handleUnnotifyMeWhenStockRenewed: () => {
      dispatch(unnotifyMeWhenStockRenewed(ownProps.productId));
    },
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(NotifyMeWhenStockRenewed);
