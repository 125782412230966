// @flow
import { logException } from '../../logHelper';
// Actions
import * as order from '../../current-order/actions';
import * as user from '../../user/actions';
import * as products from '../../products/actions';

// Helper function for comparing action
const actionMatches = (action, actionCreator) => {
  return !action.error && actionCreator.toString();
};

// Helper function for converting a lineItem to object that is sent to gtm
const lineItemToGtmProduct = lineItem => {
  const product = lineItem.variant;
  return {
    //  adding a product to a shopping cart.
    name: product.name,
    id: product.slug,
    price: product.price,
    brand: product.manufacturer,
    // 'category': 'Apparel',
    variant: product.optionsText,
    quantity: lineItem.quantity,
    totalPrice: product.price * lineItem.quantity,
    unitForQuantity: product.unitForQuantity,
    minimumQuantity: product.minimumQuantity,
    defaultQuantity: product.defaultQuantity,
  };
};

/////////////////////////////////////////////////////////
/////////////////// EVENT DEFINITIONS ///////////////////
/////////////////////////////////////////////////////////
const pageView = action => ({
  hitType: 'pageview',
  page: action.payload.location.pathname,
});

const validateOrLoginUser = action => {
  const email = action.payload.result;
  const userId = action.payload.entities.user[email].id;
  const userFirstName = action.payload.entities.user[email].firstName;
  const userLastName = action.payload.entities.user[email].lastName;
  return {
    event: 'userValidateOrLogin',
    userId,
    userEmail: email,
    userFirstName,
    userLastName,
    userType: 'existing',
  };
};

const registerUser = action => {
  const email = action.payload.result;
  const userId = action.payload.entities.user[email].id;
  return { event: 'userRegister', userId, userType: 'newUser' };
};

const productClick = action => {
  const productId = action.payload.result;
  const product = action.payload.entities.product[productId];

  return {
    event: 'productClick',
    ecommerce: {
      click: {
        products: [
          {
            name: product.name, // Name or ID is required.
            id: product.slug,
            price: product.price,
            brand: product.manufacturer,
            variant: product.optionsText,
            unitForQuantity: product.unitForQuantity,
            minimumQuantity: product.minimumQuantity,
            defaultQuantity: product.defaultQuantity,
          },
        ],
      },
    },
  };
};

const addItemToCart = action => {
  const lineItemId = action.payload.result;
  const lineItem = action.payload.entities.lineItems[lineItemId];
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        // 'add' actionFieldObject measures.
        products: [lineItemToGtmProduct(lineItem)],
      },
    },
  };
};

const removeItemFromCart = action => {
  return { event: 'removeFromCart' };
};

const transitionToDelivery = action => {
  const orderId = action.payload.result;
  const order = action.payload.entities.orders[orderId];
  const lineItems = order.lineItems.map(
    id => action.payload.entities.lineItems[id],
  );

  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1 },
        products: lineItems.map(l => lineItemToGtmProduct(l)),
      },
    },
  };
};

const transitionToPayment = action => {
  const orderId = action.payload.result;
  const order = action.payload.entities.orders[orderId];
  const lineItems = order.lineItems.map(
    id => action.payload.entities.lineItems[id],
  );

  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 2 },
        products: lineItems.map(l => lineItemToGtmProduct(l)),
      },
    },
  };
};

const transitionToConfirmation = action => {
  const orderId = action.payload.result;
  const order = action.payload.entities.orders[orderId];
  const lineItems = order.lineItems.map(
    id => action.payload.entities.lineItems[id],
  );
  const paymentName = order.payments[0].paymentMethod.name;

  return [
    {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: paymentName },
          products: lineItems.map(l => lineItemToGtmProduct(l)),
        },
      },
    },
    order.state === 'complete'
      ? {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: order.number,
                affiliation: order.channel,
                revenue: order.total, // Total transaction value (incl. tax and shipping)
              },
            },
          },
        }
      : {},
  ];
};

// Map
export const eventsMapper = action => {
  try {
    switch (action.type) {
      // Page view
      case 'LOCATION_CHANGE':
        return pageView;
      // User
      case actionMatches(action, user.validateUser):
        return validateOrLoginUser;
      case actionMatches(action, user.loginUser):
        return validateOrLoginUser;
      case actionMatches(action, user.registerUser):
        return registerUser;
      // Products
      case actionMatches(action, products.fetchProduct):
        return productClick;
      // Shopping cart
      case actionMatches(action, order.addItem):
        return addItemToCart;
      case actionMatches(action, order.removeItem):
        return removeItemFromCart;
      // Checkout Process
      case actionMatches(action, order.transitionToDelivery):
        return transitionToDelivery;
      case actionMatches(action, order.transitionToPayment):
        return transitionToPayment;
      case actionMatches(action, order.transitionToConfirmation):
        return transitionToConfirmation;
      default:
        return [];
    }
  } catch (e) {
    console.error('GTA failure', e);
    logException(e);
  }

  return [];
};
