// @flow
import React, { Component } from 'react';
import _ from 'lodash';
// Components
import ReactCreditCard from 'react-credit-cards';
import { InputWithValidation } from '../../../common';
// Types
import type { PaymentSourceCreditCard } from '../../types';
// Styles
import 'react-credit-cards/lib/styles-compiled.css';
import './CreditCardEditor.css';

type Props = {
  card: PaymentSourceCreditCard,
  validationErrors: {
    name: string,
    number: string,
    verificationValue: string,
    year: string,
    month: string,
  },
  onUpdate: PaymentSourceCreditCard => void,
};

type State = {
  focused: null | 'name' | 'number' | 'expiry' | 'cvc',
};

class CreditCardEditor extends Component {
  props: Props;
  state: State;
  updateValue: (key: $Keys<PaymentSourceCreditCard>) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      focused: null,
    };
  }

  updateValue = (key: $Keys<PaymentSourceCreditCard>) => {
    return event => {
      if (event.target instanceof HTMLInputElement) {
        const newValue = event.target.value;
        this.props.onUpdate({ ...this.props.card, [key]: newValue });
      }
    };
  };

  render() {
    const expiry =
      _.padStart(this.props.card.month, 2, '0') +
      _.padStart(this.props.card.year, 2, '0');
    return (
      <div className="credit-card-editor-main">
        <form className="credit-card-editor-form">
          <div className="credit-card-editor-form-main">
            <InputWithValidation
              className="credit-card-editor-form-control"
              type="text"
              placeholder="Nome do titular do cartão"
              value={this.props.card.name}
              onChange={this.updateValue('name')}
              validationError={this.props.validationErrors.name}
              onFocus={() =>
                this.setState({
                  focused: 'name',
                })
              }
              onBlur={() =>
                this.setState({
                  focused: null,
                })
              }
            />
            <InputWithValidation
              className="credit-card-editor-form-control"
              type="number"
              placeholder="Número de cartão"
              value={this.props.card.number}
              onChange={this.updateValue('number')}
              validationError={this.props.validationErrors.number}
              onFocus={() =>
                this.setState({
                  focused: 'number',
                })
              }
              onBlur={() =>
                this.setState({
                  focused: null,
                })
              }
            />
            <InputWithValidation
              className="credit-card-editor-form-control"
              type="number"
              placeholder="Mês de vencimento"
              value={this.props.card.month}
              onChange={this.updateValue('month')}
              validationError={this.props.validationErrors.month}
              onFocus={() =>
                this.setState({
                  focused: 'expiry',
                })
              }
              onBlur={() =>
                this.setState({
                  focused: null,
                })
              }
            />
            <InputWithValidation
              className="credit-card-editor-form-control"
              type="number"
              placeholder="Ano de vencimento"
              value={this.props.card.year}
              onChange={this.updateValue('year')}
              validationError={this.props.validationErrors.year}
              onFocus={() =>
                this.setState({
                  focused: 'expiry',
                })
              }
              onBlur={() =>
                this.setState({
                  focused: null,
                })
              }
            />
            <InputWithValidation
              className="credit-card-editor-form-control"
              type="number"
              placeholder="Código de segurança (CVC)"
              value={this.props.card.verificationValue}
              onChange={this.updateValue('verificationValue')}
              validationError={this.props.validationErrors.verificationValue}
              onFocus={() =>
                this.setState({
                  focused: 'cvc',
                })
              }
              onBlur={() =>
                this.setState({
                  focused: null,
                })
              }
            />
          </div>
        </form>

        <div className="credit-card-editor-display">
          <ReactCreditCard
            className="credit-card-display"
            name={this.props.card.name || ''}
            number={this.props.card.number || ''}
            expiry={expiry}
            cvc={this.props.card.verificationValue || ''}
            focused={this.state.focused || ''}
            namePlaceholder={'NOME COMPLETO'}
          />
        </div>
      </div>
    );
  }
}
export default CreditCardEditor;
