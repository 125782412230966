// @flow
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// Components
import CartTotal from './CartTotal';
import ItemList from './ItemList';
import { BlendList as MixList } from '../../blend'; // TODO: In the future the BlendList should be made to support all types of mixes
import { PageLoader, FreeShippingNotice, OutOfStockNotice } from '../../common';
import EmptyCart from '../images/empty-cart.png';
import { ProductMostSoldScroller } from '../../products';
// Analytics
import * as Analytics from '../../common/analytics';
// TYpes
import type { LineItem } from '../types';
import './Cart.css';

type Props = {
  items: LineItem[],
  totalPrice: number,
  numberOfItems: number,
  totalKg: number,
  totalUnit: number,
  handleInitiateCheckout: () => void,
  handleRemoveItem: (lineItemId: number) => void,
  loading: boolean,
};

const Cart = ({
  loading,
  numberOfItems,
  items,
  blends,
  totalKg,
  totalUnit,
  totalPrice,
  handleRemoveItem,
  removingItemLoading,
}) => {
  const outOfStockItems = _.filter(items, l => l.variant.inStock === false);

  const hasProductsOutOfStock = !!outOfStockItems.length;

  // USE ANALYTICS
  useEffect(() => {
    if (items && items.length > 0) {
      items.forEach(item => {
        Analytics.logLineItemView(item, window.location.pathname, 'cart');
      });
    }
  }, []);
  const handleRemoveItemAction = lineItemId => {
    const lineItem = _.find(items, item => item.id === lineItemId);
    Analytics.logLineItemRemove(lineItem, window.location.pathname, 'cart');
    handleRemoveItem(lineItemId);
  };
  // USE ANALYTICS

  return (
    <div>
      {loading && numberOfItems === 0 ? (
        <PageLoader />
      ) : numberOfItems === 0 ? (
        <div className="cart-page-empty">
          <h3>Seu carrinho esta vazio. Vamos enchê-lo?</h3>
          <img src={EmptyCart} alt="" />
        </div>
      ) : (
        <div className="cart-page-main-container">
          <FreeShippingNotice
            currentPrice={totalPrice}
            shouldDisplay={totalPrice < 99}
          />
          <OutOfStockNotice shouldDisplay={hasProductsOutOfStock} />
          <ItemList
            items={items}
            handleRemoveItem={handleRemoveItemAction}
            removingItemLoading={removingItemLoading}
          />

          {blends.length > 0 ? <MixList lineItems={blends} /> : null}

          <hr className="cart-list-line" />

          <div className="cart-list-price">
            <CartTotal
              totalPrice={totalPrice}
              numberOfItems={numberOfItems}
              totalKg={totalKg}
              totalUnit={totalUnit}
            />

            <div
              className={`cart-list-button ${
                hasProductsOutOfStock ? 'cart-list-button-disabled' : ''
              }`}
              onClick={() => {
                Analytics.logTappedCheckout(items);
              }}
            >
              <Link
                to="/checkout/"
                className={`cart-list-btn ${
                  hasProductsOutOfStock ? 'cart-list-btn-disabled' : ''
                }`}
              >
                FECHAR COMPRA
              </Link>
            </div>
          </div>
        </div>
      )}
      <ProductMostSoldScroller />
    </div>
  );
};

export default Cart;
