// @flow
import React, { Component } from 'react';
import stamp from '../images/CowStamp.png';

import './Stamp.css';

class Stamp extends Component {
  render() {
    return <div className="stamp-main"><img className="stamp-image" alt="Big Delivery Cow Stamp" src={stamp} /></div>;
  }
}

export default Stamp;
