// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Selectors
import {
  userIdSelector,
  userAddressesSelector,
  gettingUserAddressesLoadingSelector,
  editingUserAddressesLoadingSelector,
  addingUserAddressesLoadingSelector,
  deletingUserAddressesLoadingByIdSelector,
} from '../selectors';
// Actions
import {
  getAddresses,
  newAddress,
  updateAddress,
  deleteAddress,
} from '../actions';
// Components
import MyAccountPageContainer from '../components/myAccount/MyAccountPageContainer';
import MyAccountAddress from '../components/myAccount/MyAccountAddress';
// Styles
import './MyAccountAddressesPage.css';
// Types
import type { Address } from '../types';

type Props = {
  addresses: Address[],
  getAddresses: () => void,
  onSubmitNewAddress: (address: Address) => void,
  onSubmitUpdateAddress: (address: Address) => void,
  onSubmitDeleteAddress: (addressId: number) => void,
};

class MyAccountAddressesPage extends Component {
  constructor(props: Props) {
    super(props);

    // get the user addresses
    if (userIdSelector) {
      props.getAddresses();
    }
  }

  // Make sure we loaded the addresses. We may have failed to load them if URL was copied in
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.userId && nextProps.userId) {
      this.props.getAddresses();
    }
  }

  render() {
    return (
      <MyAccountPageContainer>
        <MyAccountAddress
          addresses={this.props.addresses}
          onSubmitNew={this.props.onSubmitNewAddress}
          onSubmitUpdate={this.props.onSubmitUpdateAddress}
          onSubmitDelete={this.props.onSubmitDeleteAddress}
          loading={this.props.loading}
          editingLoading={this.props.editingLoading}
          addingLoading={this.props.addingLoading}
          deletingLoading={this.props.deletingLoading}
        />
      </MyAccountPageContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: userIdSelector(state),
    addresses: userAddressesSelector(state),
    loading: gettingUserAddressesLoadingSelector(state),
    editingLoading: editingUserAddressesLoadingSelector(state),
    addingLoading: addingUserAddressesLoadingSelector(state),
    deletingLoading: id => deletingUserAddressesLoadingByIdSelector(state, id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps) => {
  return {
    getAddresses: () => {
      dispatch(getAddresses());
    },
    onSubmitNewAddress: address => {
      dispatch(newAddress(address));
    },
    onSubmitUpdateAddress: address => {
      dispatch(updateAddress(address));
    },
    onSubmitDeleteAddress: addressId => {
      dispatch(deleteAddress(addressId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MyAccountAddressesPage);
