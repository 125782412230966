// @flow
import { createSelector } from 'reselect';
import _ from 'lodash';
import type { Order } from '../../types';
import type { StoreState } from '../types';

export const localStateSelector = (state: StoreState): Order => {
  return state.pastOrders;
};

// The current orders LIST
export const ordersListSelector = createSelector(localStateSelector, (state) =>
  _.without(
    state.currentList.map((orderNumber) => state.orders[orderNumber]),
    undefined,
  ),
);

export const pagesInfoSelector = createSelector(localStateSelector, (state) => {
  return {
    currentPage: state.pagesInfo.currentPage,
    numberOfPages: state.pagesInfo.pages,
    perPage: state.pagesInfo.perPage,
  };
});

// The current order
export const currentOrderSelector = createSelector(
  localStateSelector,
  (state) => state.orders[state.current],
);

////////////////// LINE ITEMS ///////////////////
// Get the line items that belong to the current order
export const currentOrderLineItemsSelector = createSelector(
  localStateSelector,
  currentOrderSelector,
  (state, order) => {
    // If we don't have an order return an empty array
    if (!order) {
      return [];
    }

    return order.lineItems.map((id) => state.lineItems[id]);
  },
);

////////////////// SHIPMENTS ///////////////////
// Get the shipments that belong to the current order
export const currentOrderShipmentsSelector = createSelector(
  localStateSelector,
  currentOrderSelector,
  (state, order) => {
    // If we don't have an order return an empty array
    if (!order) {
      return [];
    }

    return order.shipments.map((id) => state.shipments[id]);
  },
);

export const currentOrderSelectedShipmentSelector = createSelector(
  localStateSelector,
  currentOrderSelector,
  currentOrderShipmentsSelector,
  (state, order, shipments) => {
    // If we don't have an order return an empty array
    if (!order) {
      return null;
    }

    return state.shippingRates[_.last(shipments).selectedShippingRate];
  },
);

//////////////////////// TRACKER ////////////////////

// Order tracker
export const trackerByOrderNumber = (state, orderNumber) =>
  createSelector(localStateSelector, (state) =>
    state.tracker[orderNumber] ? state.tracker[orderNumber] : {},
  )(state);

//////////////////////// LOADING ////////////////////
export const gettingOrdersLoadingSelector = createSelector(
  localStateSelector,
  (state) => state.loading.gettingOrders,
);

export const gettingOrderLoadingSelector = createSelector(
  localStateSelector,
  (state) => state.loading.gettingOrder,
);

export const trackingLoadingSelector = createSelector(
  localStateSelector,
  (state) => state.loading.gettingTracker,
);
