// @flow
/**
 * USER MODULE EXPORTS
 * This is the "interface" file for the user module.
 * You should only use exports from this file.
 */

/**
 * Imports
 * =======
 * Import default exports from files inside
 * the module
 */
import reducer from './reducer';
import CurrentUserIndicator from './containers/CurrentUserIndicator';
import UserValidate from './containers/UserValidate';
import LoginPage from './containers/LoginPage';
import LogoutPage from './containers/LogoutPage';
import PasswordRecoveryPage from './containers/PasswordRecoveryPage';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
// My Account Related
import MyAccountInformationPage from './containers/MyAccountInformationPage';
import MyAccountOrdersPage from './containers/MyAccountOrdersPage';
import MyAccountOrderPage from './containers/MyAccountOrderPage';
import MyAccountPasswordPage from './containers/MyAccountPasswordPage';
import MyAccountAddressesPage from './containers/MyAccountAddressesPage';
import MyAccountPaymentsPage from './containers/MyAccountPaymentsPage';
import MyAccountAddressEditor from './components/myAccount/MyAccountAddressEditor';
import MyAccountAddressItem from './components/myAccount/MyAccountAddressItem';
import MyAccountAddressAddNewButton from './components/myAccount/MyAccountAddressAddNewButton';
import UnauthenticatedRedirect from './containers/UnauthenticatedRedirect';
import userAddressConnector from './connectors/userAddressConnector';
import userLoggedInConnector from './connectors/userLoggedInConnector';
import userCreditCardsConnector from './connectors/userCreditCardsConnector';
import userEmailConnector from './connectors/userEmailConnector';
import userAllowedPaymentMethodsConnector from './connectors/userAllowedPaymentMethodsConnector';

/**
 * Reducer
 * =======
 * The default export is the reducer to be integrated in the Redux Store.
 * Its state is defined too, and exported as the type UserStoreState.
 */
export default reducer;
export type { StoreState as UserStoreState } from './types/StoreState';

/**
 * Widgets
 * =======
 * Widgets are connected components that can be used inside a page.
 */
export { CurrentUserIndicator, UserValidate };

/**
 * Pages
 * =====
 * Pages are components that are used in the router.
 * They are auto-rendered by react-router and thus have
 * to be self-sufficient (no passed props).
 */
export {
  LoginPage,
  LogoutPage,
  MyAccountInformationPage,
  MyAccountOrdersPage,
  MyAccountOrderPage,
  MyAccountAddressesPage,
  MyAccountPasswordPage,
  PasswordRecoveryPage,
  ForgotPasswordPage,
  MyAccountPaymentsPage,
};

/**
 * Unconnected Components
 */
export {
  MyAccountAddressEditor,
  MyAccountAddressItem,
  MyAccountAddressAddNewButton,
};

/**
 * HOCs and Connectors
 * ===================
 * Higher order components and connectors wrap the children component
 * and add behavior or props that depend on the internal user state.
 */
export {
  UnauthenticatedRedirect,
  userAddressConnector,
  userLoggedInConnector,
  userCreditCardsConnector,
  userEmailConnector,
  userAllowedPaymentMethodsConnector,
};

/**
 * Types
 * =====
 * Flow types to be used in other modules, generally with connectors.
 */
export type { Address as UserAddress } from './types/Address';
