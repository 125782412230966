// @flow
import React, { Component } from 'react';
import type { UserAddress } from '../../../user';
// Components
import {
  MyAccountAddressEditor as AddressEditor,
  MyAccountAddressItem as AddressItem,
} from '../../../user';
import { PageLoader } from '../../../common';
// Styles
import './AddressPicker.css';

export type Props = {
  editing: number | 'new' | false,
  addresses: UserAddress[],
  selected: ?UserAddress,
  loading: boolean,
  editingLoading: boolean,
  addressesLoading: boolean,
  pickAddress: (address: UserAddress) => void,
  editAddress: (address: UserAddress) => void,
  updateAddress: (address: UserAddress) => void,
  deleteAddress: (address: UserAddress) => void,
  deletingLoading: (id: number) => boolean,
};

class AddressPicker extends Component {
  props: Props;
  render() {
    // In case the addresses are still loading
    if (this.props.addressesLoading) {
      return <PageLoader />;
    }

    return (
      <div className="address-picker-main">
        <div className="address-picker-content">
          {this.props.addresses.map(address => {
            if (this.props.editing === address.id) {
              return (
                <AddressEditor
                  key={address.id}
                  address={address}
                  loading={this.props.editingLoading}
                  onSubmitAddress={editedAddress =>
                    this.props.updateAddress(editedAddress)
                  }
                />
              );
            }
            return (
              <AddressItem
                key={address.id}
                address={address}
                isSelected={
                  this.props.selected
                    ? this.props.selected.id === address.id
                    : false
                }
                deletingLoading={this.props.deletingLoading(address.id)}
                onClickSelect={() => this.props.pickAddress(address)}
                onClickEdit={() => this.props.editAddress(address)}
                onClickDelete={() => {
                  if (window.confirm('Deletar o endereço?')) {
                    this.props.deleteAddress(address);
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default AddressPicker;
