// @flow
import React from 'react';
import './OutOfStockNotice.css';

const OutOfStockNotice = ({ shouldDisplay }) => {
  if (!shouldDisplay) return null;
  return (
    <>
      <div className="out-of-stock-notice-main">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        Um ou mais itens do seu carrinho saíram de estoque. Remova-os antes de
        continuar
      </div>
    </>
  );
};

export default OutOfStockNotice;
