// @flow
import React from 'react';
import _ from 'lodash';
// Components
import Slider from 'react-slick';
import ProductSquare from './ProductSquare';
import { scroller } from 'react-scroll';
import { PageLoader } from '../../common';
import { Link } from 'react-router-dom';
// Types
import type { Product } from '../types';
// Styling
import './Scroller.css';
import 'slick-carousel/slick/slick.css';

type Props = {
  products: Product[],
  title: string,
  slug: string,
  isLoading: boolean,
  hideBottomLine: boolean,
  scrollable: boolean,
};

type State = {
  showAll: boolean,
};

// Slick Slider Arrows
class SampleNextArrow extends React.Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <div className="scroller-arrow-container">
          <i
            className="fa fa-caret-right fa-4x scroller-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

class SamplePrevArrow extends React.Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <div className="scroller-arrow-container">
          <i
            className="fa fa-caret-left fa-4x scroller-arrow"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

// This is the Scroller
class Scroller extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !this.props.isLoading &&
      this.props.scrollable &&
      this.props.products.length > 0 &&
      !_.isEqual(prevProps, this.props)
    ) {
      scroller.scrollTo(this.props.slug, {
        duration: 1500,
        smooth: 'easeInOutQuart',
      });
    }
  }

  render() {
    // Note that Product Scroller must have an even number of items if scroll by 2 will behave properly
    const settings = {
      dots: false,
      infinite: false,
      autoplay: false,
      accessibility: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: true,
      swipe: true,
      vertical: false,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    const sortedProducts = _.orderBy(
      this.props.products,
      ['inStock', 'options', 'name'],
      ['desc', 'asc', 'asc'],
    );

    if (this.props.isLoading) {
      return <PageLoader />;
    }

    if (this.props.products.length > 0) {
      return (
        <div className="scroller-main">
          <div className="scroller-header" id={this.props.slug}>
            <hr className="scroller-small-line" />
            <h3>
              <Link to={this.props.slug || ''} className="scroller-link">
                {this.props.title}
              </Link>
            </h3>
            <hr className="scroller-medium-line" />
          </div>
          <div className="scroller">
            <Slider {...settings}>
              {sortedProducts.map((product, i) => (
                <div key={i}>
                  <ProductSquare
                    productImage={product.images[0]}
                    productName={
                      product.name.length < 40
                        ? product.name
                        : product.name.substring(0, 38) + '...'
                    }
                    productManufacturer={product.manufacturer}
                    productListPrice={product.listPrice}
                    productPrice={product.price}
                    productUnit={product.unitForQuantity}
                    productSlug={product.slug}
                    productId={product.id}
                    parentProductId={product.productId}
                    productTotalOnHand={product.totalOnHand}
                    defaultQuantity={product.defaultQuantity}
                    incrementQuantity={product.incrementQuantity}
                    inStock={product.inStock}
                    analyticsViewSource={this.props.title} // USE ANALYTICS
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="scroller-mobile">
            <div className="scroller-mobile-products">
              {sortedProducts.map((product, i) => {
                if (this.state.showAll || i < 4) {
                  return (
                    <div key={i}>
                      <ProductSquare
                        productImage={product.images[0]}
                        productName={
                          product.name.length < 40
                            ? product.name
                            : product.name.substring(0, 40) + '...'
                        }
                        productManufacturer={product.manufacturer}
                        productListPrice={product.listPrice}
                        productPrice={product.price}
                        productUnit={product.unitForQuantity}
                        productSlug={product.slug}
                        productId={product.id}
                        productTotalOnHand={product.totalOnHand}
                        parentProductId={product.productId}
                        defaultQuantity={product.defaultQuantity}
                        incrementQuantity={product.incrementQuantity}
                        inStock={product.inStock}
                        analyticsViewSource={this.props.title} // USE ANALYTICS
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {!this.state.showAll && this.props.products.length > 4 ? (
              <div className="scroller-mobile-see-more">
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ showAll: true });
                  }}
                >
                  ver mais
                </a>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Scroller;
