// @flow
import React, { Component } from 'react';
import type { LineItem } from '../types';

//Components
import { Link } from 'react-router-dom';
import { ItemListImage, quantityConverter } from '../../common';
import AddToCartButton from '../../current-order/containers/AddToCartButton';
import InputFieldErrorMessage from '../../common/components/InputFieldErrorMessage';
import { Button, Checkbox } from '../../common';
import Slider from 'rc-slider';

// Actions
import { changeLineItem } from '../actions';

//Selector
import { editingItemLoadingByIdSelector } from '../selectors';

//Helpers
import { portioningString } from '../../common/commonHelpers';

// Composers
import { connect } from 'react-redux';

//Style
import './Item.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';

type OwnProps = {
  productId: number,
};

type Props =
  | {
      item: LineItem,
      updatingItemLoading: boolean,
      handleRemoveItem: (lineItemId: number) => void,
    }
  | OwnProps;

type State = {
  showAdvancedOptions: boolean,
  observation: string,
  badObservation: string,
  isPortioned: boolean,
  portioningSize: number,
};

class Item extends Component {
  state: State;
  constructor(props: Props) {
    super(props);

    this.state = {
      showAdvancedOptions: !!this.props.item.observation,
      observation: this.props.item.observation || '',
      badObservation: '',
      isPortioned: !!this.props.item.portioningSize,
      portioningSize: this.props.item.portioningSize || null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.item.portioningSize !== prevProps.item.portioningSize &&
      this.props.item.portioningSize !== this.state.portioningSize
    ) {
      this.setState({
        isPortioned: !!this.props.item.portioningSize,
        portioningSize: this.props.item.portioningSize,
      });
    }
  }

  render() {
    const item = this.props.item;
    const isEqualFields =
      item.observation === this.state.observation &&
      item.portioningSize === this.state.portioningSize;
    const minQuantity = Math.max(
      Math.ceil(item.quantity / 30 / 100) * 100,
      500,
    );
    const maxQuantity = Math.min(10000, item.quantity);
    const incrementStep = 100;
    return (
      <div className="item-list-container">
        <hr className="item-list-line" />

        <div className="item-list-subcontainer">
          <ItemListImage variant={item.variant} />

          <div className="line-item-content">
            <Link to={`/produtos/${item.variant.slug}/`}>
              <h3>{item.variant.name}</h3>
              {item.portioningSize ? (
                <span className="item-portioning-size-text">
                  {portioningString(item)}
                </span>
              ) : null}
            </Link>
            <p>{item.variant.manufacturer}</p>

            <h3>
              {parseFloat(
                item.variant.unitForQuantity === 'g'
                  ? item.variant.price * 1000
                  : item.variant.price,
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
              /{item.variant.unitForQuantity === 'g' ? 'Kg' : 'un'}
            </h3>
          </div>
          <div className="line-item-price-container">
            <div className="line-item-price-container-option">
              <button
                className={'fa fa-pencil item-list-trash'}
                onClick={() =>
                  this.setState({
                    showAdvancedOptions: !this.state.showAdvancedOptions,
                  })
                }
                aria-hidden="true"
                title="Opções avançadas."
              />
              <span>mais opções</span>
            </div>
            <div className="line-item-price-content">
              <AddToCartButton
                productId={item.variant.id}
                unitForQuantity={item.variant.unitForQuantity}
                defaultQuantity={item.quantity}
                incrementQuantity={item.variant.incrementQuantity}
                inStock={item.variant.inStock}
                disableInsteadOfDeleteOnMinimumQuantity={true}
                observation={this.state.observation}
                analyticsEventType={'cart'} // USE ANALYTICS
              />

              <p>Preço estimado</p>
              <h3>
                {parseFloat(item.variant.price * item.quantity).toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  },
                )}
              </h3>
            </div>
            <div className="line-item-price-container-option">
              <button
                className="fa fa-trash-o item-list-trash"
                aria-hidden="true"
                onClick={() => this.props.handleRemoveItem(item.id)}
                disabled={this.props.removingItemLoading}
              />
              <span>remover item</span>
            </div>
          </div>
        </div>
        {this.state.showAdvancedOptions ? (
          <>
            <div className="line-item-observation-main">
              <form>
                <InputFieldErrorMessage message={this.state.badObservation} />
                <textarea
                  placeholder="Observações para a produção"
                  value={this.state.observation}
                  onChange={e =>
                    e.target.value.length < 100 &&
                    this.setState({
                      observation: e.target.value,
                      badObservation:
                        e.target.value.length === 99
                          ? 'Observação muito longa'
                          : '',
                    })
                  }
                ></textarea>
              </form>
            </div>
            {item.variant.unitForQuantity === 'g' ? (
              <>
                <div className="item-portioning-edit">
                  <div className="item-portioning-checkbox-item">
                    <Checkbox
                      label={<span>Porcionar?</span>}
                      checked={this.state.isPortioned}
                      onChange={() => {
                        this.setState({ isPortioned: !this.state.isPortioned });
                        if (this.state.isPortioned) {
                          this.setState({ portioningSize: 0 });
                        }
                      }}
                    />
                  </div>
                  <div className="item-slider-content">
                    <Slider
                      min={minQuantity}
                      max={maxQuantity}
                      step={incrementStep}
                      dots={
                        this.state.isPortioned &&
                        (maxQuantity - minQuantity) / incrementStep <= 20 &&
                        window &&
                        window.innerWidth >= 1024
                      }
                      dotStyle={{ borderColor: '#ce3c25' }}
                      defaultValue={
                        this.state.portioningSize >= minQuantity
                          ? this.state.portioningSize
                          : minQuantity
                      }
                      value={this.state.portioningSize || minQuantity}
                      onChange={value => {
                        this.setState({ portioningSize: value });
                      }}
                      disabled={!this.state.isPortioned}
                      trackStyle={{ backgroundColor: '#eb5b1e' }}
                      handleStyle={{ borderColor: '#ce3c25' }}
                    />
                  </div>
                  <div className="item-portioning-size-block">
                    <span>
                      {quantityConverter(
                        this.state.portioningSize,
                        item.variant.unitForQuantity,
                      )}
                    </span>
                  </div>
                </div>

                {(this.state.portioningSize && (
                  <span className="item-portioning-size-quantity">{`Para o porcionamento selecionado, este item será enviado em ${Math.ceil(
                    item.quantity / this.state.portioningSize,
                  )} saco(s) separado(s)`}</span>
                )) ||
                  null}
              </>
            ) : null}
            <div className="btn-save-observation">
              <Button
                text={isEqualFields ? 'SALVO' : 'SALVAR'}
                buttonType={
                  isEqualFields ? 'button-tertiary' : 'button-secondary'
                }
                icon=""
                loading={this.props.updatingItemLoading}
                disabled={isEqualFields}
                onClick={e => {
                  this.props.handleEdit(
                    this.state.observation,
                    this.state.portioningSize,
                  );
                }}
              />
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: OwnProps) => {
  return {
    updatingItemLoading: editingItemLoadingByIdSelector(
      state,
      ownProps.productId,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleEdit: (observation: string, portioningSize: number) => {
      dispatch(changeLineItem(ownProps.productId, observation, portioningSize));
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default connector(Item);
