// @flow
import React from 'react';
// Components
import { Button, InputFieldErrorMessage, ColorRow } from '../../common';
// Images
import Pork from '../../static/images/porkOnSkate_simple.png';
// Styles
import './PasswordRecovery.css';

type State = {
  password: string,
  passwordConfirmation: string,
  // Errors
  passwordError: string,
  passwordConfirmationError: string,
};

type Props = {
  isLoading: boolean,
  errors: {},
  onSubmitRecoverPassword: (
    password: string,
    passwordConfirmation: string,
  ) => void,
};

class PasswordRecovery extends React.Component {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirmation: '',
      passwordError: '',
      passwordConfirmationError: '',
    };
  }

  // Validates the form on submission. If there are no validation error dispatches action.
  validateUserInput() {
    const password = this.state.password;
    const confirmationPassword = this.state.passwordConfirmation;

    let noValidationErrors: boolean = true;

    if (password === '') {
      // Password is missing or invalid?
      this.setState({ passwordError: 'Campo é obrigatório' });
      noValidationErrors = false;
    } else if (password.length < 6) {
      this.setState({
        passwordError: 'Senha muito curta. Mínimo de 6 caracteres',
      });
      noValidationErrors = false;
    } else if (password.length > 50) {
      this.setState({
        passwordError: 'Senha muito longa. Máx de 50 caracteres',
      });
      noValidationErrors = false;
    } else {
      this.setState({ passwordError: '' });
    }

    if (confirmationPassword === '') {
      // Confirmation password is mandatory
      this.setState({ passwordConfirmationError: 'Campo é obrigatório' });
      noValidationErrors = false;
    } else if (confirmationPassword !== password) {
      // Confirmation password has to match password
      this.setState({
        passwordConfirmationError: 'Senha e confirmação não são iguais',
      });
      noValidationErrors = false;
    } else {
      this.setState({ passwordConfirmationError: '' });
    }

    if (noValidationErrors) {
      this.props.onSubmitRecoverPassword(password, confirmationPassword);
    } else {
      console.error('Validation error on registration form.');
    }
  }

  render() {
    return (
      <div className="password-recovery-main">
        <ColorRow color="red" text="" />

        <div className="password-recovery-box">
          <img className="password-recovery-image" src={Pork} alt="" />
          <h3>Resetar Senha</h3>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.validateUserInput();
            }}
          >
            <InputFieldErrorMessage message={this.state.passwordError} />
            <input
              type="password"
              className="login-box-input"
              placeholder="Nova senha"
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
            />

            <InputFieldErrorMessage
              message={this.state.passwordConfirmationError}
            />
            <input
              type="password"
              className="login-box-input"
              placeholder="Confirme nova senha"
              value={this.state.passwordConfirmation}
              onChange={e =>
                this.setState({ passwordConfirmation: e.target.value })
              }
            />

            <Button
              text="MUDAR SENHA"
              icon=""
              buttonType="button-primary"
              loading={this.props.isLoading}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default PasswordRecovery;
