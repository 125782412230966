//@flow
import React, { Component } from 'react';

import './Checkbox.css';

class Checkbox extends Component {
  props: Props;

  render() {
    return (
      <div className="checkbox-div" title={this.props.tooltip}>
        <label>
          <input
            type="checkbox"
            checked={this.props.checked}
            onChange={this.props.onChange}
            ref={this.props.ref}
          />
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
