// @flow
import _ from 'lodash';
import { createSelector } from 'reselect';
import type { StoreState } from '../types';

export const thisStateSelector = (state: StoreState): State => {
  return state.products;
};

export const productMapSelector = createSelector(
  thisStateSelector,
  state => state.products,
);

export const currentProductSelector = createSelector(
  thisStateSelector,
  state => {
    // Attempt to find the product by ID else try by slug
    const productById = state.products[state.current];

    if (productById) return productById;

    const productArray = _.values(state.products);

    return _.find(productArray, { slug: state.current });
  },
);

export const shouldNotifyUserSelector = (state: any, productId: number) => {
  const productMap = productMapSelector(state);
  const product = productMap[productId];
  return product && product.shouldNotifyUser;
};

export const relatedProductsSelector = createSelector(
  thisStateSelector,
  state => state.related.map(id => state.products[id]),
);

export const promotionProductsSelector = createSelector(
  thisStateSelector,
  state => state.promotion.map(id => state.products[id]),
);

export const recentlyViewedProductsSelector = createSelector(
  thisStateSelector,
  state => state.recentlyViewed.map(id => state.products[id]),
);

export const recommendedForUserProductsSelector = createSelector(
  thisStateSelector,
  state => state.recommendedForUser.map(id => state.products[id]),
);

export const mostSoldProductsSelector = createSelector(
  thisStateSelector,
  state => state.mostSold.map(id => state.products[id]),
);

export const frequentlyPurchasedTogetherProductsSelector = createSelector(
  thisStateSelector,
  state => state.frequentlyPurchasedTogether.map(id => state.products[id]),
);

export const productsByIdsSelector = (state: any, ids: number[]) => {
  const productMap = productMapSelector(state);
  return ids.map(id => productMap[id]).filter(p => p !== undefined);
};

// Last Page State Related
export const promotionProductLastPageSelector = createSelector(
  thisStateSelector,
  state => state.lastPage.promotion,
);

export const mostSoldProductLastPageSelector = createSelector(
  thisStateSelector,
  state => state.lastPage.mostSold,
);

export const recommendedLastPageSelecgtor = createSelector(
  thisStateSelector,
  state => state.lastPage.recommended,
);

export const frequentlyPurchasedTogetherProductLastPageSelector =
  createSelector(
    thisStateSelector,
    state => state.lastPage.frequentlyPurchasedTogether,
  );

// Loading State related
export const currentProductLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.currentProduct,
);

export const recentlyViewedProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.recentlyViewed,
);

export const relatedProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.related,
);

export const promotionProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.promotion,
);

export const recommendedForUserProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.recommendedForUser,
);

export const mostSoldProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.mostSold,
);

export const frequentlyPurchasedTogetherProductsLoadingSelector =
  createSelector(
    thisStateSelector,
    state => state.loading.frequentlyPurchasedTogether,
  );

export const multipleProductsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.multipleProducts,
);

export const loadingByIdsSelector = (state: any, ids: number[]) => {
  const undefinedExists = ids.reduce(
    (acc, id) => acc || productMapSelector(state)[id] === undefined,
    false,
  );
  return multipleProductsLoadingSelector(state) && undefinedExists;
};

export const shouldNotifyUserLoadingSelector = createSelector(
  thisStateSelector,
  state => state.shouldNotifyUserLoading,
);

export const shouldNotifyUserLoadingByIdSelector = (
  state: any,
  productId: number,
) => {
  const index = shouldNotifyUserLoadingSelector(state).indexOf(productId);
  return index > -1;
};
