// @flow
import './AddBeforeExistsButton.css';
import React, { Component } from 'react';
import cart_icon from '../images/cart-icon.png';

type Props = {
  loading: boolean,
  clickable?: boolean,
  addItem: (productId: number, quantity: number) => void,
};

class AddBeforeExistsButton extends Component {
  props: Props;
  constructor(props: Props) {
    super(props);
    this.state = {
      clickable:
        this.props.clickable !== undefined ? this.props.clickable : true,
    };
  }

  render() {
    // If the button has been clicked and is now loading
    if (this.props.loading) {
      return (
        <div className="add-to-cart-before-button">
          <i className="fa fa-spinner fa-pulse" />
        </div>
      );
    }
    return (
      <button
        className="add-to-cart-before-button"
        onClick={this.state.clickable ? this.props.addItem : null}
      >
        <div className="add-to-cart-test">
          <i className="fa fa-plus fa-1x" aria-hidden="true" />
          <img className="add-to-cart-icon" src={cart_icon} alt="" />
        </div>
      </button>
    );
  }
}

export default AddBeforeExistsButton;
