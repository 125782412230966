/** @flow
 * CHECKOUT PAGE COMPONENT
 * This is the unconnected component that displays the checkout page,
 * with panes for address, delivery and payment. Also includes a
 * sidebar for order summary and effetuating the purchase.
 */
import React, { Component } from 'react';
// Components
import { ColorRow } from '../../common';
import AddressPane from './address/AddressPane';
import DeliveryPane from './delivery/DeliveryPane';
import PaymentMethodPane from './payment/PaymentPane';
import CartPreview from './CartPreview';
import './CheckoutPage.css';
// Types
import type { ShippingRate } from '../../types';
import type {
  OrderAddress,
  LineItem,
  CreditCard,
  PaymentMethod,
} from '../types';
import type { UserAddress } from '../../user';

/**
 * PROPS AND STATE
 */
export type Props = {
  orderNumber: string,
  getBlends: (orderNumber: string) => void,
  blendsByOrderNumber: orderNumber => void,
  // Order management
  orderState: 'cart' | 'address' | 'delivery' | 'payment' | 'confirm',
  loading: boolean,
  transitioningLoading: boolean,
  // General information
  items: LineItem[],
  totalPrice: string,
  totalDiscount: string,
  totalItemPrice: string,
  shippingPrice: string,
  numberOfItems: number,
  promotions: any,
  // Errors
  addressErrors: string[],
  deliveryErrors: string[],
  promotionErrors: string[],
  paymentErrors: string[],
  dismissErrors: () => void,
  // Address
  orderShippingAddress: ?OrderAddress, // Selected address from the order. Type is different from others.
  addresses: UserAddress[],
  newAddress: (address: UserAddress) => void,
  updateAddress: (address: UserAddress) => void,
  deleteAddress: (addressId: number) => void,
  submitAddress: UserAddress => void,
  addressesDeleteByIdLoading: (id: number) => boolean,
  addressesEditingLoading: boolean,
  addressesAddingLoading: boolean,
  addressesLoading: boolean,
  // Delivery dates
  shippingRates: any,
  submitShippingRate: ShippingRate => void,
  orderDelivery: ShippingRate,
  // Payment
  creditCards: CreditCard[],
  paymentMethods: PaymentMethod[],
  submitPayment: (CreditCard | PaymentMethod) => void,
  orderPayment: PaymentMethod,
  // promotion
  submitPromotion: (string[]) => void,
  // Confirmation
  submitConfirmation: () => void,
};

class CheckoutPage extends Component {
  props: Props;

  // We should clear all warning when the component mounts in case they are lingering from a previous session
  componentDidMount() {
    this.props.dismissErrors();
  }

  render() {
    return (
      <div className="check-out-page-container">
        <ColorRow color="red" text="CHECKOUT" />
        <div className="check-out-page-main">
          <div className="check-out-page-pane">
            <AddressPane
              errors={this.props.addressErrors}
              active={this.props.orderState === 'address'}
              selected={this.props.orderShippingAddress}
              onSubmit={this.props.submitAddress}
              loading={this.props.transitioningLoading}
              // From address connector
              addresses={this.props.addresses}
              addressesLoading={this.props.addressesLoading}
              editingLoading={this.props.addressesEditingLoading}
              addingLoading={this.props.addressesAddingLoading}
              deletingLoading={this.props.addressesDeleteByIdLoading}
              onNew={this.props.newAddress}
              onEdit={this.props.updateAddress}
              onDelete={this.props.deleteAddress}
              dismissErrors={this.props.dismissErrors}
            />
            <DeliveryPane
              errors={this.props.deliveryErrors}
              selected={
                this.props.orderState !== 'address'
                  ? this.props.orderDelivery
                  : null
              } // Delivery that is in the order from server. Ternary needed not to show when in address.
              shippingRates={this.props.shippingRates}
              onSubmit={this.props.submitShippingRate}
              active={['delivery'].indexOf(this.props.orderState) > -1}
              loading={this.props.transitioningLoading}
              dismissErrors={this.props.dismissErrors}
            />
            <PaymentMethodPane
              orderNumber={this.props.orderNumber}
              adjustmentTotal={this.props.adjustmentTotal}
              promotions={this.props.promotions}
              promotionsLoading={this.props.promotionsLoading}
              promotionErrors={this.props.promotionErrors}
              errors={this.props.paymentErrors}
              selected={this.props.orderPayment}
              creditCards={this.props.creditCards}
              paymentMethods={this.props.paymentMethods}
              submitPayment={this.props.submitPayment}
              submitPromotion={this.props.submitPromotion}
              active={
                ['payment', 'confirm'].indexOf(this.props.orderState) > -1
              }
              loading={this.props.transitioningLoading}
              dismissErrors={this.props.dismissErrors}
            />
          </div>
          <CartPreview
            items={this.props.items}
            orderNumber={this.props.orderNumber}
            blendsByOrderNumber={this.props.blendsByOrderNumber}
            totalPrice={this.props.totalPrice}
            itemTotal={this.props.totalItemPrice}
            shipTotal={this.props.shippingPrice}
            adjustmentTotal={this.props.adjustmentTotal}
            canSubmit={
              ['payment', 'confirm'].indexOf(this.props.orderState) > -1
            }
            loading={
              this.props.transitioningLoading &&
              this.props.orderState === 'confirm'
            }
            onSubmit={this.props.submitConfirmation}
          />
        </div>
      </div>
    );
  }
}

export default CheckoutPage;
