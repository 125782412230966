// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './LoggedInOptions.css';

type Props = {
  name: string,
};

const LoggedInOptions = ({ name }: Props) => {
  return (
    <div className="dropdown">
      <button
        className="dropbtn"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {name.length < 15 ? name : 'Bem vindo!'}
        <div className="dropdown-arrow-container">
          <div className="logged-in-arrow-down " />
        </div>
      </button>
      <div className="dropdown-content" aria-labelledby="dropdownMenuButton">
        <Link className="dropdown-item" to="/minha-conta/informacoes/">
          Minha conta
        </Link>
        <hr className="dropdown-line" />
        <Link className="dropdown-item" to="/minha-conta/pedidos/">
          Meus pedidos
        </Link>
        <hr className="dropdown-line" />
        <Link className="dropdown-item" to="/logout/">
          Logout
        </Link>
        <hr className="dropdown-line" />
      </div>
    </div>
  );
};

export default LoggedInOptions;
