//@flow
import React, { Component } from 'react';

import './OptionBox.css'

type Props = {
  imgSrc: string,
  text: string,
  isSelected: boolean,
};

class OptionBox extends Component {
  props: Props;

  render() {
    let selected = ""
    if (this.props.isSelected) {
      selected = "option-box-selected"
    }

    return (
      <div className={`option-box-main ${selected}`}>
        <img className="option-box-image" src={this.props.imgSrc} alt="" />
        <div className="option-box-text">{this.props.text}</div>
      </div >
    );
  }
}

export default OptionBox;
