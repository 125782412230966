// @flow
import React, { Component } from 'react';
//Components
import { ColorRow, PageLoader } from '../../common';
import { SubdepartmentScroller } from '../../products';
// Types
import type { Department } from '../types';
// Styles 
import './DepartmentPage.css'

export type Props = {
  department: Department,
  loading: boolean,
  acessedSubdepartment: String,
};

class DepartmentPage extends Component {
  props: Props;
  render() {
    const department = this.props.department;

    if (this.props.loading)
      return (
        <div>
          <ColorRow color="grey" text="" />
          <PageLoader />
        </div>

      );

    if (!department)
      return (
        <div className="department-not-found">
          <ColorRow color="grey" text="" />
          <h3>Não foi possível carregar esse departamento.</h3>
          <a href="/">Voltar ao início</a>
        </div>
      );

    return (
      <div className="product-page-main">
        <ColorRow color="grey" text={department.name} />
        {department.subdepartments.map((subdepartment, ind) => (
          <SubdepartmentScroller
            key={subdepartment.id}
            title={subdepartment.name}
            productIds={subdepartment.products}
            acessedSubdepartment={this.props.acessedSubdepartment}
            subdepartmentSlug={subdepartment.slug}
            subdepartmentLink={`/departamentos/${department.id}/${subdepartment.slug}/`}
          />
        ))}
      </div>
    );
  }
}

export default DepartmentPage;
